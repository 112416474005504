

import React from 'react';
import "../css/RecentJobs.css";

const RecentJobs = () => {
  return (
    <>
      <div className="main-recent-job">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="recent-job-card">
                        <div className="fl-lo-ca">
                            <div className="logaa">
                                <img src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="Logo" />
                            </div>
                            <div className="candl">
                                <span className="candln">Company Name</span> <br />
                                <span className="candll">Company Location</span>
                            </div>
                        </div>

                        <div className="jo-ttitle">
                            HR
                        </div>

                        <div className="emt">
                            <div className='emt-type'>employent type</div>
                            <div className='emt-time'>time</div>
                        </div>

                        <div className='shhot'>
                            <span>Short Description</span>
                        </div>
                        <div className='shhot'>
                            <span>Domain</span>
                        </div>

                        <div className="fld">
                            <div className='alumni'>4.5 LPA</div>
                            <span>Apply Now</span>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default RecentJobs;
