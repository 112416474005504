import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import baseUrlAPI from "../BaseURL";
import { useNavigate } from "react-router-dom";
import NavigateTo from "../NavigateTo";

const ProfileSnapInternship = () => {
  const [internship, setInternship] = useState(null);
  const navigate = useNavigate();

  const token = localStorage.getItem("candidateToken");
  const uid = localStorage.getItem("uid");
  console.log({ uid, token });
  const getInternship = async () => {
    const { data } = await axios.post(`${baseUrlAPI}/api/getInternship`, {
      candidate_id: uid,
      token,
    });
    setInternship(data[0]);
    console.log({ intern: data[0] });
  };
  const navigateTo = () => {
    navigate("/my-profile-others");
  };
  useEffect(() => {
    getInternship();
  }, []);
  if (!internship) {
    return <NavigateTo url={"my-profile-others"} label={"Internships"} />;
  }
  return (
    <div>
      <strong>Title : </strong>
      {internship.title}
    </div>
  );
};

export default ProfileSnapInternship;
