// Experience

import React, { useEffect, useState } from "react";
import "../css/RegExperience.css";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';
import baseUrlAPI from "./BaseURL";
import axios from "axios";
import { Alert } from "@mui/material";
import Select from "react-select";
// import { colourOptions } from '../data';
const steps = [
  "Basic Info",
  "Education",
  "Work Experience",
  "Others",
  "Preference",
];
const Experience = () => {
  const uid = localStorage.getItem("uid");
  useEffect(() => {
    if (uid) {
      navigate("/candidate-experience");
      window.scrollTo(0, 0);
    } else {
      navigate("/register");
      window.scrollTo(0, 0);
    }
  }, []);
  // api current experience

  // const [total_experience, setTotal_experience] = useState("");
  // const [current_organization, setCurrent_organization] = useState("");
  // const [current_role_name, setCurrent_role_name] = useState("");
  // const [we_c_organization, setWe_c_organization] = useState("");
  // const [c_industry_category, setC_industry_category] = useState("");
  // const [profile, setProfile] = useState("");
  // const [current_total_ctc, setCurrent_total_ctc] = useState("");
  // const [current_fixed_ctc, setCurrent_fixed_ctc] = useState("");
  // const [notice_period, setNotice_period] = useState("");
  // const [wfh_situation, setWfh_situation] = useState("Complete");

  const [myCurrentexp, setCurrentMyExp] = useState(null);
  const [currentExpYear, setCurrentExpYear] = useState("");
  const [currentExpMonths, setCurrentExpMonths] = useState(0);
  const [total_experience, setTotal_experience] = useState("");
  const [current_organization, setCurrent_organization] = useState("");
  const [current_role_name, setCurrent_role_name] = useState("");
  const [we_c_organization, setWe_c_organization] = useState("");
  const [c_industry_category, setC_industry_category] = useState("");
  const [job_tags, setJob_tags] = useState("");
  const [current_total_ctc, setCurrent_total_ctc] = useState("");
  const [current_fixed_ctc, setCurrent_fixed_ctc] = useState("");
  const [notice_period, setNotice_period] = useState("1 Month");
  const [wfh_situation, setWfh_situation] = useState("Work from office");
  const [subRoleId, setSubRoleId] = useState([]);
  const [currentRoleName, setCurrentRoleName] = useState("");
  const [error, setError] = useState([]);
  const [totalMonthExp, setTotalMonthExp] = useState(0);
  const [totalYearExp, setTotalYearExp] = useState("");
  const [isBack, setIsBack] = useState(false);
  const [formmValues, setformmValues] = useState([
    {
      currentExpMonths: "",
      currentRoleName: "",
      current_fixed_ctc: "",
      currentRoleName: "",
      current_total_ctc: "",
      current_organization: "",
    },
  ]);
  const [formValues, setFormValues] = useState([
    {
      // current_organization: "",
      company: "",
      job: "",
      location: "",
      i_year: "",
      o_year: "",
      description: "",
      job_role: "",
      achievement: "",
    },
  ]);
  const getCurrentExperience = async () => {
    const candidateToken = localStorage.getItem("candidateToken");
    const id = localStorage.getItem("uid");
    // const role_id = localStorage.getItem("role_id");
    const role_id = localStorage.getItem("role_ID");

    const subrole_id = localStorage.getItem("subRole_ID");

    const currentExperience = localStorage.getItem("currentExperience");
    // const candidate_id = localStorage.getItem("can_uid");
    const { data } = await axios.post(`${baseUrlAPI}/api/Current_experience`, {
      candidate_id: id,
      token: candidateToken,
      role_id,
      subrole_id,
    });
    if (data.length === 0) {
      return;
    }
    const currExp = data[0];
    setCurrent_organization(currExp.current_organization);
    setJob_tags(currExp.current_role_name);
    setCurrentExpYear(currExp.we_c_organization.split(".")[0]);
    setCurrentExpMonths(currExp.we_c_organization.split(".")[1]);
    setTotalMonthExp(currExp.total_experience.split(".")[1]);
    setTotalYearExp(currExp.total_experience.split(".")[0]);
    setCurrent_total_ctc(+currExp.current_total_ctc / 100000);
    setCurrent_fixed_ctc(+currExp.current_fixed_ctc / 100000);
    setC_industry_category("");

    setIsBack(true);
  };
  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    if (e.target.type === "checkbox") {
      newFormValues[i][e.target.name] = e.target.checked;
      setFormValues(newFormValues);
    } else {
      newFormValues[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);
    }
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        company: "",
        job: "",
        location: "",
        i_year: "",
        o_year: "",
        description: "",
        job_role: "",
        achievement: "",
      },
    ]);
  };
  const [otherIndustry, setOtherIndustry] = useState("");
  // setError([]);

  const navigate = useNavigate(); // declaring the navigate

  const goToOthers = (e) => {
    e.preventDefault();
    const errors = [];
    // Form validations
    const subroleUid = subRoleId.map((value, i) => {
      if (i > 2) {
        return errors.push("You can not select more than 3.");
      } else {
        return value.value;
      }
    });
    formValues.forEach((val) => {
      switch (true) {
        case val.company === "":
          errors.push("In work experience company name is required.");
          break;
        case val.description === "":
          errors.push("In work experience description is required.");
          break;
        case val.i_year === "":
          errors.push("In work experience in year is required.");
          break;
        // case val.job === "":
        //   errors.push("In work experience job role is required.");
        // case val.o_year === "":
        //   errors.push("In work experience out year is required.");
        // case val.location === "":
        //   errors.push("In work experience location is required.");
      }
    });

    const tokenid = localStorage.getItem("candidateToken");
    const candidate_id = localStorage.getItem("uid");
    const obj_data = {
      form_data: formValues,
      token: tokenid,
      candidate_id: candidate_id,
    };
    // localStorage.setItem("can_uidE", obj_data.candidate_id);

    // const AddExperience = "http://3.109.83.81/phc-admin/api/AddExperience";

    const currentExperienceURL = `${baseUrlAPI}/api/currentExperience`;
    switch (true) {
      case subRoleId === "":
        errors.push("Sub role is required.");
        break;
      case parseInt(currentExpYear) > 40:
        errors.push("Current experience year cannot be greater than 40");
        break;

      case parseInt(currentExpYear) < 0:
        errors.push("Current experience year cannot be less than 0");
        break;

      case parseInt(currentExpMonths) > 12:
        errors.push("Current experience month cannot be greater than 12");
        break;

      case parseInt(currentExpMonths) < 0:
        errors.push("Current month cannot be less than 0");
        break;

      case parseInt(totalYearExp) < 0:
        errors.push("Total experience year cannot be less than 0");
        break;

      case parseInt(totalYearExp) > 40:
        errors.push("Total experience year cannot be greater than 40");
        break;
      case parseInt(totalMonthExp) > 12:
        errors.push("Total experience month cannot be greater than 12");
        break;
      case parseInt(totalMonthExp) < 0:
        errors.push("Total experience month cannot be less than 0");
        break;
      case parseInt(totalYearExp) < parseInt(currentExpYear):
        errors.push("Total experience cannot be less than current experience");
        break;
      case current_organization === "":
        errors.push("Current organization is required");
        break;
      case c_industry_category === "":
        errors.push("Industry category is required");
        break;

      case job_tags === "":
        errors.push("Job profile is required");
        break;

      case parseInt(current_fixed_ctc) > 200:
        errors.push("Current fixed ctc cannot be more than 2CR");
        break;

      case parseInt(current_fixed_ctc) <= 11:
        errors.push("Current fixed CTC cannot be less than 12L");
        break;

      case parseInt(current_total_ctc) > 200:
        errors.push("Current total ctc cannot be more than 2CR");
        break;

      case parseInt(current_total_ctc) <= 11:
        errors.push("Current total CTC cannot be less than 12L");
        break;

      case notice_period === "":
        errors.push("Notice period is required");
        break;

      case wfh_situation === "":
        errors.push("Work from home situation is required");
        break;
    }
    if (errors.length !== 0) {
      window.scrollTo(0, 0);

      return setError(errors);
    }

    const currentExperience = {
      total_experience: `${totalYearExp}.${totalMonthExp}`,
      current_organization,
      role_id: current_role_name,
      we_c_organization: `${currentExpYear}.${currentExpMonths}`,
      c_industry_category:
        c_industry_category === "Others" ? otherIndustry : c_industry_category,
      profile: job_tags,
      current_total_ctc: current_total_ctc * 100000,
      current_fixed_ctc: current_fixed_ctc * 100000,
      notice_period,
      wfh_situation,
      token: tokenid,
      candidate_id: candidate_id,
      subrole_id: JSON.stringify(subroleUid),
      current_role_name: job_tags,
    };
    localStorage.setItem("position", currentExperience.profile);
    fetch(currentExperienceURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(currentExperience),
    })
      .then((result) => result.json())
      .then((data) => {
        if (data.status === false) {
          errors.push(data.message);
          window.scrollTo(0, 0);
          return;
        }
      })
      .catch((e) => {});
    if (errors.length !== 0) {
      return setError(errors);
    }
    const AddExperience = `${baseUrlAPI}/api/AddExperience`;
    fetch(AddExperience, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj_data),
    })
      .then((result) => result.json())

      .then((data) => {
        if (data.status === 200) {
          alert("success");
        }
      })
      .catch((e) => {});
    navigate("/others");
  };

  const [inputBox, setInputBox] = useState(false);
  const otherOption = (value) => {
    if (value === "Others") {
      setInputBox(true);
    } else {
      setInputBox(false);
    }
    window.scrollTo(0, 0);
  };

  // get API Industry

  const industryURL = `${baseUrlAPI}/api/industry`;

  const [industryapi, setIndustryAPI] = useState([]);
  useEffect(() => {
    fetch(industryURL).then((industryResult) => {
      industryResult.json().then((industryRes) => {
        setIndustryAPI(industryRes.data);

        setC_industry_category(industryRes.data[0][0]);
      });
    });
  }, [industryURL]);

  // get Role API URL

  const [getRole, setGetRole] = useState([]);
  const [roleID, setRoleID] = useState("");
  const [subRole, setSubRole] = useState([]);

  const getRoleUrl = `${baseUrlAPI}/api/getRole`;
  useEffect(() => {
    fetch(getRoleUrl).then((resultRole) => {
      resultRole.json().then((resRole) => {
        setGetRole(resRole.data);
        // setCurrent_role_name(resRole.data[0].uid);
      });
    });
  }, [getRoleUrl]);

  const handleRole = (event) => {
    const getRoleID = event.target.value;
    setRoleID(getRoleID);
  };

  const getSubRoleURL = `${baseUrlAPI}/api/getSubRole?uid=` + roleID;
  const getSubRole = async () => {
    try {
      if (roleID === "") {
        return;
      }
      const { data } = await axios.get(getSubRoleURL);
      const temp = [...data.data];
      setSubRole(temp);
      setSubRoleId(temp[0].uid);
    } catch (e) {}
  };
  const renderError = () => {
    return error.map((e) => {
      return <Alert severity="error">{e}</Alert>;
    });
  };
  useEffect(() => {
    getSubRole();
  }, [roleID]);

  const handleRemove = (i) => {
    let temp = [...formValues];
    temp.splice(i, 1);
    setFormValues(temp);
  };
  // Get Experience
  const getData = async () => {
    const candidateToken = localStorage.getItem("candidateToken");
    const id = localStorage.getItem("uid");
    const { data } = await axios.post(`${baseUrlAPI}/api/getExperience`, {
      candidate_id: id,
      token: candidateToken,
    });
    const formData = data.map((el) => {
      return {
        company: el.company_name,
        job: el.job_title,
        location: el.location,
        i_year: el.starting_year,
        o_year: el.completion_year,
        description: el.description,
        job_role: el.job_role,
        achievement: el.achievement,
        uid: el.uid,
      };
    });
    setFormValues(formData);
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getCurrentExperience();
  }, []);
  // UPDATE EXPERIENCE

  const updateExperience = async (i) => {
    const valToUpdate = formValues[i];
    const token = localStorage.getItem("candidateToken");
    const candidate_id = localStorage.getItem("uid");
    const updateObj = {
      token,
      candidate_id,
      uid: valToUpdate.uid,
      company_name: valToUpdate.company,
      job_title: valToUpdate.job,
      location: valToUpdate.location,
      starting_year: valToUpdate.i_year,
      completion_year: valToUpdate.o_year,
      description: valToUpdate.description,
      achievement: valToUpdate.achievement,
      job_role: valToUpdate.job_role,
    };
    console.log(updateObj);
    const { data } = await axios.post(
      `${baseUrlAPI}/api/updateExperience`,
      updateObj
    );
    console.log({ data });
  };
  // const updateExperience = () => {

  // }
  return (
    <>
      <div className="Free-Today">
        <div className="container">
          <div className="ce-ex-re">
            <h5 className="reg-to">Register</h5>
            <h1 className="reg-start">
              Start for <span>Free Today</span>{" "}
            </h1>
            <p className="reg-para">
              Access to all features. No credit card required.
            </p>
          </div>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={2} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>

        <div
          className="r-form-first "
          style={{
            boxShadow: "0px 0px 40px rgba(0,0,0,.05)",
          }}
        >
          <div className="container">
            {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6"> */}
            <h4
              className="add-int-li-pro"
              onClick={() => navigate("/education")}
            >
              Go Back
            </h4>
            {/* </div> */}
            <form action="">
              {error.length === 0 ? null : renderError()}
              <div className="expe">
                <div className="company-name">
                  <label htmlFor="">Current Organization *</label> <br />
                  {/* <select name="" id="" value={current_organization} onChange={(e)=>setCurrent_organization(e.target.value)}>
                    <option value="ABC Company">ABC Company</option>
                    <option value="A Company">A Company</option>
                    <option value="B Company">B Company</option>
                  </select> */}
                  <input
                    type="text"
                    placeholder="Current Organization "
                    value={current_organization}
                    onChange={(e) => {
                      setCurrent_organization(e.target.value);
                      handleChange(0, {
                        target: {
                          value: e.target.value,
                          name: "company",
                        },
                      });
                    }}
                    className="input"
                  />
                </div>

                <div className="job-title">
                  <label>Current role name</label> <br />
                  <input
                    type="text"
                    placeholder="Profile"
                    value={job_tags}
                    onChange={(e) => {
                      setJob_tags(e.target.value);
                      handleChange(0, {
                        target: {
                          value: e.target.value,
                          name: "job",
                        },
                      });
                    }}
                    className="input"
                  />
                </div>
              </div>
              <div className="expe">
                <div className="company-namee">
                  <label className="ptt" htmlFor="">
                    Work Exp in current organization: Year & Month *
                  </label>{" "}
                  <br />
                  <input
                    type="number"
                    placeholder="Year"
                    // value={we_c_organization}
                    className="input"
                    value={currentExpYear}
                    onChange={(e) => {
                      setCurrentExpYear(e.target.value);
                    }}
                  />
                  <input
                    type="number"
                    placeholder="Months"
                    value={currentExpMonths}
                    className="input hoho"
                    onChange={(e) => {
                      setCurrentExpMonths(e.target.value);
                    }}
                  />
                  {/* <select name="" id="" className="left-year-month">
                    <option value="Year">Year</option>
                    <option value="Month">Month</option>
                  </select> */}
                </div>
                <div className="job-titlee">
                  <label className="ptt" htmlFor="">
                    Total Work Experience: Year & Month *
                  </label>{" "}
                  <br />
                  <input
                    type="number"
                    placeholder="Year"
                    value={totalYearExp}
                    className="input"
                    onChange={(e) => setTotalYearExp(e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="Months"
                    className="input hoho"
                    value={totalMonthExp}
                    onChange={(e) => {
                      setTotalMonthExp(e.target.value);
                    }}
                  />
                  {/* <select name="" id="" className="left-year-month">
                    <option value="Year">Year</option>
                    <option value="Month">Month</option>
                  </select> */}
                </div>
              </div>
              <div className="expe">
                <div className="company-name">
                  <label htmlFor="">
                    Total Current CTC <br /> ( in Lakhs per Annum) *
                  </label>{" "}
                  <br />
                  <input
                    type="number"
                    placeholder="Current CTC"
                    value={current_total_ctc}
                    onChange={(e) => setCurrent_total_ctc(e.target.value)}
                    className="input"
                  />
                </div>

                <div className="job-title">
                  <label>
                    Total Fixed CTC <br /> (in Lakhs per Annum)
                  </label>{" "}
                  <br />
                  <input
                    type="number"
                    placeholder="Fixed CTC"
                    value={current_fixed_ctc}
                    onChange={(e) => setCurrent_fixed_ctc(e.target.value)}
                    className="input"
                  />
                </div>
              </div>
              <div className="date">
                <div className="Left">
                  <label htmlFor=""> MBA Domain</label> <br />
                  <select
                    style={{
                      // height: "60px",
                      padding: "0px 2px",
                      // fontSize: "16px",
                      border: "border: 1px solid rgb(209, 209, 209) ",
                    }}
                    name=""
                    id=""
                    value={current_role_name}
                    onChange={(e) => {
                      setCurrent_role_name(e.target.value);
                      handleRole(e);
                      setRoleID(e.target.value);
                      const roleName = getRole.find((el) => {
                        return el.uid === e.target.value;
                      });
                      setCurrentRoleName(roleName.role);
                    }}
                  >
                    <option selected enabled>
                      Select MBA domain
                    </option>
                    {getRole.map((value, i) => {
                      return (
                        <option key={i} value={value.uid}>
                          {value.role}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="Durationn">
                  <label htmlFor="">Sub Domains</label> <br />
                  <Select
                    // defaultValue={[colourOptions[2], colourOptions[3]]}
                    // defaultValue={""}
                    isMulti
                    name=""
                    options={
                      subRole && subRole.length === 0
                        ? null
                        : subRole.map((e, i) => {
                            return {
                              key: i,
                              value: e.uid,
                              label: e.role,
                            };
                          })
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => {
                      setSubRoleId(e);
                    }}
                  />
                  <span className="please">(Please select upto 3)</span>
                </div>

                <div className="date2">
                  <div className="Duration" style={{ width: "100%" }}>
                    {/*  On selection of others, user should be able to add the industry through input field */}
                    <label htmlFor="">Industry</label> <br />
                    {
                      <select
                        style={{
                          // height: "60px",
                          padding: "0px 2px",
                          // fontSize: "16px",
                          border: "border: 1px solid rgb(209, 209, 209) ",
                        }}
                        name=""
                        id=""
                        value={c_industry_category}
                        onChange={(e) => {
                          setC_industry_category(e.target.value);
                        }}
                        // style={{ width: "100%" }}
                      >
                        {industryapi.map((value, i) => {
                          return (
                            <option key={i} value={value}>
                              {value}
                            </option>
                          );
                        })}
                      </select>
                    }
                    {c_industry_category === "Others" ? (
                      <input
                        className="input"
                        style={{ marginTop: "10px" }}
                        value={otherIndustry}
                        onChange={(e) => {
                          setOtherIndustry(e.target.value);
                        }}
                        placeholder={"Enter the industry name"}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="expe">
                <div className="company-name">
                  <label className="ptt" htmlFor="">
                    Notice Period *
                  </label>{" "}
                  <br />
                  <select
                    style={{
                      // height: "60px",
                      padding: "0px 2px",
                      // fontSize: "16px",
                      border: "border: 1px solid rgb(209, 209, 209) ",
                    }}
                    name=""
                    id=""
                    required={true}
                    value={notice_period}
                    onChange={(e) => {
                      setNotice_period(e.target.value);
                      otherOption(e.target.value);
                    }}
                  >
                    <option value="1 Month">1 Month</option>
                    <option value="2 Months">2 Months</option>
                    <option value="3 Months">3 Months</option>
                    <option value="Others">Other</option>
                    <option value="Currently Serving Notice">
                      Currently Serving Notice
                    </option>
                  </select>
                  {inputBox ? (
                    <input
                      onChange={(e) => {
                        setNotice_period(e.target.value);
                      }}
                      className="input"
                      type="text"
                    />
                  ) : null}
                </div>
                <div className="job-title">
                  <label className="ptt" htmlFor="">
                    WFH Situation *
                  </label>{" "}
                  <br />
                  <select
                    style={{
                      // height: "60px",
                      padding: "0px 2px",
                      // fontSize: "16px",
                      border: "border: 1px solid rgb(209, 209, 209) ",
                    }}
                    name=""
                    className="bbbb-h-bor"
                    id=""
                    value={wfh_situation}
                    onChange={(e) => setWfh_situation(e.target.value)}
                  >
                    <option>Select your option</option>
                    <option value="Work from office">Work from office</option>
                    <option value="Partial">Partial</option>
                    <option value="Complete">Complete</option>
                  </select>
                </div>
              </div>{" "}
              <br />
              <div className="flex-ad-mo">
                <h4 className="post-gr-col-font">Work Experience Details: </h4>
                <h4
                  className="post-gr-col-fontt"
                  onClick={() => addFormFields()}
                >
                  Add More
                </h4>
              </div>
              {formValues.map((element, index) => (
                <div name="input" key={index} className="or-add-more-ad">
                  {index === 0 ? null : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        color: "gray",
                      }}
                      onClick={() => {
                        handleRemove(index);
                      }}
                    >
                      <DeleteIcon />
                    </div>
                  )}
                  <div className="org-lo row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label htmlFor="">Job Title *</label> <br />
                      <input
                        className="input"
                        type="text"
                        placeholder="Job Profile"
                        name="job"
                        value={element.job || ""}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label htmlFor="">Company Name *</label> <br />
                      <input
                        className="input"
                        type="text"
                        placeholder="Company Name"
                        name="company"
                        value={element.company || ""}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>

                  <div className="org-lo row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label htmlFor="">Starting year *</label> <br />
                      <input
                        className="input"
                        type="month"
                        placeholder="Starting year"
                        name="i_year"
                        value={element.i_year || ""}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label htmlFor="">Completion Year *</label> <br />
                      <input
                        type="month"
                        placeholder="Completion Year"
                        name="o_year"
                        value={element.o_year || ""}
                        onChange={(e) => handleChange(index, e)}
                        className={element.job_role ? "hide-cy" : "input"}
                      />
                    </div>
                    <div className="ml-fp-r">
                      {index === 0 ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={element.job_role || ""}
                              name="job_role"
                              onChange={(e) => handleChange(index, e)}
                            />
                          }
                          label="This is my current job role"
                          className="check-label"
                        />
                      ) : (
                        <input
                          type="hidden"
                          name="job_role"
                          value={element.job_role || "0"}
                          // onChange={(e) => handleChange(index, e)}
                        />
                      )}
                    </div>
                    <div className="org-lo ">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label htmlFor="">Location *</label> <br />
                        <input
                          type="text"
                          className="loc-loc-1px"
                          placeholder="Location"
                          name="location"
                          value={element.location || ""}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="j-titlee">
                    <label htmlFor="">
                      Role & Responsibilities*{" "}
                      <span className="red-span">
                        (Add ";" for adding bullet point)
                      </span>
                    </label>{" "}
                    <br />
                    <textarea
                      id=""
                      cols="30"
                      rows="5"
                      placeholder="Role & Responsibilities ...."
                      name="description"
                      value={element.description || ""}
                      onChange={(e) => handleChange(index, e)}
                    ></textarea>
                  </div>

                  <div className="j-titlee">
                    <label htmlFor="">
                      Initiatives and Achievements*{" "}
                      <span>
                        <span className="red-span">
                          (Add ";" for adding bullet point)
                        </span>
                      </span>
                    </label>{" "}
                    <br />
                    {console.log({ element })}
                    <textarea
                      id=""
                      cols="30"
                      rows="5"
                      placeholder="Achievement ...."
                      name="achievement"
                      value={element.achievement || ""}
                      onChange={(e) => handleChange(index, e)}
                    ></textarea>
                  </div>

                  <hr />
                  {isBack ? (
                    <button
                      className="Updates"
                      onClick={(e) => {
                        e.preventDefault();
                        updateExperience(index);
                      }}
                    >
                      Update
                    </button>
                  ) : null}
                </div>
              ))}
            </form>
            <div className="r-btn-next">
              <button
                onClick={(e) => {
                  isBack ? navigate("/others") : goToOthers(e);
                }}
              >
                Proceed To Next Step
              </button>
            </div>
            {/* <div className="reg1-sign">
              <p>
                Already have an account? <span>Sign In Now.</span>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Experience;
