import React from "react";
const AddLicenceForm = ({ handleChange }) => {
  return (
    <div name="input">
      <div className="handleDelete"></div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
          <div className="title-other">
            <label htmlFor="">Title </label> <br />
            <input
              type="text"
              placeholder="Title"
              name="title_l"
              className="input"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
          <div className="title-other">
            <label htmlFor="">Year </label> <br />
            <input
              className="input"
              type="number"
              placeholder="Year "
              name="year_l"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
          <div className="title-other">
            <label htmlFor="">Category</label> <br />
            <select
              name="category"
              id=""
              className="input"
              style={{
                // height: "60px",
                padding: "0px 2px",
                border: "border: 1px solid rgb(209, 209, 209) ",
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <option value="Licence">Licence</option>
              <option value="Certificate">Certificate</option>
              <option value="Workshop">Workshop</option>
              <option value="Others">Others</option>
            </select>
            {/* {inputBox ? <input type="text" /> : null} */}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
          <div className="title-other">
            <label htmlFor=""> Institute Name </label> <br />
            <input
              type="text"
              className="input"
              placeholder=" Institute Name "
              name="college"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="des-other">
            <label htmlFor="">
              Description{" "}
              <span className="red-span">
                (Add ";" for adding bullet point)
              </span>
            </label>{" "}
            <br />
            <textarea
              name="description_l"
              className="input"
              style={{ height: "200px" }}
              id=""
              cols="30"
              rows="5"
              placeholder="Description ...."
              onChange={(e) => handleChange(e)}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLicenceForm;
