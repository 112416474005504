import React from "react";
import { useNavigate } from "react-router-dom";
const NavigateTo = ({ label, url }) => {
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate(`/${url}`);
  };
  return (
    <div className="navigateToBtn" onClick={navigateTo}>
      Add {label}
    </div>
  );
};

export default NavigateTo;
