import React, { useState } from "react";
import "../css/Reset_Password.css";
import { useNavigate } from "react-router-dom";
import baseUrlAPI from "./BaseURL";
import { Alert } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

import { Modal, ModalBody, Row, Col } from "reactstrap";

const ForgetPassword = () => {
  const [login, setLogin] = useState(false);
  const [error, setError] = useState([]);
  const [mobile, setMobile] = useState("");

  const navigate = useNavigate();

  const goToValidateOTP = (e) => {
    e.preventDefault();

    const myMobile = { mobile };

    const urlMemberLogin = `${baseUrlAPI}/api/forgotpassword`;

    fetch(urlMemberLogin, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(myMobile),
    })
      .then((result) => result.json())
      .then((data) => {
        if (data.status === true) {
          localStorage.setItem("mobile", mobile);
          // localStorage.setItem("otp", data.data.otp);
          navigate("/reset-password");
        } else {
          const errors = [];
          if (mobile.length !== 10) {
            errors.push("Please enter a 10-digit mobile number");
          }
          if (isNaN(mobile)) {
            errors.push("Username is not valid");
          }
          setError(errors);
          setLogin(true);
        }
      })
      .catch((e) => {
        console.log("Error:", e);
      });
  };

  const renderError = () => {
    return (
      <Alert severity="error">
        {error.map((e, index) => {
          return <h5 key={index}>{e}</h5>;
        })}
      </Alert>
    );
  };

  const signUp = () => {
    navigate("/register");
  };

  const loginPage = () => {
    navigate("/member-log-in");
  };

  return (
    <>
      <div className="Member-Login">
        <Modal size="md" isOpen={login} toggle={() => setLogin(!login)}>
          <ModalBody>
            <form action="">
              <Row>
                <Col lg={12}>
                  <div className="err-icn">
                    <ErrorIcon className="icn-icn" />
                  </div>

                  <h2 className="login-err">
                    USERNAME NUMBER IS NOT REGISTERED
                  </h2>
                  <h6 className="saveup-cen">
                    Please sign up to start using PHC Platform
                  </h6>
                  {error.length !== 0 ? renderError() : null}

                  <button onClick={signUp} className="ok_popup">
                    Go To Sign Up
                  </button>
                </Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>
        <h5 className="ml-wel">Welcome back!</h5> <br />
        <h1 className="ml-mem">
          Forget <span>Password</span>{" "}
        </h1>
        <br />
        <p className="ml-access">
          The verification email will be sent to the email box.
        </p>{" "}
        <br />
        <form action="" className="ml-form">
          <label htmlFor="" className="eu-mll">
            Username
          </label>{" "}
          <br />
          <input
            className="input-mobile input"
            type="text"
            name="mobile"
            placeholder="Enter Registered Email / Mobile number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <br /> <br />
          {/* <FormControlLabel
            control={<Checkbox />}
            label="Remember Me"
            className="check-labell"
          /> */}
          {/* <label htmlFor="" className="pass-mll"></label> <br />
          <input className="input-passs" type="text" placeholder="2024" /> */}
        </form>{" "}
        <br />
        <button className="validate" onClick={goToValidateOTP}>
          Reset Password
        </button>{" "}
        <br /> <br />
        <div className="signUp">
          <button className="btn-sign" onClick={login}>
            Back to LogIn
          </button>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
