import React from "react";

const AddCurricularForm = ({ handleChanege }) => {
  return (
    <div name="input">
      <div className="handleDelete"></div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div className="title-other">
            <label htmlFor="">Title </label> <br />
            <input
              className="input"
              type="text"
              placeholder="Title"
              name="title_c"
              onChange={(e) => handleChanege(e)}
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div className="title-other">
            <label htmlFor="">Year </label> <br />
            <input
              className="input"
              type="number"
              placeholder="Year "
              name="year"
              onChange={(e) => handleChanege(e)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="des-other">
            <label htmlFor="">
              Description{" "}
              <span className="red-span">
                (Add ";" for adding bullet point)
              </span>
            </label>{" "}
            <br />
            <textarea
              id=""
              className="input"
              style={{ height: "200px" }}
              cols="30"
              rows="5"
              placeholder="Description ...."
              name="description_c"
              onChange={(e) => handleChanege(e)}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddCurricularForm;
