import react from "react";
import "../css/Job_card.css";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { useLocation, useNavigate } from "react-router-dom";

// import navo
// Using print to decide the value of flex basis
const JobCard = ({ job, print }) => {
  // console.log(job, "job");
  const navigate = useNavigate();
  const uid = localStorage.getItem("uid");

  const pathName = useLocation().pathname;

  const goToJobSearch = (value) => {
    let jobSearchId;
    if (pathName == "/job") {
      jobSearchId = value?.uid;
    }

    if (pathName == "/saved-jobs") {
      jobSearchId = value?.job_id;
    }

    if (uid) {
      navigate("/job-search/" + jobSearchId);
      window.scrollTo(0, 0);
    } else {
      navigate("/register");
      window.scrollTo(0, 0);
    }
  };

  const dateDiffInDays = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  };
  return (
    <div
      className="jobCardMainConatiner"
      style={{ flexBasis: print === 0 ? "24%" : "32%" }}
      onClick={() => {
        goToJobSearch(job);
      }}
    >
      <div className="jobCardHeaderContainer">
        <div className="jobCardImageContainer">
          {/* <img
            src={
              job?.company_logo
                ? job?.company_logo
                : `https://cdn-icons-png.flaticon.com/512/174/174857.png`
            }
            className="jobCardImage"
            alt="logo"
          /> */}
          <img
            src={
              job?.company_logo
                ? job?.company_logo
                : `https://cdn-icons-png.flaticon.com/512/174/174857.png`
            }
            className="jobCardImage"
          />
        </div>
        <div className="jobCardRightContainer">
          <div
            className="jobCardComanyName boldText"
            style={{ height: "25px" }}
          >
            {job?.company_name}
          </div>
          <div className="jobCardComanyLocation" style={{ marginLeft: "-5px" }}>
            <LocationOnOutlinedIcon /> {job?.job_location}
          </div>
        </div>
      </div>

      <div className="jobBelowContainer">
        <h3 className="jobCardTitle">{job?.job_title}</h3>
        <div className="jobCardTitleContainer">
          <div className="jobCardDescription">
            <WorkOutlineOutlinedIcon /> &nbsp;{" "}
            <span> {job?.employment_type}</span>
          </div>
          <div className="jobCardDescription">
            {" "}
            <AccessTimeOutlinedIcon /> &nbsp;
            <span className="Days-age">
              {" "}
              {dateDiffInDays(new Date(job?.date_added), new Date(Date.now())) >
              0
                ? `${dateDiffInDays(
                    new Date(job?.date_added),
                    new Date(Date.now())
                  )} Days ago`
                : `Recently posted`}
            </span>
          </div>
        </div>
        {/* <div>{job.short_decription}</div> */}
        <div
          className="jobCardDescriptionBox"
          dangerouslySetInnerHTML={{
            __html: job
              ? job?.job_description?.length > 80 ||
                job?.short_description?.length > 80
                ? `${(job?.job_description || job?.short_description).slice(
                    0,
                    85
                  )}...`
                : job?.job_description
              : "Loading",
          }}
        ></div>
        <p className="card_patagrap">{job?.short_description}</p>
        <div className="jobCardDescriptionBox" style={{ height: "34px" }}>
          MBA Domain : {job?.role}
        </div>
        {/* {job?.role || job["role "]} */}
        <div className="jobCardFooter day-btn-l">
          <h3
            className="jobCardSalary"
            style={{ padding: "10px 0px", marginTop: "10px" }}
          >
            ₹ {job?.job_salary} LPA
          </h3>
          <div>{job?.required_exp}</div>
          <button className="">
            {job?.is_dreamjob === "Yes" ? "Applied!" : "Apply"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
