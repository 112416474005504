import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import "../css/RegEducation.css";
import Select from "react-select";
import "../css/MyProfile.css";
import "../css/MyProfileExperience.css";
import axios from "axios";
import { Alert, AlertTitle } from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useAsyncValue, useNavigate } from "react-router-dom";

import { saveAs } from "file-saver";
import AddModal from "./AddModal";

// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import Button from '@mui/material/Button';

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import baseUrlAPI from "./BaseURL";

const steps = [
  "Basic Info",
  "Education",
  "Work Experience",
  "Others",
  "Preferance",
];

const MyProfileExperience = () => {
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const goToSavedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/saved-jobs");
  };
  const uid = localStorage.getItem("uid");
  useEffect(() => {
    if (uid) {
      navigate("/my-profile-experience");
      window.scrollTo(0, 0);
    } else {
      navigate("/register");
      window.scrollTo(0, 0);
    }
  }, []);

  const goToAppliedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/applied-job");
  };

  const goToProfileOverview = () => {
    window.scrollTo(0, 0);
    navigate("/dashboard");
  };

  const goToGenerateCV = () => {
    window.scrollTo(0, 0);
    navigate("/generate-cv");
  };

  const goToChangePassword = () => {
    window.scrollTo(0, 0);
    navigate("/change-password");
  };

  const firstName = localStorage.getItem("firstName");

  const lastName = localStorage.getItem("lastName");

  const [job_title, setJob_title] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [starting_year, setStarting_year] = useState("");
  const [completion_year, setCompletion_year] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [job_role, setJob_role] = useState("");
  const [achievement, setAchievement] = useState("");
  const [subRoleId, setSubRoleId] = useState([]);

  const [myexp, setMyExp] = useState(null);

  const fetchMyExp = () => {
    const tokenid = localStorage.getItem("candidateToken");

    const candidate_id = localStorage.getItem("uid");

    const getCExperienceID = { token: tokenid, candidate_id: candidate_id };

    const getCExperienceURL = `${baseUrlAPI}/api/getExperience`;

    fetch(getCExperienceURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(getCExperienceID),
    })
      .then((result) => result.json())
      .then((data) => {
        setMyExp(data);
        localStorage.setItem("can-id-e", data[0].candidate_id);
        localStorage.setItem("can-uid-e", data[0].uid);
      })

      .catch((e) => {});
  };

  useEffect(() => {
    fetchMyExp();
  }, []);

  const [industries, setIndustries] = useState(null);
  // current experience

  const [myCurrentexp, setCurrentMyExp] = useState(null);
  const [currentExpYear, setCurrentExpYear] = useState("");
  const [currentExpMonths, setCurrentExpMonths] = useState("");
  const [total_experience, setTotal_experience] = useState("");
  const [current_organization, setCurrent_organization] = useState("");
  const [current_role_name, setCurrent_role_name] = useState("");
  const [we_c_organization, setWe_c_organization] = useState("");
  const [c_industry_category, setC_industry_category] = useState("");
  const [job_tags, setJob_tags] = useState("");
  const [current_total_ctc, setCurrent_total_ctc] = useState("");
  const [current_fixed_ctc, setCurrent_fixed_ctc] = useState("");
  const [notice_period, setNotice_period] = useState("1 Month");
  const [wfh_situation, setWfh_situation] = useState("Work from office");
  const [currentRoleName, setCurrentRoleName] = useState("");
  const [error, setError] = useState([]);
  const [totalMonthExp, setTotalMonthExp] = useState("");
  const [totalYearExp, setTotalYearExp] = useState("");
  const [sub_roles, setSub_roles] = useState([]);

  const [currentExpData, setCurrentExpData] = useState({
    c_industry_category: "",
    current_fixed_ctc: "",
    current_organization: "",
    current_role_name: "",
    current_total_ctc: "",
    notice_period: "",
    profile: "",
    role_id: "",
    subrole_id: "",
    total_experience_months: "",
    total_experience_years: "",
    c_organization_months: "",
    c_organization_years: "",
    wfh_situation: "",
  });
  const fetchCurrentMyExp = () => {
    const tokenid = localStorage.getItem("candidateToken");

    const candidate_id = localStorage.getItem("uid");

    const role_id = localStorage.getItem("role_ID");

    const subrole_id = localStorage.getItem("subRole_ID");

    const currentExperience = localStorage.getItem("currentExperience");

    const getCurrentExperienceID = {
      token: tokenid,
      candidate_id: candidate_id,
      role_id: role_id,
      subrole_id: subrole_id,
      ...currentExperience,
    };

    const getCurrentExperienceURL = `${baseUrlAPI}/api/Current_experience`;

    fetch(getCurrentExperienceURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(getCurrentExperienceID),
    })
      .then((result) => result.json())
      .then((data) => {
        if (data.length === 0) {
          return;
        }
        const currExp = data[0];
        setCurrentExpData(data[0]);
      })

      .catch((e) => {});
  };

  useEffect(() => {
    fetchCurrentMyExp();
  }, []);

  const addExperienceModal = () => {
    return <AddModal type={2} />;
  };

  // get Role API URL

  const [getRole, setGetRole] = useState([]);
  const [roleID, setRoleID] = useState("");
  const [subRole, setSubRole] = useState([]);
  const [subRolStr, setSubRoleStr] = useState("");
  const getRoleUrl = `${baseUrlAPI}/api/getRole`;
  useEffect(() => {
    fetch(getRoleUrl).then((resultRole) => {
      resultRole.json().then((resRole) => {
        setGetRole(resRole.data);
        localStorage.setItem("role_ID", resRole.data[0].uid);
        localStorage.setItem("subRole_ID", resRole.data[0].parent_id);
      });
    });
  }, [getRoleUrl]);

  const handleRole = (event) => {
    const getRoleID = event.target.value;
    setRoleID(getRoleID);
  };
  const handleUpdate = (index, event) => {};
  const deleteExp = async (id) => {
    const url = `${baseUrlAPI}/api/deleteRecords?module=c_experience&uid=${id}`;
    try {
      await axios.get(url);
      window.location.reload();
    } catch (e) {}
  };
  const fetchSubroles = async () => {
    if (currentExpData?.role_id === "") {
      return;
    }
    const { data } = await axios.get(
      `${baseUrlAPI}/api/getSubRole?uid=${currentExpData?.role_id}`
    );
    setSubRole(data.data);
  };
  useEffect(() => {
    fetchSubroles();
  }, [currentExpData?.role_id]);

  const [disabled, setDisabled] = useState(false);
  const [toggle, setToggle] = useState(false);

  let [yearsepa, setYearsepa] = useState("");
  let [monthsepa, setMonthsepa] = useState("");

  let [yearsepat, setYearsepat] = useState("");
  let [monthsepat, setMonthsepat] = useState("");

  const [toggleGoBack, setoggleGoBack] = useState(false);

  const EditCandidateFormGoBack = () => {
    setDisabled(!disabled);
    setoggleGoBack(!toggleGoBack);
  };

  const [toggles, setToggles] = useState(false);
  const deleteUpdatedForms = () => {
    setDisabled(!disabled);
    setToggles(!toggles);
  };

  const [togglee, setTogglee] = useState(false);
  const UpdateCandidateForm = () => {
    setDisabled(!disabled);
    setTogglee(!togglee);
  };

  const EditCandidateForm = () => {
    setDisabled(!disabled);
    setToggle(!toggle);

    let shortyear = myCurrentexp.we_c_organization;
    let workExpCueOrg = shortyear.split(".");

    let yearSep = workExpCueOrg[0];

    setYearsepa(yearSep);

    let monthSep = workExpCueOrg[1];

    setMonthsepa(monthSep);

    let shortyearT = myCurrentexp.total_experience;
    let workExpCueOrgT = shortyearT.split(".");

    let yearSepT = workExpCueOrgT[0];
    setYearsepat(yearSepT);

    let monthSepT = workExpCueOrgT[1];

    setMonthsepat(monthSepT);
  };
  const findRoleName = () => {
    const r = getRole?.find((e) => {
      return e.uid === currentExpData?.role_id;
    });
    return r?.role;
  };
  const fetchSubRoles = async () => {
    if (!currentExpData.role_id) return;
    console.log("working");
    const userSubRoles = JSON.parse(currentExpData.subrole_id);
    const getSubRoleURL = `${baseUrlAPI}/api/getSubRole?uid=${currentExpData.role_id}`;
    const { data } = await axios.get(getSubRoleURL);
    const roleMap = {};
    data.data.forEach((e) => {
      roleMap[e.uid] = e.role;
    });
    const subRoles = userSubRoles.map((e) => {
      return roleMap[e];
    });
    setSub_roles(subRoles);
  };
  useEffect(() => {
    fetchSubRoles();
  }, [currentExpData.role_id]);
  const findSubRoles = () => {
    // return sub_roles;
  };
  const updateCurrentExperience = async () => {
    const token = localStorage.getItem("candidateToken");

    const body = { ...currentExpData, token: token };

    const { data } = await axios.post(
      `${baseUrlAPI}/api/updateCurrentExperience`,
      body
    );
    setMessage("Record has been updated!");
  };
  const renderSubroles = () => {
    return sub_roles?.map((e) => {
      return <div>{e.label}</div>;
    });
  };
  const getAllIndustries = async () => {
    const industryURL = `${baseUrlAPI}/api/industry`;
    const { data } = await axios.get(industryURL);
    setIndustries(data.data);
  };
  useEffect(() => {
    getAllIndustries();
  }, []);
  const handleUpdateExpience = (type, value) => {
    if (type === "month") {
    }
  };
  const handleExperience = (type, value) => {
    const temp = { ...currentExpData };
    let updatedVal;

    if (type === "c_organization_months") {
      if (value > 12 || value < 0) {
        return;
      }
      const expArr = temp.we_c_organization.split(".");
      expArr[1] = value;
      temp.we_c_organization = expArr.join(".");
    } else if (type === "c_organization_years") {
      if (value < 0) {
        return;
      }
      const expArr = temp.we_c_organization.split(".");
      expArr[0] = value;
      temp.we_c_organization = expArr.join(".");
    } else if (type === "total_experience_month") {
      if (value < 0) {
        return;
      }
      const expArr = temp.total_experience.split(".");
      expArr[1] = value;
      temp.total_experience = expArr.join(".");
    } else if (type === "total_experience_year") {
      if (value < 0) {
        return;
      }
      const expArr = temp.total_experience.split(".");
      expArr[0] = value;
      temp.total_experience = expArr.join(".");
    }

    setCurrentExpData(temp);
    // 2.1
  };
  const handleChangeCurrentExp = (e) => {
    const temp = { ...currentExpData };
    temp[e.target.name] = e.target.value;
    setCurrentExpData(temp);
  };
  const toggleSwitch = () => {
    if (!toggle) {
      return "disableForm";
    } else {
      return "";
    }
  };
  const renderSubRoles = () => {
    console.log({ sub_roles });
    return (
      <div className="subroles">
        {sub_roles?.map((e) => {
          return <div>{e}</div>;
        })}
      </div>
    );
  };
  const renderCurrentExperienceInfo = () => {
    return (
      <div>
        <div className="edi-form">
          <span
            className="edit_form"
            style={{ backgroundColor: toggleGoBack ? "#68174a" : "#ef4625" }}
            onClick={() => navigate("/my-profile-education")}
            onChange={EditCandidateFormGoBack}
          >
            Go Back
          </span>
          <span
            className="edit_form"
            style={{ backgroundColor: toggle ? "#68174a" : "#ef4625" }}
            onClick={EditCandidateForm}
          >
            Edit Form
          </span>
        </div>
        <div className="expe">
          <div className="company-name">
            <label htmlFor="">Current Organization *</label> <br />
            <input
              type="text"
              name="current_organization"
              placeholder="Current Organization "
              defaultValue={currentExpData?.current_organization}
              onChange={(e) => handleChangeCurrentExp(e)}
              disabled={!disabled}
              style={{
                border: toggle ? "1px solid gray" : "none",
                backgroundColor: "white",
              }}
            />
          </div>

          <div className="job-title">
            <label htmlFor="" className="cu-mar">
              Current role name
            </label>{" "}
            <br />
            <input
              type="text"
              placeholder="Profile"
              name="current_role_name"
              defaultValue={currentExpData?.current_role_name}
              onChange={(e) => {
                handleChangeCurrentExp(e);
              }}
              disabled={!disabled}
              style={{
                border: toggle ? "1px solid gray" : "none",
                backgroundColor: "white",
              }}
            />
          </div>
        </div>
        <div className="expe">
          <div className="job-titlee">
            <label className="ptt" htmlFor="">
              Work Exp in current org: Year & Month *
            </label>
            <br />
            <input
              type="number"
              placeholder="Year"
              defaultValue={currentExpData?.we_c_organization?.split(".")[0]}
              onChange={(e) =>
                handleExperience("c_organization_years", e.target.value)
              }
              disabled={!disabled}
              style={{
                border: toggle ? "1px solid gray" : "none",
                backgroundColor: "white",
              }}
            />
            <input
              type="number"
              placeholder="Month"
              defaultValue={currentExpData?.we_c_organization?.split(".")[1]}
              onChange={(e) =>
                handleExperience("c_organization_months", e.target.value)
              }
              disabled={!disabled}
              style={{
                border: toggle ? "1px solid gray" : "none",
                backgroundColor: "white",
              }}
            />
          </div>
          <div className="job-titlee">
            <label className="ptt" htmlFor="">
              Total Work Experience: Year & Month *
            </label>{" "}
            <br />
            <input
              type="number"
              placeholder="Year"
              // defaultValue={myCurrentexp.total_experience}
              defaultValue={currentExpData?.total_experience?.split(".")[0]}
              // onChange={(e) => setTotal_experience(e.target.value)}
              onChange={(e) =>
                handleExperience("total_experience_year", e.target.value)
              }
              disabled={!disabled}
              style={{
                border: toggle ? "1px solid gray" : "none",
                backgroundColor: "white",
              }}
            />
            <input
              type="number"
              placeholder="Months"
              defaultValue={currentExpData?.total_experience?.split(".")[1]}
              onChange={(e) =>
                handleExperience("total_experience_month", e.target.value)
              }
              disabled={!disabled}
              style={{
                border: toggle ? "1px solid gray" : "none",
                backgroundColor: "white",
              }}
            />
          </div>
        </div>
        <div className="expe">
          <div className="company-name">
            <label htmlFor="">
              Current Total CTC <br /> (in Lakhs per Annum) *
            </label>{" "}
            <br />
            <input
              type="number"
              name="current_total_ctc"
              placeholder="Current CTC"
              value={currentExpData?.current_total_ctc / 100000}
              onChange={(e) => {
                e.target.value = e.target.value * 100000;
                handleChangeCurrentExp(e);
              }}
              disabled={!disabled}
              style={{
                border: toggle ? "1px solid gray" : "none",
                backgroundColor: "white",
              }}
            />
          </div>

          <div className="job-title">
            <label>
              Fixed Total CTC <br /> (in Lakhs per Annum) *
            </label>{" "}
            <br />
            <input
              type="number"
              name="current_fixed_ctc"
              placeholder="Fixed CTC"
              value={currentExpData?.current_fixed_ctc / 100000}
              onChange={(e) => {
                e.target.value = e.target.value * 100000;
                handleChangeCurrentExp(e);
              }}
              disabled={!disabled}
              style={{
                border: toggle ? "1px solid gray" : "none",
                backgroundColor: "white",
              }}
            />
          </div>
        </div>
        <div className="row">
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            className="display-block"
          >
            <div className="col-lg-4 role-s ">
              <label htmlFor="">MBA Domain</label>
              <br />
              <select
                id=""
                disabled={!disabled}
                style={{
                  border: toggle ? "1px solid gray" : "none",
                  backgroundColor: "white",
                  appearance: toggle ? "auto" : "none",
                }}
                name="role_id"
                onChange={(e) => {
                  handleRole(e);
                  handleChangeCurrentExp(e);
                  setCurrent_role_name(e.target.value);
                }}
              >
                <option disabled selected>
                  {findRoleName()}
                </option>
                {getRole?.map((value) => {
                  return (
                    <option key={value.uid} value={value.uid}>
                      {value.role}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="Durationn">
              <label htmlFor="">Sub Domains</label> <br />
              {!disabled ? (
                renderSubRoles()
              ) : (
                <div>
                  <Select
                    defaultValue={findSubRoles()}
                    // defaultValue={sub_roles}
                    isMulti
                    name=""
                    dis
                    isDisabled={!disabled}
                    style={{
                      border: toggle ? "1px solid gray" : "none",
                      backgroundColor: "white",
                    }}
                    options={
                      subRole && subRole.length === 0
                        ? null
                        : subRole?.map((e, i) => {
                            return {
                              key: i,
                              value: e.uid,
                              label: e.role,
                            };
                          })
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => {
                      if (e.length > 3) {
                        return;
                      }
                      const name = "subrole_id";
                      const value = e?.map((val) => {
                        return val.value;
                      });

                      const obj = {
                        target: {
                          name,
                          value: JSON.stringify(value),
                        },
                      };
                      handleChangeCurrentExp(obj);
                    }}
                  />
                  <span className="please">(Please select upto 3)</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-4 role-s">
            <label htmlFor="">Industry Category</label> <br />
            <select
              name="c_industry_category"
              id=""
              defaultValue={currentExpData?.c_industry_category}
              onChange={(e) => handleChangeCurrentExp(e)}
              disabled={!disabled}
              style={{
                border: toggle ? "1px solid gray" : "none",
                backgroundColor: "white",
                appearance: toggle ? "auto" : "none",
              }}
            >
              <option selected disabled>
                {currentExpData?.c_industry_category}
              </option>
              {industries?.map((e) => {
                return <option value={e[0]}>{e[0]}</option>;
              })}
            </select>
          </div>
        </div>
        <div className="expe">
          <div className="company-name">
            <label className="ptt" htmlFor="">
              Notice Period *
            </label>{" "}
            <br />
            <select
              name="notice_period"
              id=""
              onChange={(e) => handleChangeCurrentExp(e)}
              disabled={!disabled}
              style={{
                border: toggle ? "1px solid gray" : "none",
                backgroundColor: "white",
              }}
            >
              <option selected disabled>
                {currentExpData?.notice_period}
              </option>
              <option value="1 Months">1 Month</option>
              <option value="2 Months">2 Months</option>
              <option value="3 Months">3 Months</option>
              <option value="Others">Other</option>
            </select>
          </div>
          <div className="job-title">
            <label className="ptt" htmlFor="">
              WFH Situation *
            </label>{" "}
            <br />
            <select
              name="wfh_situation"
              id=""
              defaultValue={currentExpData?.wfh_situation}
              disabled={!disabled}
              style={{
                border: toggle ? "1px solid gray" : "none",
                backgroundColor: "white",
                appearance: toggle ? "auto" : "none",
              }}
              onChange={(e) => {
                handleChangeCurrentExp(e);
              }}
            >
              <option selected disabled>
                {currentExpData?.wfh_situation}
              </option>
              <option value="Complete">Complete</option>
              <option value="Partial">Partial</option>
              <option value="Work from office">Work from office</option>
            </select>
          </div>
        </div>{" "}
      </div>
    );
  };
  const updateExp = (e, i) => {
    let newFormValues = [...myexp];
    if (e.target.type === "checkbox") {
      newFormValues[i][e.target.name] = e.target.checked;
      setMyExp(newFormValues);
    } else {
      newFormValues[i][e.target.name] = e.target.value;
      setMyExp(newFormValues);
    }
  };
  const updateExperience = async (i) => {
    const valToUpdate = myexp[i];
    const token = localStorage.getItem("candidateToken");
    const candidate_id = localStorage.getItem("uid");
    const updateObj = {
      token,
      candidate_id,
      uid: valToUpdate.uid,
      company_name: valToUpdate.company_name,
      job_title: valToUpdate.job_title,
      location: valToUpdate.location,
      starting_year: valToUpdate.starting_year,
      completion_year: valToUpdate.completion_year,
      description: valToUpdate.description,
      acheivement: valToUpdate.acheivement,
      job_role: valToUpdate.job_role,
    };
    const { data } = await axios.post(
      `${baseUrlAPI}/api/updateExperience`,
      updateObj
    );
    setMessage("Experience has been updated");
    window.location.reload();
  };
  let handleChange = (i, e) => {
    let newFormValues = [...myexp];
    if (e.target.type === "checkbox") {
      newFormValues[i][e.target.name] = e.target.checked;
      setMyExp(newFormValues);
    } else {
      newFormValues[i][e.target.name] = e.target.value;
      setMyExp(newFormValues);
    }
  };

  const rendermyexpcationInfo = () => {
    return myexp?.map((element, index) => {
      return (
        <div name="input" key={index} className="or-add-more-ad">
          <div className="org-lo row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label htmlFor="">Job Title *</label> <br />
              <input
                type="text"
                placeholder="Job Profile"
                name="job_title"
                value={element.job_title || ""}
                onChange={(e) => handleChange(index, e)}
                disabled={!disabled}
                style={{
                  border: toggle ? "1px solid gray" : "none",
                  backgroundColor: "white",
                  appearance: toggle ? "auto" : "none",
                }}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label htmlFor="">Company Name *</label> <br />
              <input
                disabled={!disabled}
                style={{
                  border: toggle ? "1px solid gray" : "none",
                  backgroundColor: "white",
                  appearance: toggle ? "auto" : "none",
                }}
                type="text"
                placeholder="Company Name"
                name="company_name"
                value={element.company_name || ""}
                onChange={(e) => handleChange(index, e)}
              />
            </div>
          </div>

          <div className="org-lo row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label htmlFor="">Starting year *</label> <br />
              <input
                disabled={!disabled}
                style={{
                  border: toggle ? "1px solid gray" : "none",
                  backgroundColor: "white",
                  appearance: toggle ? "auto" : "none",
                }}
                type="month"
                placeholder="Starting year"
                name="starting_year"
                value={element.starting_year || ""}
                onChange={(e) => handleChange(index, e)}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label htmlFor="">Completion Year *</label> <br />
              <input
                type="month"
                disabled={!disabled}
                style={{
                  border: toggle ? "1px solid gray" : "none",
                  backgroundColor: "white",
                  appearance: toggle ? "auto" : "none",
                }}
                placeholder="Completion Year"
                name="completion_year"
                value={element.completion_year || ""}
                onChange={(e) => handleChange(index, e)}
                className={element.job_role ? "hide-cy" : "input"}
              />
            </div>
            <div className="ml-fp-r">
              {index === 0 ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      value={element.job_role || ""}
                      name="job_role"
                      onChange={(e) => handleChange(index, e)}
                    />
                  }
                  label="This is my current job role"
                  className="check-label"
                />
              ) : null}
            </div>
            <div className="org-lo ">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="">Location *</label> <br />
                <input
                  disabled={!disabled}
                  style={{
                    border: toggle ? "1px solid gray" : "none",
                    backgroundColor: "white",
                    appearance: toggle ? "auto" : "none",
                  }}
                  type="text"
                  placeholder="Location"
                  name="location"
                  value={element.location || ""}
                  onChange={(e) => handleChange(index, e)}
                />
              </div>
            </div>
          </div>

          <div className="j-titlee">
            <label htmlFor="">
              Role & Responsibilities*{" "}
              <span className="red-span">
                (Add ";" for adding bullet point)
              </span>
            </label>{" "}
            <br />
            <textarea
              disabled={!disabled}
              style={{
                border: toggle ? "1px solid gray" : "none",
                backgroundColor: "white",
                appearance: toggle ? "auto" : "none",
              }}
              id=""
              cols="30"
              rows="5"
              placeholder="Role & Responsibilities ...."
              name="description"
              value={element.description || ""}
              onChange={(e) => handleChange(index, e)}
            ></textarea>
          </div>

          <div className="j-titlee">
            <label htmlFor="">
              Initiatives and Achievements*{" "}
              <span>
                <span className="red-span">
                  (Add ";" for adding bullet point)
                </span>
              </span>
            </label>{" "}
            <br />
            <textarea
              disabled={!disabled}
              style={{
                border: toggle ? "1px solid gray" : "none",
                backgroundColor: "white",
                appearance: toggle ? "auto" : "none",
              }}
              id=""
              cols="30"
              rows="5"
              placeholder="Achievement ...."
              name=" achievement"
              value={element.achievement || ""}
              onChange={(e) => handleChange(index, e)}
            ></textarea>
          </div>

          <hr />

          {/* hi please change here  */}
          <div className="btn-flex">
            <button
              className="Updates"
              style={{ backgroundColor: togglee ? "#68174a" : "#ef4625" }}
              onClick={(e) => {
                e.preventDefault();
                updateExperience(index);
                updateCurrentExperience();
                UpdateCandidateForm();
              }}
            >
              Update
            </button>
            <span className="text-btn-button">
              Please press the update button to update the new data in this
              section
            </span>
            <div
              style={{ backgroundColor: toggles ? "#68174a" : "#ef4625" }}
              className="Updates"
              onClick={() => {
                deleteExp(element.uid);
              }}
              onChange={deleteUpdatedForms}
            >
              <DeleteIcon />
            </div>
          </div>
        </div>
      );
    });
  };

  const Position = localStorage.getItem("position");

  const DownloadCV = async (e) => {
    e.preventDefault();
    // import { saveAs } from "file-saver";
    const candidate_id = localStorage.getItem("uid");

    const DownloadResume = { uid: candidate_id };

    const DownloadCVURL = `${baseUrlAPI}/api/pdf`;

    fetch(DownloadCVURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(DownloadResume),
    })
      .then((res) => res.blob())
      .then((data) => {
        saveAs(data, "cv.pdf");
      });
  };

  return (
    <>
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 className="dashboard-heading-d">My Profile </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="Profile-Overview">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
              <div className="flex-p-overview-my-pro">
                <p className="bol-pro" onClick={goToProfileOverview}>
                  <ion-icon name="search-circle-outline"></ion-icon> Profile
                  Overview
                </p>
                <p className="bol-pro" onClick={goToAppliedJobs}>
                  <ion-icon name="business-outline"></ion-icon> Applied Jobs
                </p>
                <p className="bol-pro" onClick={goToSavedJobs}>
                  <ion-icon name="bookmark-outline"></ion-icon> Saved Jobs
                </p>
                <p className="pro-over-col bol-pro">
                  <ion-icon name="person-circle-outline"></ion-icon> My Profile
                </p>
                {/* <p className='bol-pro' onClick={goToMessages}><ion-icon name="chatbox-ellipses-outline"></ion-icon> Messages</p> */}
                <p className="bol-pro" onClick={DownloadCV}>
                  <ion-icon name="document-outline"></ion-icon> Generate CV
                </p>
                <p className="bol-pro" onClick={goToChangePassword}>
                  <ion-icon name="lock-closed-outline"></ion-icon> Change
                  Password
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Deactivate
                  Account
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Log Out
                </p>
              </div>
            </div>

            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
              <div className="pad-mar-my-proo">
                <div className="my-pro-pad-mar">
                  {/* <img
                    className="g-i-avtar"
                    src="https://media.istockphoto.com/id/1288732130/photo/portrait-of-cheerful-mixed-race-teenage-girl.jpg?b=1&s=170667a&w=0&k=20&c=R3BwP5rv0nG441whzRmAaLXCs9OZMdZHKOfUw0oj4sQ="
                    alt="girl"
                  /> */}
                  <div>
                    <h3>
                      {firstName} {lastName}
                    </h3>
                    {/* <h4>{firstName} {lastName}</h4> */}
                    <p>
                      {/* {profile ? profile : Position} &nbsp; &nbsp;{" "} */}
                      {/* <ion-icon name="create-outline"></ion-icon> */}
                    </p>
                  </div>
                </div>

                <div className="box-pad-mar-k">
                  <Box sx={{ width: "100%" }} className="option-of-option">
                    <Stepper activeStep={2} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label} className="option-to-recognization">
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </div>
                {/* {renderBasicInfo()} */}

                <div className="r-form-first">
                  <div className="container">
                    {/* <span
                      className="edit_formm"
                      onClick={() => navigate("/my-profile-education")}
                    >
                      Go Back
                    </span> */}

                    <form action="">
                      {renderCurrentExperienceInfo()}
                      <br />
                      <div className="flex-ad-mo">
                        <h4 className="post-gr-col-font">
                          Work Experience Details:{" "}
                        </h4>

                        {message && (
                          <Alert
                            onClose={() => {
                              setMessage("");
                            }}
                            severity="success"
                          >
                            {message}
                          </Alert>
                        )}
                        {addExperienceModal()}
                        {/* <h4 className="post-gr-col-font" onClick={() => addFormFields()}>
                  Add More
                </h4> */}
                      </div>

                      {rendermyexpcationInfo()}
                    </form>

                    {/* <hr /> */}
                    {/* <div className="ml-fp-r">
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Agree our terms and policy"
                            className="check-label"
                        />
                        <button className="btn-label">Back</button>
                        </div> */}

                    <div className="r-btn-next">
                      <button
                        onClick={() => {
                          navigate("/my-profile-others");
                        }}
                      >
                        Proceed To Next Step
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfileExperience;
