// Preferance

import React, { useState, useEffect } from "react";

import "../css/RegPreference.css";

import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// import Button from '@mui/material/Button';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';

// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";

import baseUrlAPI from "./BaseURL";

const steps = [
  "Basic Info",
  "Education",
  "Work Experience",
  "Others",
  "Preference",
];

const RegPreference = () => {
  const uid = localStorage.getItem("uid");
  useEffect(() => {
    if (uid) {
      navigate("/preference");
      window.scrollTo(0, 0);
    } else {
      navigate("/register");
      window.scrollTo(0, 0);
    }
  }, []);

  // api input post preference
  const [formattedCTC, setFormattedCTC] = useState("");
  const [expected_ctc, setExpected_ctc] = useState([12, 17]);
  const [willing_relocate, setWilling_relocate] = useState("Yes");
  const [notice_period, setNotice_period] = useState("Yes");
  const [recruiter_name, setRecruiter_name] = useState("");
  const [recruiter_number, setRecruiter_number] = useState("");
  const [error, setError] = useState([]);
  const navigate = useNavigate();

  const goToWelcome = (e) => {
    e.preventDefault();
    console.log(typeof recruiter_number);
    const errors = [];
    // switch (true) {
    //   case expected_ctc[1] - expected_ctc[0] > 5:
    //     errors.push("Expected Range should deffer only by 5Lakhs");
    //   // case willing_relocate
    // }

    if (errors.length !== 0) {
      return setError(errors);
    }
    const tokenid = localStorage.getItem("candidateToken");

    const candidate_id = localStorage.getItem("uid");
    const expectedCTC = [expected_ctc[0] * 100000, expected_ctc[1] * 100000];
    const formattedCTC = expected_ctc.map((ctc) => ctc / 100000);
    const Preference = {
      expected_ctc: JSON.stringify(expectedCTC),
      willing_relocate,
      notice_period,
      recruiter_number,
      formattedCTC,
      recruiter_name,
      token: tokenid,
      candidate_id,
    };
    console.log(Preference);

    const AddPreferenceURL = `${baseUrlAPI}/api/AddPreference`;

    fetch(AddPreferenceURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(Preference),
    })
      .then((result) => result.json())
      .then((data) => {
        console.log("result", data);
        if (data.status === 200) {
          alert("success");
        }
      })

      .catch((e) => {
        console.log("e", e);
      });
    navigate("/dashboard");
  };
  const renderErrror = () => {
    return error.map((e) => {
      return <Alert severity="error">{e}</Alert>;
    });
  };

  return (
    <>
      <div className="Free-Today">
        <div className="container">
          <div className="fr-geg-para-dev">
            <h5 className="reg-to">Register</h5>
            <h1 className="reg-start">
              Start for <span>Free Today</span>{" "}
            </h1>
            <p className="reg-para">
              Access to all features. No credit card required.
            </p>
          </div>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={4} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>

        <div className="r-form-first">
          <div className="container">
            {/* <form action="">
            <div className="expe">
                <div className="company-name">
                    <label htmlFor="">Location*</label> <br />
                    <select name="" id="" value={location} onChange={(e)=>setLocation(e.target.value)}>
                        <option value="Noida">Noida</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Gurugram">Gurugram</option>
                    </select>
                </div>
                <div className="job-title">
                    <label htmlFor="">Employment Type *</label> <br />
                    <select name="" id="" value={employement_type} onChange={(e)=>setEmployement_type(e.target.value)}>
                        <option value="Part Time">Part Time</option>
                        <option value="Full Time">Full Time</option>
                        
                    </select>
                </div>
            </div>

            <div className="expe">
              <div className="company-name">
                <label htmlFor="" className='ind-ind-mec'>Industry *</label> <br />
                <select name="" id="" value={industry} onChange={(e)=>setIndustry(e.target.value)}>
                    <option value="IT Industry">IT Industry</option>
                    <option value="Mechanical Industry">Mechanical Industry</option>
                    <option value="AeroSpace Industry">AeroSpace Industry</option>
                </select>
              </div>
              <div className="job-title">
                <label htmlFor=""  className='ind-ind-mec'>Candidate ID *</label> <br />
                <input type="text" placeholder='Candidate ID' value={candidate_id} onChange={(e)=>setCandidate_id(e.target.value)}/>
              </div>
            </div>
            
            <div className="rnr">
                <div className="rnr-flex">
                  <label htmlFor="">Industry *</label> 
                  <button>Add More</button>
                </div> <br />
                <input type="text" placeholder='Description' />
            </div>

            <div className="expe">
                <div className="company-name">
                    <label htmlFor="" className='cn-cn'>Salary Range*</label> <br />
                    <select name="" id="" value={salary_range} onChange={(e)=>setSalary_range(e.target.value)}>
                        <option value="120k - 150k">120k - 150k</option>
                        <option value="150k - 180k">150k - 180k</option>
                        <option value="180k - 200k">180k - 200k</option>
                    </select>
                </div>
                <div className="job-title">
                    <label htmlFor="" className='cn-cn'>Experience*</label> <br />
                    <select name="" id="" value={experience} onChange={(e)=>setExperience(e.target.value)}>
                        <option value="3 - 5 Years">3 - 5 Years</option>
                        <option value="5 - 7 year">5 - 7 year</option>
                        <option value="8 - 10 year">8 - 10 year</option>
                    </select>
                </div>
            </div>

          </form> */}

            <form action="">
              <h4
                className="add-m-ore-gold"
                onClick={() => navigate("/others")}
              >
                Go Back
              </h4>
              {error.length !== 0 ? renderErrror() : null}
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ecpe-k-select">
                  <label htmlFor="">
                    From :{" "}
                    {expected_ctc
                      ? `${expected_ctc[0]} to ${expected_ctc[1]}`
                      : null}{" "}
                  </label>{" "}
                  <br />
                  <div></div>
                  <br />
                  {/* <select name="" id="" value={expected_ctc} onChange={(e)=>setExpected_ctc(e.target.value)}>
                  <option value="120k - 150k">120k - 150k</option>
                  <option value="150k - 180k">150k - 180k</option>
                  <option value="180k - 200k">180k - 200k</option>
                </select> */}
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-num-phon range-slider-in-lak">
                    <br />

                    {/* <input
                      type="range"
                      // placeholder="Any one Recruiter’s Contact number in your organization"
                      value={expected_ctc}
                      onChange={(e) => setExpected_ctc(+e.target.value)}
                    /> */}
                  </div>
                  <RangeSlider
                    isVisible={true}
                    onInput={(e) => {
                      console.log(e);
                      setExpected_ctc(e);
                    }}
                    // defaultValue={[12, 17]}
                    // min={12}
                    // max={200}
                    step={1}
                    label={true}
                  />
                </div>
              </div>

              {/* <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 cl-yes-no">
                <label htmlFor="">Expected Fixed CTC Range</label> <br />
                <select name="" id="">
                  <option value="">120k - 150k</option>
                  <option value="">150k - 180k</option>
                  <option value="">180k - 200k</option>
                </select>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 cl-yes-no">
              <label htmlFor="" className='can-id-hh'>Candidate ID *</label> <br />
              <input type="text" placeholder='Candidate ID' />
              </div>
            </div> */}
              <h5 className="please-note-four">Please note: </h5>
              <p>
                If selected, your negotiation with the employer will be fixed
                between this range so select carefully.More preise your expected
                CTC range,higher chance of getting shortlisted. You can change
                your expected CTC while applying to different jobs as per your
                preference . <br /> <br />
                Fixed CTC range does not include variables, incentives, joining
                and year end bonuses etc and you are free to negotiate on these
                over your Fixed CTC with the employer
              </p>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 cl-yes-no">
                  <label htmlFor="">Willing To relocate *</label> <br />
                  <select
                    name=""
                    className="input"
                    id=""
                    value={willing_relocate}
                    onChange={(e) => setWilling_relocate(e.target.value)}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 cl-yes-no">
                  <label htmlFor="">Notice Period Negotiable </label> <br />
                  <select
                    name=""
                    id=""
                    value={notice_period}
                    className="input"
                    onChange={(e) => setNotice_period(e.target.value)}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-num-phonn">
                  <label htmlFor="">Name of the Recruiter: </label> <br />
                  <input
                    type="text"
                    placeholder="Name of the recruiter"
                    value={recruiter_name}
                    className="input"
                    onChange={(e) => setRecruiter_name(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-num-phon">
                  <label htmlFor="">
                    One Recruiter’s Contact number in your organization:
                  </label>{" "}
                  <br />
                  <input
                    className="input"
                    type="number"
                    placeholder="Any one Recruiter’s Contact number in your organization"
                    value={recruiter_number}
                    onChange={(e) => setRecruiter_number(e.target.value)}
                  />
                </div>
                <p>
                  (This contact will help us fetch more jobs on our portal which
                  will benefit everyone, Your identity or source of the contact
                  will never be mentioned to the recruiter you are listing)
                </p>
              </div>

              {/* <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-num-phonn">
                  <label htmlFor="">Name of the Recruiter: *</label> <br />
                  <input
                    type="text"
                    placeholder="Name of the recruiter"
                    value={recruiter_name}
                    className="input"
                    onChange={(e) => setRecruiter_name(e.target.value)}
                  />
                </div>
              </div> */}
            </form>

            {/* <div className="ml-fp-r">
          <FormControlLabel control={<Checkbox  />} label="Agree our terms and policy" className='check-label'/>
          <button className='btn-label' >Back</button>
          </div> */}

            <div className="r-btn-next">
              <button onClick={goToWelcome}>Register & Submit</button>
            </div>
            {/* <div className="reg1-sign">
            <p>Already have an account? <span>Sign In Now.</span></p>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default RegPreference;
