// git pull origin main

import React, { useState } from "react";
import "../css/Member_Login.css";

import Button from "@mui/material/Button";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";

import ErrorIcon from "@mui/icons-material/Error";

import { Modal, ModalBody, Row, Col } from "reactstrap";

import baseUrlAPI from "./BaseURL";
import { Alert } from "@mui/material";
import SignInWithLinkden from "./LinkdenLogin";
import LoginWithPassword from "./LoginWithPassword";

const Member_Login = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleLoginClick = () => {
    navigate("/login-with-password");
  };
  // post api input login otp
  const [error, setError] = useState([]);
  const [mobile, setMobile] = useState("");
  const [login, setLogin] = useState(false);

  const navigate = useNavigate();

  const goToReset = () => {
    navigate("/reset-password");
  };

  const goToValidateOTP = (e) => {
    e.preventDefault();

    const myMobile = { mobile };
    console.log(myMobile);

    // const urlMemberLogin = "http://3.109.83.81/phc-admin/api/login";

    const urlMemberLogin = `${baseUrlAPI}/api/login`;

    fetch(urlMemberLogin, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(myMobile),
    })
      .then((result) => result.json())
      .then((data) => {
        console.log("result", data);
        console.log(data.status);
        if (data.status === true) {
          navigate("/verify-account");
        } else {
          // navigate('/member-log-in')
          // alert("Please Register First");
          setLogin(true);
        }
        localStorage.setItem("mobile", mobile);
        localStorage.setItem("otp", data.data.otp);

        if (data.status === 200) {
          alert("success");
        }
      })

      .catch((e) => {
        console.log("e", e);
      });

    const errors = [];
    if (mobile.length !== 10) {
      errors.push("Please Enter 10-digit Mobile Number");
    }
    if (isNaN(mobile)) {
      errors.push("Username is not valid");
    }

    if (error !== 0) {
      setError(errors);
    }
  };

  const renderError = () => {
    console.log(error);
    return (
      <Alert severity="error">
        {error.map((e) => {
          return <h5>{e}</h5>;
        })}
      </Alert>
    );
  };

  const MemberLogIn = () => {
    if (error === 0) {
      navigate("/register");
    } else {
      navigate("/member-log-in");
    }
  };
  const goToForget = () => {
    navigate("/forget-password");
  };

  const SignUP = () => {
    navigate("/register");
  };

  return (
    <>
      <div className="Member-Login">
        <Modal size="md" isOpen={login} toggle={() => setLogin(!login)}>
          {/* <ModalHeader toggle={()=>setPopup(!popup)}>
            PopUp
          </ModalHeader> */}
          <ModalBody>
            <form action="">
              <Row>
                <Col lg={12}>
                  <div className="err-icn">
                    <ErrorIcon className="icn-icn" />
                  </div>

                  <h2 className="login-err">USERNAME IS NOT REGISTERED</h2>
                  <h6 className="saveup-cen">
                    Please sign up for start using PHC Platform
                  </h6>
                  {error.length !== 0 ? renderError() : null}

                  <button onClick={SignUP} className="ok_popup">
                    Go To Sign Up
                  </button>
                </Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>

        <div className="container">
          <h5 className="ml-wel">Welcome back!</h5>
          <h1 className="ml-mem">
            Member <span>Login</span>{" "}
          </h1>
          <p className="ml-access">
            {/* Access to all features. No credit card required. */}
          </p>{" "}
          <br />
          <form action="" className="ml-form">
            <br />
            <input
              className="input-mobile input"
              type="text"
              name="mobile"
              placeholder="Enter Registered Email"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </form>{" "}
          <br />
          <div className="ce-cen-ter">
            <div className="ml-fpp">
              <FormControlLabel
                control={<Checkbox />}
                label="Remember Me"
                className="check-labell"
              />
              <button className="btn-labell" onClick={goToForget}>
                Forget Password
              </button>
            </div>
          </div>
          <button className="validatee" onClick={goToValidateOTP}>
            Validate the OTP
          </button>{" "}
          <br /> <br />
          <p className="con-ml">Or continue with</p>
          <button className="validatee" onClick={handleLoginClick}>
            Login with Password
          </button>
          {/* <Button
            className="btn-comp-nbn"
            variant="outlined"
            startIcon={<LinkedInIcon className="link-icnn" />}
          >
            Sign Up with Linkedin
          </Button> */}
          <SignInWithLinkden />
          <br /> <br />
          <div className="signUp">
            <p className="p-signUp">Don't have an Account?</p>
            <p className="btn-sign" onClick={SignUP}>
              Sign up
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Member_Login;
