// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import "../css/Verify_Account.css";

// import baseUrlAPI from "./BaseURL";

// const LoginWithPassword = () => {
//   const navigate = useNavigate();

//   const [password, setPassword] = useState("");
//   const [mobile, setMobile] = useState("");

//   const Login = (e) => {
//     e.preventDefault();
//     const myPASS = { password, mobile };

//     const checkPasswordURL = `${baseUrlAPI}/api/checkPassword`;
//     fetch(checkPasswordURL, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//       body: JSON.stringify(myPASS),
//     })
//       .then((result) => result.json())
//       .then((data) => {
//         console.log("result", data);

//         const passworP = localStorage.getItem("password");

//         if (password === passworP) {
//           navigate("/dashboard");
//         }
//       })
//       .catch((e) => {
//         console.log("e", e);
//       });
//   };

//   return (
//     <>
//       <div className="va-pad">
//         <div className="Verify-your-Account containe">
//           <div className="width-otp">
//             <h2 className="vya-veri">
//               Enter your <span>Password</span>{" "}
//             </h2>
//             <form action="" className="ml-form">
//               <br />
//               <input
//                 className="input-mobile input"
//                 type="number"
//                 name="mobile"
//                 placeholder="Enter Registered Mobile"
//                 value={mobile}
//                 onChange={(e) => setMobile(e.target.value)} // Update the state here
//               />
//             </form>{" "}
//             <br></br>
//             <input
//               type="text"
//               placeholder="Password"
//               className="input-mobile input"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//             />{" "}
//             <br /> <br />
//             <button className="validate" onClick={Login}>
//               Login
//             </button>{" "}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Verify_Account.css";
import baseUrlAPI from "./BaseURL";

const LoginWithPassword = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");

  const Login = (e) => {
    e.preventDefault();
    const myPASS = { password, mobile };

    const checkPasswordURL = `${baseUrlAPI}/api/checkPassword`;
    fetch(checkPasswordURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application /json",
      },
      body: JSON.stringify(myPASS),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("result", data);

        const candidateToken = data?.token;
        // const uid = data?.candidates?.uid;
        // console.log(passwordP, "aaaa");

        if (candidateToken) {
          localStorage.setItem("candidateToken", candidateToken);
          localStorage.setItem("firstName", data.candidates.first_name);
          localStorage.setItem("lastName", data.candidates.last_name);

          localStorage.setItem("candidateData", data.candidates.mobile);
          localStorage.setItem("candidateToken", data.token);

          localStorage.setItem("uid", data.candidates.uid);

          localStorage.setItem("can_uid", data.candidates.uid);

          navigate("/dashboard"); // Navigate to the dashboard on successful login
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An Password is incorrect . Please try again later.");
      });
  };

  return (
    <>
      <div className="va-pad">
        <div className="Verify-your-Account containe">
          <div className="width-otp">
            <h2 className="vya-veri">
              Enter your <span>Password</span>{" "}
            </h2>
            <form action="" className="ml-form">
              <br />
              <input
                className="input-mobile input"
                type="number"
                name="mobile"
                placeholder="Enter Registered Mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </form>{" "}
            <br />
            <input
              type="password" // Use type="password" for password input
              placeholder="Password"
              className="input-mobile input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />{" "}
            <br /> <br />
            <button className="validate" onClick={Login}>
              Login
            </button>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginWithPassword;
