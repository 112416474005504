import react, { useState } from "react";
const AddEducationForm = ({ handleChange }) => {
  const [college_name, setCollege_name] = useState("");
  const [degree_name, setDegree_name] = useState("");
  const [initiation_year, setInitiation_year] = useState("");
  const [passout_year, setPassout_year] = useState("");
  const [passing_score, setPassing_score] = useState("");

  return (
    <div name="input">
      <div className="reg2">
        <div className="school">
          <label htmlFor="college_name">School/College *</label> <br />
          <input
            type="text"
            name="college"
            id="college_name"
            onChange={(e) => {
              // handleCollegeU(e);
              handleChange(e);
            }}
            // style={{backgroundColor: toggle ? "unset" : "#fde8eb"}}
          />
        </div>
        <div className="pro">
          <label htmlFor="">Degree Name *</label> <br />
          <input
            type="text"
            name="degree"
            id=""
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>

      <div className="reg3">
        <div className="pass-out-year-h">
          <label htmlFor="">Pass In Year *</label> <br />
          <input
            type="number"
            name="i_year"
            defaultValue={initiation_year}
            onChange={(e) => handleChange(e)}
          />
        </div>

        <div className="pass-out-year-h-mm">
          <label htmlFor="">Pass Out Year *</label> <br />
          <input
            type="number"
            name="o_year"
            defaultValue={passout_year}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>

      <div className="reg3">
        <div className="pass-out-year-h">
          <label htmlFor="">Percentage *</label> <br />
          <input
            type="number"
            className="gr-fl-bt"
            name="score"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="note-res">
        <h5>
          Note : If your score was not in percentage then please convert it to
          percentage.
        </h5>
      </div>
      <hr />
    </div>
  );
};

export default AddEducationForm;
