import React, { useEffect, useState } from "react";
import "../css/MyProfile.css";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import axios from "axios";
import Radio from "@mui/material/Radio";
import { Alert, AlertTitle } from "@mui/material";

import RadioGroup from "@mui/material/RadioGroup";

import FormControl from "@mui/material/FormControl";

import FormLabel from "@mui/material/FormLabel";

import { saveAs } from "file-saver";

// import LinkedInIcon from '@mui/icons-material/LinkedIn'; 8959869584  8987788987
// import Button from '@mui/material/Button'; 8956784589

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";

import baseUrlAPI from "./BaseURL";

const steps = [
  "Basic Info",
  "Education",
  "Work Experience",
  "Others",
  "Preference",
];

const MyProfile = () => {
  const [message, setMessage] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");

  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState(0);
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("Male");
  const [current_location, setCurrent_location] = useState("");
  const [linkedIn_profile_link, setLinkedIn_profile_link] = useState("");
  const [b_school_name, setB_school_name] = useState("");
  const [mba_specialization, setMba_specialization] = useState("");
  const [basicInfo, setBasicInfo] = useState(null);
  const [occupations, setOccupations] = useState("");

  const uid = localStorage.getItem("uid");
  useEffect(() => {
    if (uid) {
      navigate("/my-profile");
      window.scrollTo(0, 0);
    } else {
      navigate("/register");
      window.scrollTo(0, 0);
    }
  }, []);
  const navigate = useNavigate();

  // const goToMessages = () => {
  //     window.scrollTo(0, 0)
  //     navigate('/messages')
  // }

  const goToSavedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/saved-jobs");
  };

  const goToAppliedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/applied-job");
  };

  const goToProfileOverview = () => {
    window.scrollTo(0, 0);
    navigate("/dashboard");
  };

  const goToGenerateCV = () => {
    window.scrollTo(0, 0);
    navigate("/generate-cv");
  };

  const goToChangePassword = () => {
    window.scrollTo(0, 0);
    navigate("/change-password");
  };
  const fetchBasicUserInfo = () => {
    const tokenid = localStorage.getItem("candidateToken");

    const candidate_id = localStorage.getItem("uid");

    const getProfileID = { token: tokenid, id: candidate_id };

    const getProfileURL = `${baseUrlAPI}/api/getProfile`;

    fetch(getProfileURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(getProfileID),
    })
      .then((result) => result.json())
      .then((data) => {
        // setBasicInfo(data[0]);
        const existingData = data[0];
        console.log({ existingData });
        setFirst_name(existingData.first_name);
        setLast_name(existingData.last_name);
        setDob(existingData.dob);
        setGender(existingData.gender);
        setEmail(existingData.email);
        setMobile(existingData.mobile);
        setCurrent_location(existingData.current_location);
        setLinkedIn_profile_link(existingData["linkedIn_profile_link"]);
        setB_school_name(existingData.b_school_name);
        setMba_specialization(existingData.mba_specialization);
        setOccupations(existingData.occupations);
      })

      .catch((e) => {});
  };

  useEffect(() => {
    fetchBasicUserInfo();
  }, []);

  // const goToProceedToNextStep = async (e) => {
  //   e.preventDefault();

  //   const tokenid = localStorage.getItem("candidateToken");

  //   const candidate_id = localStorage.getItem("uid");
  //   const candidateToken = localStorage.getItem("candidateToken");
  //   const id = localStorage.getItem("uid");

  //   try {
  //     const { data } = await axios.post(
  //       `${baseUrlAPI}/api/updateCandidate`,
  //       updateObj
  //     );

  //     // navigate("/my-profile-education");
  //   } catch (e) {}
  // };

  const [disabled, setDisabled] = useState(false);
  const [toggle, setToggle] = useState(false);

  const EditCandidateForm = () => {
    setDisabled(!disabled);
    setToggle(!toggle);
    // setMessage("Record has been updated!");
  };
  const findName = (type) => {
    let val = "";
    if (type === 1) {
      collegeU?.forEach((e) => {
        if (e.uid === b_school_name) {
          val = e.college_name;
          // return e.college_name;
        }
      });
    }
    return val;
  };
  const [disabledd, setDisabledd] = useState(false);
  const [togglee, setTogglee] = useState(false);

  const proceedToNext = async (e) => {
    e.preventDefault();
    setDisabledd(!disabled);
    setTogglee(!togglee);

    const tokenid = localStorage.getItem("candidateToken");

    const candidate_id = localStorage.getItem("uid");
    const candidateToken = localStorage.getItem("candidateToken");
    const id = localStorage.getItem("uid");

    const updateObj = {
      first_name,
      last_name,
      token: candidateToken,
      uid: id,
      mobile,
      email,
      dob,
      gender,
      current_location,
      b_school_name,
      mba_specialization,
      linkedIn_profile_link: linkedIn_profile_link,
      occupations,
    };
    const { data } = await axios.post(
      `${baseUrlAPI}/api/updateCandidate`,
      updateObj
    );
    setUpdateMessage("Record  has been updated !");
  };

  const proceed = () => {
    navigate("/my-profile-education");
  };
  const renderBasicInfo = () => {
    return (
      <div className="my-pro-inn-pro">
        {updateMessage && (
          <Alert
            onClose={() => {
              setUpdateMessage("");
            }}
            severity="success"
          >
            {updateMessage}
          </Alert>
        )}
        <div className="edi-form">
          <span
            className="edit_form"
            style={{ backgroundColor: toggle ? "#68174a" : "#ef4625" }}
            onClick={EditCandidateForm}
          >
            Edit Form
          </span>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form className="for-m-my-pr" action="">
                  <label className="label-all-pro" htmlFor="">
                    First Name *
                  </label>
                  <input
                    className="input-for-m p-1"
                    type="text"
                    placeholder="Enter Your Name"
                    defaultValue={first_name}
                    onChange={(e) => setFirst_name(e.target.value)}
                    disabled={!disabled}
                    // style={{backgroundColor: toggle ? "unset" : "#fde8eb"}}
                    style={{
                      border: toggle ? "1px solid gray" : "none",
                      backgroundColor: "white",
                      paddingLeft: "0px",
                    }}
                  />
                </form>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form action="">
                  <label className="label-all-pro" htmlFor="">
                    Last Name *
                  </label>
                  <input
                    className="input-for-m p-1"
                    type="text"
                    placeholder="Enter Your Name"
                    defaultValue={last_name}
                    onChange={(e) => setLast_name(e.target.value)}
                    disabled={!disabled}
                    style={{
                      border: toggle ? "1px solid gray" : "none",
                      backgroundColor: "white",
                      paddingLeft: "0px",
                    }}
                  />
                </form>
              </div>
              <form action="">
                <label className="label-all-pro" htmlFor="">
                  Email ID*
                </label>{" "}
                <br />
                <input
                  className="input-for-m p-1"
                  type="text"
                  placeholder="Email Here"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={!disabled}
                  style={{
                    border: toggle ? "1px solid gray" : "none",
                    backgroundColor: "white",
                    paddingLeft: "0px",
                  }}
                />{" "}
                <br />
                <label className="label-all-pro" htmlFor="">
                  Current Location City *
                </label>{" "}
                <br />
                <input
                  className="input-for-m p-1"
                  type="text"
                  placeholder="Current Location City "
                  defaultValue={current_location}
                  onChange={(e) => setCurrent_location(e.target.value)}
                  disabled={!disabled}
                  style={{
                    border: toggle ? "1px solid gray" : "none",
                    backgroundColor: "white",
                    paddingLeft: "0px",
                  }}
                />{" "}
                <br />
                <label className="label-all-pro" htmlFor="">
                  School/College *
                </label>{" "}
                <br />
                <select
                  className="input-for-m p-1"
                  name=""
                  id=""
                  defaultValue={b_school_name}
                  onChange={(e) => {
                    handleCollegeU(e);
                    setB_school_name(e.target.value);
                  }}
                  disabled={!disabled}
                  style={{
                    border: toggle ? "1px solid gray" : "none",
                    backgroundColor: "white",
                    appearance: toggle ? "auto" : "none",
                    paddingLeft: "0px",
                  }}
                >
                  {/* b_school_name */}
                  <option value="" disabled>
                    {findName(1)}
                  </option>
                  {collegeU.map((val, i) => {
                    return (
                      <option key={i} value={val.uid}>
                        {val.college_name}
                      </option>
                    );
                  })}
                </select>
              </form>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form action="">
                  <label className="label-all-pro al-pro-u" htmlFor="">
                    DOB *
                  </label>{" "}
                  <br />
                  <input
                    className="input-for-m p-1"
                    type="date"
                    defaultValue={dob}
                    onChange={(e) => setDob(e.target.value)}
                    disabled={!disabled}
                    style={{
                      border: toggle ? "1px solid gray" : "none",
                      backgroundColor: "white",
                      paddingLeft: "0px",
                    }}
                  />
                </form>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form action="">
                  <label className="label-all-pro  al-pro-u" htmlFor="">
                    Gender *
                  </label>
                  <select
                    className="input-for-m p-1"
                    name=""
                    id=""
                    defaultValue={gender}
                    onChange={(e) => setGender(e.target.value)}
                    disabled={!disabled}
                    style={{
                      border: toggle ? "1px solid gray" : "none",
                      backgroundColor: "white",
                      appearance: toggle ? "auto" : "none",
                      paddingLeft: "0px",
                    }}
                  >
                    <option selected disabled>
                      {gender}
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </form>
              </div>
            </div>

            <form action="">
              <label className="label-all-pro" htmlFor="">
                Phone*
              </label>
              <br />
              <input
                className="input-for-m p-1"
                type="text"
                placeholder="Number Here"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                disabled={!disabled}
                style={{
                  border: toggle ? "1px solid gray" : "none",
                  backgroundColor: "white",
                  paddingLeft: "0px",
                }}
              />{" "}
              <br />
            </form>

            <form action="" className="nbv">
              <label className="label-all-pro" htmlFor="">
                LinkedIn *
              </label>{" "}
              <br />
              <input
                className="input-for-m p-1"
                type="text"
                placeholder="LinkedIn "
                defaultValue={linkedIn_profile_link}
                onChange={(e) => setLinkedIn_profile_link(e.target.value)}
                disabled={!disabled}
                style={{
                  border: toggle ? "1px solid gray" : "none",
                  backgroundColor: "white",
                  paddingLeft: "0px",
                }}
              />{" "}
              <br />
            </form>

            <form action="">
              <label className="label-all-pro p-1" htmlFor="">
                MBA Specialization *
              </label>{" "}
              <br />
              <select
                className="input-for-m"
                // defaultValue={mba_specialization}
                onChange={(e) => {
                  setMba_specialization(e.target.value);
                }}
                disabled={!disabled}
                style={{
                  border: toggle ? "1px solid gray" : "none",
                  backgroundColor: "white",
                  appearance: toggle ? "auto" : "none",
                  paddingLeft: "0px",
                }}
              >
                MBA
                <option selected disabled>
                  {mba_specialization}
                </option>
                {courseU && courseU.length ? (
                  courseU.map((value, i) => {
                    return (
                      <option key={i} value={value.course_name}>
                        {value.course_name}
                      </option>
                    );
                  })
                ) : (
                  <div>not found</div>
                )}
              </select>
            </form>
          </div>
        </div>
        <div>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="med-gg"
              value={occupations}
              onChange={(e) => setOccupations(e.target.value)}
            >
              <FormLabel
                className="tick-mark-reg"
                id="demo-row-radio-buttons-group-label"
              >
                Occupational Status *
              </FormLabel>{" "}
              &nbsp; &nbsp;
              <FormControlLabel
                value="Campus-Student"
                control={<Radio />}
                label="Campus Student"
              />
              <FormControlLabel
                value="Professional"
                control={<Radio />}
                label="Professional"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div>
          {/* <FormControlLabel
            control={<Checkbox />}
            label="Agree our terms and policy"
            className="check-label"
          /> */}
          {/* <button className="btn-label btn-learn-b-m">Learn More</button> */}
        </div>
        <div className="update-c">
          <span
            className="Updates "
            style={{
              backgroundColor: togglee ? "#68174a" : "#ef4625",
            }}
            onClick={proceedToNext}
          >
            Update
          </span>
        </div>
        <span className="text-btn-button">
          Please press the update button to update the new data in this section
        </span>

        <button className="btn-my-pro-mj mbb-1" onClick={proceed}>
          Proceed To Next Step
        </button>
      </div>
    );
  };

  // get college and course

  // get college

  const [collegeU, setCollegeU] = useState([]);

  const [collegeUId, setCollegeUId] = useState("");

  const [courseU, setCourseU] = useState([]);

  const collegeUURL = `${baseUrlAPI}/api/colleges`;

  useEffect(() => {
    fetch(collegeUURL).then((result) => {
      result.json().then((res) => {
        setCollegeU(res.data);
      });
    });
  }, [collegeUURL]);

  const handleCollegeU = (event) => {
    const getCollegeUId = event.target.value;
    setCollegeUId(getCollegeUId);
  };

  // const courseURL =  collegeId ? "http://3.109.83.81/phc-admin/api/course?college_id=" + collegeId : "http://3.109.83.81/phc-admin/api/course?college_id=63aa7d3c3cab8";

  const courseUURL = collegeUId
    ? `${baseUrlAPI}/api/course?college_id=` + collegeUId
    : `${baseUrlAPI}/api/course?college_id=63cb863f0ef37`;

  // const courseURL =  "http://3.109.83.81/phc-admin/api/course?college_id=20";

  useEffect(() => {
    fetch(courseUURL).then((result) => {
      result.json().then((res) => {
        setCourseU(res);
      });
    });
  }, [courseUURL]);

  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");

  const Position = localStorage.getItem("position");

  const DownloadCV = async (e) => {
    e.preventDefault();
    // import { saveAs } from "file-saver";
    const candidate_id = localStorage.getItem("uid");

    const DownloadResume = { uid: candidate_id };

    const DownloadCVURL = `${baseUrlAPI}/api/pdf`;

    fetch(DownloadCVURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(DownloadResume),
    })
      .then((res) => res.blob())
      .then((data) => {
        saveAs(data, "cv.pdf");
      });
  };

  return (
    <>
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 className="dashboard-heading-d">My Profile</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="Profile-Overview">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
              <div className="flex-p-overview-my-pro">
                <p className="bol-pro" onClick={goToProfileOverview}>
                  <ion-icon name="search-circle-outline"></ion-icon> Profile
                  Overview
                </p>
                <p className="bol-pro" onClick={goToAppliedJobs}>
                  <ion-icon name="business-outline"></ion-icon> Applied Jobs
                </p>
                <p className="bol-pro" onClick={goToSavedJobs}>
                  <ion-icon name="bookmark-outline"></ion-icon> Saved Jobs
                </p>
                <p className="pro-over-col bol-pro">
                  <ion-icon name="person-circle-outline"></ion-icon> My Profile
                </p>

                <p className="bol-pro" onClick={DownloadCV}>
                  <ion-icon name="document-outline"></ion-icon> Generate CV
                </p>
                <p className="bol-pro" onClick={goToChangePassword}>
                  <ion-icon name="lock-closed-outline"></ion-icon> Change
                  Password
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Deactivate
                  Account
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Log Out
                </p>
              </div>
            </div>

            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
              <div className="pad-mar-my-proo">
                <div className="my-pro-pad-mar">
                  {/* <img
                    className="g-i-avtar"
                    src="https://media.istockphoto.com/id/1288732130/photo/portrait-of-cheerful-mixed-race-teenage-girl.jpg?b=1&s=170667a&w=0&k=20&c=R3BwP5rv0nG441whzRmAaLXCs9OZMdZHKOfUw0oj4sQ="
                    alt="girl"
                  /> */}
                  <div>
                    <h3>
                      {first_name ? first_name : firstName}{" "}
                      {last_name ? last_name : lastName}
                    </h3>
                    {/* <h4>{firstName} {lastName}</h4> */}
                    <p>{Position} &nbsp; &nbsp; </p>
                  </div>
                </div>

                <div className="box-pad-mar-k">
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={0} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </div>
                {renderBasicInfo()}
                {/* <div className="my-pro-inn-pro">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                <form className='for-m-my-pr' action="">
                                                    <label className='label-all-pro' htmlFor="">First Name *</label>
                                                    <input className='input-for-m' type="text" placeholder='Enter Your Name' value={first_name} onChange={(e)=>setFirst_name(e.target.value)}/>
                                                </form>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                <form action="">
                                                    <label className='label-all-pro' htmlFor="">Last Name *</label>
                                                    <input className='input-for-m' type="text" placeholder='Enter Your Name' value={last_name} onChange={(e)=>setLast_name(e.target.value)}/>
                                                </form>
                                            </div>
                                            <form action="">
                                               

                                                <label className='label-all-pro' htmlFor="">Email ID*</label> <br />
                                                <input className='input-for-m' type="text" placeholder='Email Here' value={email} onChange={(e)=>setEmail(e.target.value)}/> <br />

                                                <label className='label-all-pro' htmlFor="">School/College *</label> <br />
                                                <option >Select College</option>
                                                <select className='input-for-m' name="" id="" value={b_school_name} onChange={(e)=>setB_school_name(e.target.value)}>
                                                    
                                                    <option value="M.Tech Specialization">M.Tech Specialization</option>
                                                    <option value="B.Tech Specialization">B.Tech Specialization</option>
                                                </select>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <form action="">
                                            <label className='label-all-pro' htmlFor="">Phone*</label> <br />
                                            <input className='input-for-m' type="text" placeholder='Number Here' value={mobile} onChange={(e)=>setMobile(e.target.value)}/> <br />

                                           
                                        </form>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                <form action="">
                                                    <label className='label-all-pro' htmlFor="">DOB *</label> <br />
                                                    <input className='input-for-m' type="date" value={dob} onChange={(e)=>setDob(e.target.value)}/>
                                                </form>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                <form action="">
                                                    <label className='label-all-pro' htmlFor="">Gender *</label>
                                                    <select className='input-for-m' name="" id="" value={gender} onChange={(e)=>setGender(e.target.value)}>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </form>
                                            </div>
                                        </div>

                                        <form action="">
                                            <label className='label-all-pro' htmlFor="">MBA Specialization *</label> <br />
                                            <option value="">Select Course</option>
                                            <select className='input-for-m' name="" id="" value={mba_specialization} onChange={(e)=>setMba_specialization(e.target.value)}>
                                                
                                                <option value="Specialization">Specialization *</option>
                                                <option value="MTech Specialization">MTech Specialization *</option>
                                            </select>
                                        </form>
                                    </div>
                                </div>

                               

                                <div className="ml-fp-r">
                                    <FormControlLabel control={<Checkbox  />} label="Agree our terms and policy" className='check-label'/>
                                    <button className='btn-label' >Learn More</button>
                                </div>

                                <button className='btn-my-pro-mj' onClick={goToProceedToNextStep}>Proceed To Next Step</button>

                                
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
