import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import "../css/MyProfile.css";
import axios from "axios";
import { Alert, AlertTitle } from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useNavigate } from "react-router-dom";

import AddModal from "./AddModal";
import { saveAs } from "file-saver";

import baseUrlAPI from "./BaseURL";

const steps = [
  "Basic Info",
  "Education",
  "Work Experience",
  "Others",
  "Preferance",
];

const MyProfileEducation = () => {
  const [message, setMessage] = useState("");
  const [myedu, setMyedu] = useState(null);
  const [formValues, setFormValues] = useState([]);
  console.log(formValues);
  let handleChange = (i, e) => {
    let newFormValues = [...myedu];
    newFormValues[i][e.target.name] = e.target.value;
    // setFormValues(setMyedu);
    setMyedu(newFormValues);
    console.log(i, e);
  };
  console.log({ myedu });
  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        college_name: "",
        degree_name: "",
        initiation_year: "",
        passout_year: "",
        passing_score: "",
      },
    ]);
  };
  const deleteEdu = async (id) => {
    const url = `${baseUrlAPI}/api/deleteRecords?module=c_qualification&uid=${id}`;
    try {
      await axios.get(url);
      console.log("success");
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };
  const uid = localStorage.getItem("uid");
  useEffect(() => {
    if (uid) {
      navigate("/my-profile-education");
      window.scrollTo(5, 0);
    } else {
      navigate("/register");
      window.scrollTo(0, 0);
    }
  }, []);

  const navigate = useNavigate();

  const goToSavedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/saved-jobs");
  };

  const goToAppliedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/applied-job");
  };

  const goToProfileOverview = () => {
    window.scrollTo(0, 0);
    navigate("/dashboard");
  };

  const goToGenerateCV = () => {
    window.scrollTo(0, 0);
    navigate("/generate-cv");
  };

  const goToChangePassword = () => {
    window.scrollTo(0, 0);
    navigate("/change-password");
  };

  const handleBackClick = () => {
    navigate("/my-profile");
  };
  const fetchMyEdu = () => {
    const tokenid = localStorage.getItem("candidateToken");

    const candidate_id = localStorage.getItem("uid");

    const getQualificationID = { token: tokenid, candidate_id: candidate_id };

    const getQualificationURL = `${baseUrlAPI}/api/getQualification`;

    fetch(getQualificationURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(getQualificationID),
    })
      .then((result) => result.json())
      .then((data) => {
        console.log(data);
        localStorage.setItem("can_uid_get_edu", data[0].candidate_id);
        // if(!data.status){
        //   return
        // }
        setMyedu(data);
      })

      .catch((e) => {
        console.log("e", e);
      });

    console.log(getQualificationID);
  };

  useEffect(() => {
    fetchMyEdu();
  }, []);

  console.log(myedu);

  const firstName = localStorage.getItem("firstName");
  // console.log(firstName);
  const lastName = localStorage.getItem("lastName");
  // console.log(lastName);

  const goToExperience = (e) => {
    e.preventDefault();

    navigate("/my-profile-experience");
  };

  const [disabled, setDisabled] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [toggleGoBack, setoggleGoBack] = useState(false);

  const EditCandidateFormGoBack = () => {
    setDisabled(!disabled);
    setoggleGoBack(!toggleGoBack);
  };

  const EditCandidateForm = () => {
    setDisabled(!disabled);
    setToggle(!toggle);
  };

  const [toggleeDelete, settoggleeDelete] = useState(false);
  const deleteButtonForms = () => {
    setDisabledd(!disabledd);
    settoggleeDelete(!toggleeDelete);
    // setMessage("Record has been updated!");
  };

  const [disabledd, setDisabledd] = useState(false);
  const [togglee, setTogglee] = useState(false);
  const UpdateCandidateForm = () => {
    setDisabledd(!disabledd);
    setTogglee(!togglee);
    // setMessage("Record has been updated!");
  };

  // update start
  const handleUpdate = (i) => {
    const token = localStorage.getItem("candidateToken");
    const candidate_id = localStorage.getItem("uid");

    const updateObj = myedu[i];
    updateObj.token = token;
    updateObj.candidate_id = candidate_id;

    console.log(updateObj);

    const updateEducationURL = `${baseUrlAPI}/api/updateQualification`;
    fetch(updateEducationURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(updateObj),
    })
      .then((result) => result.json())
      .then((data) => {
        console.log("result", data);
        if (data.status === 200) {
          alert("success");
        }
        setMessage("Record has been updated!");
      })

      .catch((e) => {
        console.log("e", e);
      });
  };
  // update end
  const renderEducationInfo = () => {
    const renderLabel = (i) => {
      switch (true) {
        case i === 0:
          return <label htmlFor="college_name">MBA</label>;
        case i === 1:
          return <label htmlFor="college_name">Graduation</label>;
        case i === 2:
          return <label htmlFor="college_name">XII</label>;
        case i === 3:
          return <label htmlFor="college_name">X</label>;
      }
    };

    return (
      <div className="r-form-first">
        <div className="edi-form">
          <span
            className="edit_form"
            style={{ backgroundColor: toggleGoBack ? "#68174a" : "#ef4625" }}
            onClick={handleBackClick}
            onChange={EditCandidateFormGoBack}
          >
            Go Back
          </span>
          <span
            className="edit_form"
            style={{ backgroundColor: toggle ? "#68174a" : "#ef4625" }}
            onClick={EditCandidateForm}
          >
            Edit Form
          </span>
          <AddModal type={1} />
          {/* <span className="edit_formm" onClick={() => addFormFields()}>
            Add More
          </span> */}
        </div>
        <div className="container">
          {message && (
            <Alert
              onClose={() => {
                setMessage("");
              }}
              severity="success"
            >
              {message}
            </Alert>
          )}
          <form action="">
            <div className="flex-h-ad-m">
              <h4 className="post-gr-col-font">Education Details</h4>
              {/* <h4 className='add-m-ore-gold' onClick={() => addFormFields()}>Add More</h4> */}
            </div>

            {!myedu ? (
              <div>No data found</div>
            ) : (
              myedu.map((element, index) => (
                <div name="input">
                  <div className="reg2">
                    <div className="school">
                      {renderLabel(index)}
                      {/* <label htmlFor="college_name">School/College *</label> <br /> */}
                      <input
                        type="text"
                        name="college_name"
                        id="college_name"
                        defaultValue={element.college_name}
                        onChange={(e) => {
                          // handleCollegeU(e);
                          handleChange(index, e);
                        }}
                        disabled={!disabled}
                        // style={{backgroundColor: toggle ? "unset" : "#fde8eb"}}
                        style={{
                          border: toggle ? "1px solid gray" : "none",
                          backgroundColor: "white",
                        }}
                      />
                    </div>
                    <div className="pro">
                      <label htmlFor="">Degree Name *</label> <br />
                      <input
                        type="text"
                        name="degree_name"
                        id=""
                        defaultValue={element.degree_name}
                        onChange={(e) => handleChange(index, e)}
                        disabled={!disabled}
                        style={{
                          border: toggle ? "1px solid gray" : "none",
                          backgroundColor: "white",
                        }}
                      />
                    </div>
                  </div>

                  <div className="reg3">
                    <div className="pass-out-year-h">
                      <label htmlFor="">Pass In Year *</label> <br />
                      <input
                        type="text"
                        name="initiation_year"
                        defaultValue={element.initiation_year}
                        onChange={(e) => handleChange(index, e)}
                        disabled={!disabled}
                        style={{
                          border: toggle ? "1px solid gray" : "none",
                          backgroundColor: "white",
                        }}
                      />
                    </div>

                    <div className="pass-out-year-h-mm">
                      <label htmlFor="">Pass Out Year *</label> <br />
                      <input
                        type="text"
                        name="passout_year"
                        defaultValue={element.passout_year}
                        onChange={(e) => handleChange(index, e)}
                        disabled={!disabled}
                        style={{
                          border: toggle ? "1px solid gray" : "none",
                          backgroundColor: "white",
                        }}
                      />
                    </div>
                  </div>

                  <div className="reg3">
                    <div className="pass-out-year-h">
                      <label htmlFor="">Percentage *</label> <br />
                      <input
                        type="text"
                        className="gr-fl-bt"
                        placeholder="Grade"
                        name="passing_score"
                        defaultValue={element.passing_score}
                        onChange={(e) => handleChange(index, e)}
                        disabled={!disabled}
                        style={{
                          border: toggle ? "1px solid gray" : "none",
                          backgroundColor: "white",
                        }}
                      />
                    </div>
                  </div>
                  <div className="note-res">
                    <h5>
                      Note : If your score was not in percentage then please
                      convert it to percentage.
                    </h5>
                  </div>

                  <div className="update-c">
                    <div className="update-c-pra">
                      <span
                        className="Updates"
                        style={{
                          backgroundColor: togglee ? "#68174a" : "#ef4625",
                        }}
                        onClick={() => {
                          UpdateCandidateForm();
                          handleUpdate(index);
                        }}
                      >
                        Update
                      </span>
                      <span className="text-btn-button">
                        Please press the update button to update the new data in
                        this section
                      </span>
                    </div>
                    <span
                      className="Updates"
                      style={{
                        backgroundColor: toggleeDelete ? "#68174a" : "#ef4625",
                      }}
                      onClick={() => {
                        deleteEdu(element.uid);
                      }}
                      onChange={deleteButtonForms}
                    >
                      <DeleteIcon />
                    </span>
                  </div>

                  <div className="token-education">
                    <input type="hidden" placeholder="Token" />
                  </div>
                  <hr />
                </div>
              ))
            )}
          </form>
          <div className="ml-fp-r">
            {/* <FormControlLabel control={<Checkbox  />} label="Agree our terms and policy" className='check-label'/> */}
            {/* <button className='btn-label' >Back</button> */}
          </div>
          <div className="r-btn-next">
            <button onClick={goToExperience}>Proceed To Next Step</button>
          </div>
          {/* <div className="reg1-sign">
                      <p>
                        Already have an account? <span>Sign In Now.</span>
                      </p>
                    </div> */}
        </div>
      </div>
    );
  };

  // const Position = localStorage.getItem("position");

  const DownloadCV = async (e) => {
    e.preventDefault();
    // import { saveAs } from "file-saver";
    const candidate_id = localStorage.getItem("uid");

    console.log(candidate_id);
    const DownloadResume = { uid: candidate_id };
    console.log(DownloadResume);

    const DownloadCVURL = `${baseUrlAPI}/api/pdf`;

    fetch(DownloadCVURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(DownloadResume),
    })
      .then((res) => res.blob())
      .then((data) => {
        saveAs(data, "cv.pdf");
      });
  };

  return (
    <>
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 className="dashboard-heading-d">My Profile</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="Profile-Overview">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
              <div className="flex-p-overview-my-pro">
                <p className="bol-pro" onClick={goToProfileOverview}>
                  <ion-icon name="search-circle-outline"></ion-icon> Profile
                  Overview
                </p>
                <p className="bol-pro" onClick={goToAppliedJobs}>
                  <ion-icon name="business-outline"></ion-icon> Applied Jobs
                </p>
                <p className="bol-pro" onClick={goToSavedJobs}>
                  <ion-icon name="bookmark-outline"></ion-icon> Saved Jobs
                </p>
                <p className="pro-over-col bol-pro">
                  <ion-icon name="person-circle-outline"></ion-icon> My Profile
                </p>
                {/* <p className='bol-pro' onClick={goToMessages}><ion-icon name="chatbox-ellipses-outline"></ion-icon> Messages</p> */}
                <p className="bol-pro" onClick={DownloadCV}>
                  <ion-icon name="document-outline"></ion-icon> Generate CV
                </p>
                <p className="bol-pro" onClick={goToChangePassword}>
                  <ion-icon name="lock-closed-outline"></ion-icon> Change
                  Password
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Deactivate
                  Account
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Log Out
                </p>
              </div>
            </div>

            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
              <div className="pad-mar-my-proo">
                <div className="my-pro-pad-mar">
                  {/* <img
                    className="g-i-avtar"
                    src="https://media.istockphoto.com/id/1288732130/photo/portrait-of-cheerful-mixed-race-teenage-girl.jpg?b=1&s=170667a&w=0&k=20&c=R3BwP5rv0nG441whzRmAaLXCs9OZMdZHKOfUw0oj4sQ="
                    alt="girl"
                  /> */}
                  <div>
                    <h3>
                      {firstName} {lastName}
                    </h3>
                    {/* <h4>{firstName} {lastName}</h4> */}
                    <p>
                      {/* { Position} &nbsp; &nbsp;{" "} */}
                      {/* <ion-icon name="create-outline"></ion-icon> */}
                    </p>
                  </div>
                </div>

                <div className="box-pad-mar-k">
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={1} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </div>
                {renderEducationInfo()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfileEducation;
