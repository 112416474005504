import React, { useEffect, useState } from "react";
import "../css/Job.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import baseUrlAPI from "./BaseURL";
import JobCard from "./Job_card";
import axios from "axios";

const Job = () => {
  const [job, setJob] = useState([]);
  // console.log(job, "aaaa");
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(1);
  const [locations, setLocations] = useState([]);
  const [domainNames, setDomainNames] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [filtersData, setFiltersData] = useState([]);
  console.log(filtersData);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [sorting, setSorting] = useState("");

  const salaryRange = [[15, 25], [26, 35], [36, 45], [45]];
  const experienceLevel = [[0, 2], [3, 5], [6, 8], [9, 10], [10]];

  const jobURL = `${baseUrlAPI}/api/jobs?page=${page}`;
  const filtersType = [
    "location",
    "domain",
    "industry",
    "jobType",
    "ctc",
    "experience",
  ];
  //=========== Job sorting start ==========
  useEffect(() => {
    if (sorting === "SAL1") {
      setJob([...job].sort((a, b) => a.job_salary - b.job_salary));
    } else if (sorting === "SAL0") {
      setJob([...job].sort((a, b) => b.job_salary - a.job_salary));
    } else if (sorting === "EXP0") {
      setJob([...job].sort((a, b) => b.req_experience - a.req_experience));
    } else if (sorting === "EXP1") {
      setJob([...job].sort((a, b) => a.req_experience - b.req_experience));
    }
  }, [sorting]);
  //=========== Job sorting end ==========
  //=========== Job reset start ==========
  // const resetFilters = async () => {
  //   try {
  //     const { data } = await axios.get(jobURL);
  //     setJob(data.data);
  //   } catch (e) {}
  // };
  console.log(filtersData);
  const resetFilters = () => {
    setFiltersData([]);
  };
  //=========== Job reset end ==========

  const createFilterObj = (obj) => {
    const isExist = filtersData?.find(
      (object) => Object.keys(obj)[0] in object
    );
    if (isExist) {
      const restData = filtersData.filter(
        (f) => Object.keys(f)[0] !== Object.keys(isExist)[0]
      );
      setFiltersData([...restData, obj]);
    }
    if (!isExist) {
      setFiltersData([...filtersData, obj]);
    }
  };

  // get all jobs from db
  useEffect(() => {
    fetch(jobURL).then((result) => {
      result.json().then((res) => {
        setJob(res.data);
      });
    });
  }, [jobURL]);

  // get all jobs for db and it is calling for filtering data
  const getJobs = () => {
    axios(jobURL).then((res) => {
      setJobs(res.data.data);
    });
  };

  // get all locations from db
  useEffect(() => {
    fetch(`http://15.206.6.75/phc-admin/api/getlocations`)
      .then((res) => res.json())
      .then((data) => setLocations(data?.locations));
  }, []);

  // get all domains name from db
  useEffect(() => {
    fetch(`http://15.206.6.75/phc-admin/api/getRole`)
      .then((res) => res.json())
      .then((data) => setDomainNames(data?.data));
  }, []);
  //get all industry name from db
  useEffect(() => {
    fetch(`http://15.206.6.75/phc-admin/api/industry`)
      .then((res) => res.json())
      .then((data) => setIndustries(data?.data));
  }, []);
  //get all industry name from db
  useEffect(() => {
    fetch(`http://15.206.6.75/phc-admin/api/industry`)
      .then((res) => res.json())
      .then((data) => setIndustries(data?.data));
  }, []);
  //get all job type name from db
  useEffect(() => {
    fetch(`http://15.206.6.75/phc-admin/api/employmentType`)
      .then((res) => res.json())
      .then((data) => setJobTypeOptions(data?.data));
  }, []);

  // any filtering then this will be call for new flirting data
  useEffect(() => {
    getJobs();
    allOfFiltering();
  }, [filtersData]);

  // all filterings functions
  const allOfFiltering = () => {
    const existFilters = [];
    filtersType.forEach((type) => {
      const filterType = filtersData?.find((object) => type in object);
      if (filterType) {
        existFilters.push(filterType);
      }
    });

    if (existFilters.length > 0) {
      const allData = jobs;
      let jobsData = [];
      if (filtersData.length === 1) {
        jobsData = allData;
      }
      const location = existFilters?.find((object) => "location" in object);
      const domain = existFilters?.find((object) => "domain" in object);
      const industry = existFilters?.find((object) => "industry" in object);
      const jobType = existFilters?.find((object) => "jobType" in object);
      const ctc = existFilters?.find((object) => "ctc" in object);
      const experience = existFilters?.find((object) => "experience" in object);

      // according location filtering
      if (location?.location) {
        if (jobsData.length === 0) {
          jobsData = allData;
        }
        const filters = jobsData?.filter(
          (jb) =>
            jb?.job_location.toLowerCase() === location.location.toLowerCase()
        );
        jobsData = filters;
      }

      // according domain name flirting
      if (domain?.domain) {
        const filters = jobsData?.filter(
          (jb) => jb?.role.toLowerCase() === domain.domain.toLowerCase()
        );

        jobsData = filters;
      }
      // according industry filtering
      if (industry?.industry) {
        const filters = jobsData?.filter(
          (jb) => jb?.industry.toLowerCase() === industry.industry.toLowerCase()
        );

        jobsData = filters;
      }

      // according ctc filtering
      if (ctc?.ctc) {
        const ctcRange = ctc.ctc.split(",");

        if (ctcRange.length > 1) {
          const filters = jobsData.filter(
            (jb) =>
              +jb?.job_salary >= +ctcRange[0] && +jb?.job_salary <= +ctcRange[1]
          );

          jobsData = filters;
        }
        if (ctcRange.length === 1) {
          const filters = jobsData.filter(
            (jb) => +jb?.job_salary >= +ctcRange[0]
          );

          jobsData = filters;
        }
      }

      // according experience filtering
      if (experience?.experience) {
        const experienceRange = experience?.experience.split(",");
        if (experienceRange.length > 1) {
          const filters = jobsData.filter(
            (jb) =>
              +jb?.req_experience >= +experienceRange[0] &&
              +jb?.req_experience <= +experienceRange[1]
          );

          jobsData = filters;
        }
        if (experienceRange.length === 1) {
          const filters = jobsData.filter(
            (jb) => +jb?.req_experience >= +experienceRange[0]
          );

          jobsData = filters;
        }
      }

      // according job types filtering
      if (jobType?.jobType) {
        const filters = jobsData.filter(
          (jb) =>
            jb?.employment_type.toLowerCase() ===
            jobType?.jobType.trim().toLowerCase()
        );
        jobsData = filters;
      }
      setJob(jobsData);
    } else {
      setJob(jobs);
    }
  };

  return (
    <div className="col-back-col ">
      <div className="Available-Now">
        <div className="an-flex">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h2 className="clr">
                  {job && job.length !== 0 ? job.length : "0"} Jobs Available
                  Now
                </h2>

                <div className="ali-job-cen">
                  <form className="search-bar-hh" action="">
                    <AllInboxIcon className="btn-serch-icn" />
                    <input
                      className="no-outline"
                      type="text"
                      placeholder="Keyword"
                      style={{ borderRight: "2px solid rgba(0,0,0,.1)" }}
                    />
                    <hr />
                    <LocationOnOutlinedIcon className="btn-serch-icn" />
                    <input
                      className="no-outline"
                      type="text"
                      placeholder="Location"
                      style={{ borderRight: "2px solid rgba(0,0,0,.1)" }}
                    />
                    <hr />
                    <ManageSearchIcon className="btn-serch-icn" />
                    <select
                      name=""
                      id=""
                      className="no-outline"
                      style={{ borderRight: "2px solid rgba(0,0,0,.1)" }}
                    >
                      <option selected disabled>
                        Select MBA domain
                      </option>
                      <option value="kiso nai">kiso nai</option>
                    </select>
                    <Button
                      className="btn-ser-btn"
                      variant="contained"
                      // startIcon={<SearchIcon />}
                      onClick={() => {
                        resetFilters();
                      }}
                    >
                      Reset
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Advance-Filter globalPaddingg">
        <div className="container skw">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 marginSmalll">
              <div className="flex-ad-fil">
                <h4>Advance Filter</h4>
                <button
                  className="reset-btn"
                  onClick={() => {
                    resetFilters();
                  }}
                >
                  Reset
                </button>
              </div>
              <hr />
              <h5 className="loc-loc">Location</h5>

              {/* ======================================== */}
              <select
                // defaultValue={filtersData?.find((object) =>
                //   "location" in object !== undefined ? object["location"] : ""
                // )}
                onChange={(e) => createFilterObj({ location: e.target.value })}
                className="jb-ser-loca"
                id="industry-fetc"
                style={{
                  width: "100%",
                  background: "none",
                  color: "black",
                }}
              >
                <option
                  selected={
                    !filtersData?.find((object) => "location" in object)
                  }
                  disabled
                >
                  Select Location
                </option>
                {locations?.map((location, i) => (
                  <option key={i} value={location}>
                    {location}
                  </option>
                ))}
              </select>

              {/* ======================================== */}

              {/* </div>{" "} */}
              <br />
              <div className="Industry">
                <h5>MBA Domain</h5>

                <select
                  onChange={(e) => createFilterObj({ domain: e.target.value })}
                  name="college_nam"
                  style={{ color: "black" }}
                  id="industry-fetc"
                >
                  <option
                    selected={
                      !filtersData?.find((object) => "domain" in object)
                    }
                    disabled
                  >
                    Select MBA domain
                  </option>
                  {domainNames?.map((name, i) => (
                    <option key={i} value={name?.role}>
                      {name?.role}
                    </option>
                  ))}
                </select>

                <h5 className="industry-opt">Industry</h5>

                <select
                  onChange={(e) =>
                    createFilterObj({ industry: e.target.value })
                  }
                  name=""
                  id="industry-fetc"
                  style={{ color: "black" }}
                >
                  <option
                    selected={
                      !filtersData?.find((object) => "industry" in object)
                    }
                    disabled
                  >
                    Select Industry
                  </option>
                  {industries?.map((name, i) => (
                    <option key={i} value={name[0]}>
                      {name[0]}
                    </option>
                  ))}
                </select>

                <div className="ind-flex">
                  <div className="job-checkbox"></div>
                </div>
              </div>
              <hr />
              <div className="Salary-Range">
                <h5 className="exp-exp">CTC (in Lakhs) </h5>
              </div>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  {salaryRange.map((value, i) => {
                    return (
                      <FormControlLabel
                        onChange={(e) =>
                          createFilterObj({ ctc: e.target.value })
                        }
                        color="red"
                        key={i}
                        value={value + " "}
                        // value=""
                        // selected={
                        //   filtersData?.find((object) => "ctc" in object)?.ctc
                        // }
                        selected={
                          filtersData?.find((object) => "ctc" in object)?.ctc
                            ? true
                            : false
                        }
                        // checked={
                        //   filtersData?.find(
                        //     (object) => `${Object.keys(object)}` in object
                        //   )?.ctc === value
                        //     ? true
                        //     : false
                        // }
                        control={<Radio />}
                        label={`${
                          !value[1]
                            ? `More than 45 Lakhs`
                            : `${value[0]} to ${value[1]}`
                        }`}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>

              <hr />

              <h5 className="exp-exp">Experience Level </h5>
              <div className="job-checkbox">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    // onChange={(e) => {
                    //   SetExperienceJob(e.target.value);
                    // }}
                  >
                    {experienceLevel.map((value, i) => {
                      return (
                        <FormControlLabel
                          onChange={(e) =>
                            createFilterObj({ experience: e.target.value })
                          }
                          color="red"
                          key={i}
                          value={value + " "}
                          control={<Radio />}
                          label={`${
                            !value[1]
                              ? `More than 10 years`
                              : `${value[0]} to ${value[1]}`
                          }`}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </div>

              <hr />
              <div
                className="job-checkbox"
                // onChange={(e) => {
                //   fetchEmploymentType(e.target.value.trim());
                // }}
              >
                <FormControl>
                  <h5 className="exp-exp">Job Type</h5>
                  <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    {jobTypeOptions.map((value, i) => {
                      return (
                        <FormControlLabel
                          onChange={(e) =>
                            createFilterObj({ jobType: e.target.value })
                          }
                          key={i}
                          color="red"
                          value={value + " "}
                          control={<Radio />}
                          label={value}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-9 col-md-6 col-sm-6 col-xs-6 marginSmall tpmrgn">
              <div className="det-flex-job">
                <div className="fle-short flexSort">
                  <select
                    onChange={(e) => {
                      setSorting(e.target.value);
                    }}
                    style={{ color: "black", outline: "none" }}
                  >
                    <option value="Newest Post">Sort Job Results</option>
                    <option value="SAL1">Salary Low To High</option>
                    <option value="SAL0"> Salary High To Low</option>
                    <option value="EXP0">Experience High To Low</option>
                    <option value="EXP1">Experience Low To High</option>
                  </select>
                </div>
              </div>
              <hr />

              <div
                className="day-cards"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  padding: "50px 10px",
                }}
              >
                {job.length === 0 ? (
                  <div>No jobs found</div>
                ) : (
                  job?.map((jb, i) => <JobCard key={i} job={jb} />)
                )}
              </div>

              <div
                style={{ width: `${page === 1 ? "10%" : ""}` }}
                className="pagination-stack-job-search pagination"
              >
                {page === 1 ? null : (
                  <button
                    onClick={() => {
                      const temp = page - 1;
                      setPage(temp);
                    }}
                    style={{ backgroundColor: "red" }}
                    className="crl"
                  >
                    <ArrowBackIosIcon />
                  </button>
                )}
                <span>
                  {/* Page */}
                  <b> {page} </b>
                </span>
                <button
                  onClick={() => {
                    const temp = page + 1;
                    setPage(temp);
                  }}
                  style={{ backgroundColor: "red" }}
                  className="crl"
                >
                  <ArrowForwardIosIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "200px",
          background: "#FBCCCC",
          marginTop: "-140px",
        }}
      ></div>
    </div>
  );
};

export default Job;
