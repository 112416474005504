import React from "react";
import logo from "../images/phc-logo-light.png";
// import logo from "../images/PHC_Logo-04.png";
import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "../css/Footer.css";

// import About_Us from './About_Us';
import { useNavigate } from "react-router-dom";

const Footer = () => {
  let navigate = useNavigate();
  function goToAbout() {
    window.scrollTo(0, 0);
    navigate("/about-us");
  }

  function goToContact() {
    window.scrollTo(0, 0);
    navigate("/contact-us");
  }

  function goToPrivacy() {
    window.scrollTo(0, 0);
    navigate("/privacy-policy");
  }

  function goToTerms() {
    window.scrollTo(0, 0);
    navigate("/terms");
  }

  const goToDashboard = () => {
    window.scrollTo(0, 0);
    navigate("/dashboard");
  };

  // const goToFAQ = () =>{
  //     navigate ('/faqs')
  // }

  return (
    <footer>
      <div className="footer-f ">
        <div className="footer">
          <div className="logo-footer">
            <div className="f-logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="para">
              <span className="paraspan">
                Premium Hiring Company offers job opportunities to top business
                school graduates in India. We provide customized service to
                employers from India’s leading organisations and help them to
                find the right candidate meeting their needs and expectations.{" "}
                <br /> <br />
                We strive to ensure that our candidates have the best career
                opportunities available.
              </span>
            </div>
          </div>
          <div className="Quick_Links">
            <h5>Resources</h5>
            <div>
              {/* <li onClick={goToAbout}>About us</li> */}
              {/* <li>Our Team</li>
                    <li>Products</li> */}
              {/* <li onClick={goToContact}>Contact</li> */}
              <li onClick={goToDashboard}>Dashboard</li>
            </div>
          </div>
          {/* <div className="Community">
                <h5>Community</h5>
                <div>
                    <li>Feature</li>
                    <li>Pricing</li>
                    <li>Credits</li>
                    <li>FAQ</li>
                </div>
            </div> */}
          <div className="Quick_Links">
            <h5>Quick Links</h5>
            <div>
              <li onClick={goToPrivacy}>Privacy Policy</li>
              {/* <li>Help</li> */}
              {/* <li onClick={goToTerms}>Terms</li> */}
              {/* <li onClick={goToFAQ}>FAQ</li> */}
            </div>
          </div>
          <div className="Social">
            <h5>Social</h5>
            <div className="mobile-flex">
              <a
                href="https://www.facebook.com/premiumhiring"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon className="icon icon-f" />
              </a>

              <a
                href="https://www.linkedin.com/company/premium-hiring-company-in/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon className="icon icon-t" />
              </a>

              <a
                href="https://instagram.com/premiumhiringcompany?igshid=MzRlODBiNWFlZA=="
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon className="icon icon-i" />
              </a>

              {/* <a
              href="https://in.pinterest.com/login/"
              target="_blank"
              rel="noreferrer"
            >
              <CameraAltIcon className="icon icon-c" />
            </a> */}
              <a href="https://youtube.com" target="_blank" rel="noreferrer">
                <YouTubeIcon className="icon icon-c" />
              </a>
            </div>
          </div>
        </div>
        <hr className="hr-f" />
        <div className="bottom-f">
          <span>Copyright © 2023. All rights reserved. Managed & Developed by TechnoGripper Solutions Private Limited</span>
          <span>
            <ul></ul>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
