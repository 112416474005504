import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import "../css/MyProfile.css";
import { Alert, AlertTitle } from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useNavigate } from "react-router-dom";

import { saveAs } from "file-saver";

// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import Button from '@mui/material/Button';

// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import { useNavigate } from "react-router-dom";

import baseUrlAPI from "./BaseURL";
import { element } from "prop-types";
import axios from "axios";
import AddModal from "./AddModal";
// import { element } from "prop-types";

const steps = [
  "Basic Info",
  "Education",
  "Work Experience",
  "Others",
  "Preferance",
];

const MyProfileOthers = () => {
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  // const uid = localStorage.getItem("uid");
  // useEffect(() => {
  //   if (uid) {
  //     navigate("/my-profile-others");
  //     window.scrollTo(0, 0);
  //   } else {
  //     navigate("/register");
  //     window.scrollTo(0, 0);
  //   }
  // }, []);

  const goToSavedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/saved-jobs");
  };

  const goToAppliedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/applied-job");
  };

  const goToProfileOverview = () => {
    window.scrollTo(0, 0);
    navigate("/dashboard");
  };

  const goToGenerateCV = () => {
    window.scrollTo(0, 0);
    navigate("/generate-cv");
  };

  const goToChangePassword = () => {
    window.scrollTo(0, 0);
    navigate("/change-password");
  };

  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");

  // get Intern Start
  const tokenid = localStorage.getItem("candidateToken");
  const candidate_id = localStorage.getItem("uid");
  const title = localStorage.getItem("title");
  const uid = localStorage.getItem("can-uid-L");

  const GetInternship = {
    token: tokenid,
    candidate_id: candidate_id,
    title: title,
    uid: uid,
  };

  const getInternshipURL = `${baseUrlAPI}/api/getInternship`;
  const [getIntern, setGetIntern] = useState([
    { title: "", starting_year: "", completion_year: "", description: "" },
  ]);

  let handleChange = (i, e, t) => {
    if (t === "int") {
      let newFormValues = [...getIntern];
      newFormValues[i][e.target.name] = e.target.value;
      setGetIntern(newFormValues);
    }
  };

  let addFormFields = () => {
    setGetIntern([...getIntern, { title: "", i_year: "", description: "" }]);
  };
  const getInternships = async () => {
    const { data } = await axios.post(getInternshipURL, {
      token: tokenid,
      candidate_id: candidate_id,
    });
    setGetIntern(data);
    console.log({ data });
  };
  useEffect(() => {
    getInternships();
  }, []);
  const handleDelete = async (type, id) => {
    const url = `${baseUrlAPI}/api/deleteRecords?module=${type}&uid=${id}`;
    try {
      await axios.get(url);
      console.log("success");
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };
  const GetExtraCurricular = { token: tokenid, candidate_id: candidate_id };

  const getExtraCurricularURL = `${baseUrlAPI}/api/getCurricular`;
  const [getExtraInt, setGetExtraInt] = useState([
    { title: "", year: "", description: "" },
  ]);

  let handleChangeCurricular = (i, e) => {
    let newCurricularValues = [...getExtraInt];

    newCurricularValues[i][e.target.name] = e.target.value;
    setGetExtraInt(newCurricularValues);
  };

  let addFormFieldsCurricular = () => {
    setGetExtraInt([
      ...getExtraInt,
      { title_c: "", year: "", description_c: "" },
    ]);
  };

  useEffect(() => {
    fetch(getExtraCurricularURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(GetExtraCurricular),
    })
      .then((resultGETExtra) => resultGETExtra.json())
      .then((dataGetExtra) => {
        setGetExtraInt(dataGetExtra);
        localStorage.setItem("can-uid-EX", dataGetExtra[0].uid);
      });
  }, []);

  // get Extra Curricular End

  // get Liecence Start

  const GetLicence = { token: tokenid, candidate_id: candidate_id, uid: uid };

  const getGetLicenceURL = `${baseUrlAPI}/api/getLicence`;
  const [getLiecence, setGetLiecence] = useState([
    { title: "", category: "", organization: "", year: "", description: "" },
  ]);

  useEffect(() => {
    fetch(getGetLicenceURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(GetLicence),
    })
      .then((resultGETExtra) => resultGETExtra.json())
      .then((dataGetExtra) => {
        setGetLiecence(dataGetExtra);
        localStorage.setItem("can-id-L", dataGetExtra[0].candidate_id);
        localStorage.setItem("can-uid-L", dataGetExtra[0].uid);
      });
  }, []);

  let handleChangeLicence = (i, e) => {
    let newLicenceValues = [...getLiecence];

    newLicenceValues[i][e.target.name] = e.target.value;
    setGetLiecence(newLicenceValues);
    // console.log(get)
  };

  let addFormFieldsLicence = () => {
    setGetLiecence([
      ...getLiecence,
      { title: "", category: "", organization: "", year: "", description: "" },
    ]);
  };

  const goToPreference = () => {
    // const [togglee, setTogglee] = useState(false);
    // setTogglee(!togglee);
    window.scrollTo(0, 0);
    navigate("/my-profile-preference");

    const uidL = localStorage.getItem("can-uid-L");

    const uidI = localStorage.getItem("can-uid-I");

    const uidEX = localStorage.getItem("can-uid-EX");

    const InternshipUpdate = {
      token: tokenid,
      candidate_id: candidate_id,
      uid: uidI,
    };

    InternshipUpdate.title = getIntern[0].title;
    InternshipUpdate.starting_year = getIntern[0].starting_year;
    InternshipUpdate.completion_year = getIntern[0].completion_year;
    InternshipUpdate.description = getIntern[0].description;

    const InternshipUpdateURL = `${baseUrlAPI}/api/updateInternship`;

    fetch(InternshipUpdateURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(InternshipUpdate),
    })
      .then((result) => result.json())
      .then((data) => {
        // setMessage("Record has been updated!");
      });
    // post intern Update end

    // post curricular start

    const CurricularUpdate = {
      token: tokenid,
      candidate_id: candidate_id,
      uid: uidEX,
    };

    CurricularUpdate.title = getExtraInt[0].title;
    CurricularUpdate.year = getExtraInt[0].year;
    CurricularUpdate.description = getExtraInt[0].description;

    const CurricularUpdateURL = `${baseUrlAPI}/api/updateCurricular`;

    fetch(CurricularUpdateURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(CurricularUpdate),
    })
      .then((result) => result.json())
      .then((data) => {});
    setMessage("Internship has been updated!");
    // post curricular end

    // post licence start
    const LicenceUpdate = {
      token: tokenid,
      candidate_id: candidate_id,
      uid: uidL,
    };

    LicenceUpdate.title = getLiecence[0].title;
    LicenceUpdate.category = getLiecence[0].category;
    LicenceUpdate.organization = getLiecence[0].organization;
    LicenceUpdate.year = getLiecence[0].year;
    LicenceUpdate.description = getLiecence[0].description;

    const LicenceUpdateURL = `${baseUrlAPI}/api/updateLicences`;

    fetch(LicenceUpdateURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(LicenceUpdate),
    })
      .then((result) => result.json())
      .then((data) => {});
  };

  const [disabled, setDisabled] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [togglee, setTogglee] = useState(false);
  const [disabledd, setDisabledd] = useState(false);

  const EditCandidateForm = () => {
    setDisabled(!disabled);
    setToggle(!toggle);
    setDisabledd(!disabledd);
    setTogglee(!togglee);
  };

  const [toggleGoBack,setoggleGoBack]=useState(false);

  const EditCandidateFormGoBack = () => {
    setDisabled(!disabled);
    setoggleGoBack(!toggleGoBack);
  };


  const [toggleUpdate1, setToggleUpdate] = useState(false);
  const [toggleUpdate2,setToggleUpdate2]=useState(false);
  const [toggleUpdate3,setToggleUpdate3]=useState(false);
  


  const UpdateCandidateForm = () => {
    setDisabled(!disabled);
    setToggleUpdate(!toggleUpdate1);
  
  };

  const UpdateCandidateForm2 = () => {
    setDisabled(!disabled);
    setToggleUpdate2(!toggleUpdate2);
  
  };

  const UpdateCandidateForm3 = () => {
    setDisabled(!disabled);
    setToggleUpdate3(!toggleUpdate3);
  
  };




  const [toggleDelete1, setToggleDelete1] = useState(false);
  const [toggleDelete2,setToggleDelete2]=useState(false);
  const [toggleDelete3,setToggleDelete3]=useState(false);
  


  const DeleteCandidateForm1 = () => {
    setDisabled(!disabled);
    setToggleDelete1(!toggleDelete1);
  
  };

  const DeleteCandidateForm2 = () => {
    setDisabled(!disabled);
    setToggleDelete2(!toggleDelete2);
  
  };

  const DeleteCandidateForm3 = () => {
    setDisabled(!disabled);
    setToggleDelete3(!toggleDelete3);
  
  };

  const Position = localStorage.getItem("position");

  const DownloadCV = async (e) => {
    e.preventDefault();
    // import { saveAs } from "file-saver";
    const candidate_id = localStorage.getItem("uid");

    const DownloadResume = { uid: candidate_id };

    const DownloadCVURL = `${baseUrlAPI}/api/pdf`;

    fetch(DownloadCVURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(DownloadResume),
    })
      .then((res) => res.blob())
      .then((data) => {
        saveAs(data, "cv.pdf");
      });
  };
  // handleChangeLicence
  const updateLicences = async (i) => {
    const candidateToken = localStorage.getItem("candidateToken");
    const id = localStorage.getItem("uid");
    const dataToUpdate = getLiecence[i];
    console.log({ dataToUpdate });
    dataToUpdate.token = candidateToken;
    const updateData = await axios.post(
      `${baseUrlAPI}/api/updateLicences`,
      dataToUpdate
    );
    console.log({ updateData });
    setMessage("License has been updated!");
    try {
    } catch (e) {
      console.log(e);
    }
  };
  const updateCurricular = async (i) => {
    try {
      const candidateToken = localStorage.getItem("candidateToken");
      const id = localStorage.getItem("uid");
      const dataToUpdate = getExtraInt[i];
      console.log({ dataToUpdate });
      dataToUpdate.token = candidateToken;
      const updateData = await axios.post(
        `${baseUrlAPI}/api/updateCurricular`,
        dataToUpdate
      );
      console.log({ updateData });
      setMessage(" Curricular has been updated!");
    } catch (e) {
      console.log(e);
    }
  };
  const updateInternship = async (i) => {
    try {
      const itemToUpdate = getIntern[i];
      const candidateToken = localStorage.getItem("candidateToken");
      const id = localStorage.getItem("uid");
      console.log({ itemToUpdate });
      itemToUpdate.token = candidateToken;
      const { data } = await axios.post(
        `${baseUrlAPI}/api/updateInternship`,
        itemToUpdate
      );
      console.log(data);
      setMessage("Internship has been updated!");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 className="dashboard-heading-d">My Profile</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="Profile-Overview">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
              <div className="flex-p-overview-my-pro">
                <p className="bol-pro" onClick={goToProfileOverview}>
                  <ion-icon name="search-circle-outline"></ion-icon> Profile
                  Overview
                </p>
                <p className="bol-pro" onClick={goToAppliedJobs}>
                  <ion-icon name="business-outline"></ion-icon> Applied Jobs
                </p>
                <p className="bol-pro" onClick={goToSavedJobs}>
                  <ion-icon name="bookmark-outline"></ion-icon> Saved Jobs
                </p>
                <p className="pro-over-col bol-pro">
                  <ion-icon name="person-circle-outline"></ion-icon> My Profile
                </p>
                {/* <p className='bol-pro' onClick={goToMessages}><ion-icon name="chatbox-ellipses-outline"></ion-icon> Messages</p> */}
                <p className="bol-pro" onClick={DownloadCV}>
                  <ion-icon name="document-outline"></ion-icon> Generate CV
                </p>
                <p className="bol-pro" onClick={goToChangePassword}>
                  <ion-icon name="lock-closed-outline"></ion-icon> Change
                  Password
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Deactivate
                  Account
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Log Out
                </p>
              </div>
            </div>

            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
              <div className="pad-mar-my-proo">
                <div className="my-pro-pad-mar">
                  {/* <img
                    className="g-i-avtar"
                    src="https://media.istockphoto.com/id/1288732130/photo/portrait-of-cheerful-mixed-race-teenage-girl.jpg?b=1&s=170667a&w=0&k=20&c=R3BwP5rv0nG441whzRmAaLXCs9OZMdZHKOfUw0oj4sQ="
                    alt="girl"
                  /> */}
                  <div>
                    <h3>
                      {firstName} {lastName}
                    </h3>
                    {/* <h4>{firstName} {lastName}</h4> */}
                    <p>
                      {Position} &nbsp; &nbsp;{" "}
                      {/* <ion-icon name="create-outline"></ion-icon> */}
                    </p>
                  </div>
                </div>

                <div className="box-pad-mar-k">
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={3} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </div>
                {/* {renderBasicInfo()} */}

                <section className="intern-form">
                  <div className="edi-form">
                    <span
                      className="edit_form"
                      style={{
                        backgroundColor: toggleGoBack ? "#68174a" : "#ef4625",
                      }}
                      onClick={() => navigate("/my-profile-experience")}
                      onChange={EditCandidateFormGoBack}
                    >
                      Go Back
                    </span>
                    <span
                      className="edit_form"
                      style={{
                        backgroundColor: toggle ? "#68174a" : "#ef4625",
                      }}
                      onClick={EditCandidateForm}
                    >
                      Edit Form
                    </span>
                  </div>

                  <div className="container">
                    <div className="row">
                      {message && (
                        <Alert
                          onClose={() => {
                            setMessage("");
                          }}
                          severity="success"
                        >
                          {message}
                        </Alert>
                      )}
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <h4 className="int-li-pro">
                          Internship & Live Projects:{" "}
                        </h4>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        {/* <h4
                          className="add-int-li-pro"
                          onClick={() => addFormFields()}
                        >
                          Add More
                        </h4> */}
                        <AddModal type={3} />
                        {/* <h4
                          className="add-int-li-pro"
                          onClick={() => navigate("/my-profile-experience")}
                        >
                          Go Back
                        </h4> */}
                      </div>
                    </div>

                    {getIntern && getIntern.length
                      ? getIntern.map((element, index) => {
                          return (
                            <div name="input">
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <div className="title-other">
                                    <label htmlFor="">Title </label> <br />
                                    <input
                                      type="text"
                                      placeholder="Title"
                                      name="title"
                                      // defaultValue={getIntern.title}
                                      value={element.title || ""}
                                      onChange={(e) =>
                                        handleChange(index, e, "int")
                                      }
                                      disabled={!disabled}
                                      style={{
                                        border: toggle
                                          ? "1px solid gray"
                                          : "none",
                                        backgroundColor: "white",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <div className="title-other">
                                    <label htmlFor="">Year </label> <br />
                                    <input
                                      type="text"
                                      placeholder="Year "
                                      name="starting_year"
                                      // defaultValue={getIntern.starting_year}
                                      value={element.starting_year || ""}
                                      onChange={(e) =>
                                        handleChange(index, e, "int")
                                      }
                                      disabled={!disabled}
                                      style={{
                                        border: toggle
                                          ? "1px solid gray"
                                          : "none",
                                        backgroundColor: "white",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="des-otherr">
                                    <label htmlFor="">
                                      Description
                                      <span
                                        className="red-span"
                                        style={{
                                          display: toggle ? "block" : "none",
                                        }}
                                      >
                                        (Add ";" for adding bullet point)
                                      </span>
                                    </label>{" "}
                                    <br />
                                    <textarea
                                      id=""
                                      className="dec-des-dec"
                                      cols="30"
                                      rows="5"
                                      placeholder="Description ...."
                                      name="description"
                                      // defaultValue={getIntern.description}
                                      value={element.description || ""}
                                      onChange={(e) =>
                                        handleChange(index, e, "int")
                                      }
                                      disabled={!disabled}
                                      style={{
                                        border: toggle
                                          ? "1px solid gray"
                                          : "none",
                                        backgroundColor: "white",
                                      }}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <div className="btn-flex">
                                <button
                                  className="Updates"
                                  style={{
                                    backgroundColor: toggleUpdate1
                                      ? "#68174a"
                                      : "#ef4625",
                                  }}
                                  onClick={() => {
                                    updateInternship(index);
                                    UpdateCandidateForm()
                                  }}
                                >
                                  Update
                                </button>
                                <span className="text-btn-button">
                                Please press the update button to update the new data in this section
                                 </span>
                                <div
                                  className="Updates"
                                  style={{ backgroundColor: toggleDelete1 ? "#68174a" : "#ef4625" }}
                                  onClick={() => {
                                    handleDelete("c_internship", element.uid);
                                  }}
                                  onChange={DeleteCandidateForm1}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                              <hr />
                            </div>
                          );
                        })
                      : "No data found!"}

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <h4 className="int-li-pro">
                          Licences, Cert & Workshops:{" "}
                        </h4>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        {/* <h4
                          className="add-int-li-pro"
                          onClick={() => addFormFieldsLicence()}
                        >
                          Add More
                        </h4> */}
                        <AddModal type={5} />
                      </div>
                    </div>

                    {/* {licenceValues.map((element, index) => {
                    return ( */}
                    {getLiecence && getLiecence.length
                      ? getLiecence.map((element, index) => {
                          return (
                            <div name="input" key={index}>
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                  <div className="title-other">
                                    <label htmlFor="">Title </label> <br />
                                    <input
                                      type="text"
                                      placeholder="Title"
                                      name="title"
                                      defaultValue={element.title || ""}
                                      onChange={(e) =>
                                        handleChangeLicence(index, e)
                                      }
                                      disabled={!disabled}
                                      style={{
                                        border: toggle
                                          ? "1px solid gray"
                                          : "none",
                                        backgroundColor: "white",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                  <div className="title-other">
                                    <label htmlFor="">Year </label> <br />
                                    <input
                                      type="text"
                                      placeholder="Year "
                                      name="year"
                                      defaultValue={element.year || ""}
                                      onChange={(e) =>
                                        handleChangeLicence(index, e)
                                      }
                                      disabled={!disabled}
                                      style={{
                                        border: toggle
                                          ? "1px solid gray"
                                          : "none",
                                        backgroundColor: "white",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                  <div className="title-other">
                                    <label htmlFor="">Category </label> <br />
                                    <select
                                      name="category"
                                      id=""
                                      defaultValue={element.category || ""}
                                      onChange={(e) =>
                                        handleChangeLicence(index, e)
                                      }
                                      disabled={!disabled}
                                      style={{
                                        border: toggle
                                          ? "1px solid gray"
                                          : "none",
                                        backgroundColor: "white",
                                        appearance: toggle ? "auto" : "none",
                                      }}
                                    >
                                      <option value="Licence">Licence</option>
                                      <option value="Certificate">
                                        Certificate
                                      </option>
                                      <option value="Workshop">Workshop</option>
                                      <option value="Others">Others</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                  <div className="title-other">
                                    <label htmlFor=""> Institute Name </label>{" "}
                                    <br />
                                    <input
                                      type="text"
                                      placeholder=" Institute Name "
                                      name="organization"
                                      defaultValue={element.organization || ""}
                                      onChange={(e) =>
                                        handleChangeLicence(index, e)
                                      }
                                      disabled={!disabled}
                                      style={{
                                        border: toggle
                                          ? "1px solid gray"
                                          : "none",
                                        backgroundColor: "white",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="des-otherr">
                                    <label htmlFor="">
                                      Description *
                                      <span
                                        className="red-span"
                                        style={{
                                          display: toggle ? "block" : "none",
                                        }}
                                      >
                                        (Add ";" for adding bullet point)
                                      </span>
                                    </label>{" "}
                                    <br />
                                    <textarea
                                      name="description"
                                      className="dec-des-dec"
                                      id=""
                                      cols="30"
                                      rows="5"
                                      placeholder="Description ...."
                                      defaultValue={element.description || ""}
                                      onChange={(e) =>
                                        handleChangeLicence(index, e)
                                      }
                                      disabled={!disabled}
                                      style={{
                                        border: toggle
                                          ? "1px solid gray"
                                          : "none",
                                        backgroundColor: "white",
                                      }}
                                    ></textarea>
                                    <div className="btn-flex">
                                      <button
                                        className="Updates"
                                        style={{ backgroundColor: toggleUpdate2 ? "#68174a" : "#ef4625" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          updateLicences(index);
                                          UpdateCandidateForm2()
                                        }}
                                      >
                                        Update
                                      </button>
                                      <span className="text-btn-button">
                                          Please press the update button to update the new data in this section
                                        </span>
                                      <button
                                        className="Updates"
                                        style={{ backgroundColor: toggleDelete2 ? "#68174a" : "#ef4625" }}
                                        onClick={() => {
                                          handleDelete(
                                            "c_licences",
                                            element.uid
                                          );
                                        }}
                                        onChange={DeleteCandidateForm1}
                                      >
                                        <DeleteIcon />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : "No data found"}
                    <br></br>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <h4 className="int-li-pro">Extra – curricular: </h4>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <AddModal type={4} />
                      </div>
                    </div>

                    {getExtraInt && getExtraInt.length
                      ? getExtraInt.map((element, index) => {
                          return (
                            <div name="input">
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                  <div className="title-other">
                                    <label htmlFor="">Title </label> <br />
                                    <input
                                      type="text"
                                      placeholder="Title"
                                      name="title"
                                      // defaultValue={getExtraInt.title}
                                      value={element.title || ""}
                                      onChange={(e) =>
                                        handleChangeCurricular(index, e)
                                      }
                                      disabled={!disabled}
                                      style={{
                                        border: toggle
                                          ? "1px solid gray"
                                          : "none",
                                        backgroundColor: "white",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                  <div className="title-other">
                                    <label htmlFor="">Year </label> <br />
                                    <input
                                      type="text"
                                      placeholder="Year "
                                      name="year"
                                      // defaultValue={getExtraInt.year}
                                      value={element.year || ""}
                                      onChange={(e) =>
                                        handleChangeCurricular(index, e)
                                      }
                                      disabled={!disabled}
                                      style={{
                                        border: toggle
                                          ? "1px solid gray"
                                          : "none",
                                        backgroundColor: "white",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="des-otherr">
                                    <label htmlFor="">
                                      Description
                                      <span
                                        className="red-span"
                                        style={{
                                          display: toggle ? "block" : "none",
                                        }}
                                      >
                                        (Add ";" for adding bullet point)
                                      </span>
                                    </label>{" "}
                                    <br />
                                    <textarea
                                      id=""
                                      className="dec-des-dec"
                                      cols="30"
                                      rows="5"
                                      placeholder="Description ...."
                                      name="description"
                                      // defaultValue={getExtraInt.description}
                                      value={element.description || ""}
                                      onChange={(e) =>
                                        handleChangeCurricular(index, e)
                                      }
                                      disabled={!disabled}
                                      style={{
                                        border: toggle
                                          ? "1px solid gray"
                                          : "none",
                                        backgroundColor: "white",
                                      }}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <div className="btn-flex">
                                <button
                                  className="Updates"
                                  style={{ backgroundColor: toggleUpdate3 ? "#68174a" : "#ef4625" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    updateCurricular(index);
                                    UpdateCandidateForm3()
                                  }}
                                >
                                  Update
                                </button>
                                <span className="text-btn-button">
                                    Please press the update button to update the new data in this section
                                  </span>
                                <button
                                  className="Updates"
                                  style={{ backgroundColor: toggleDelete3 ? "#68174a" : "#ef4625" }}
                                  onClick={() => {
                                    handleDelete("c_curricular", element.uid);
                                  }}
                                  onChange={DeleteCandidateForm1}
                                >
                                  <DeleteIcon />
                                </button>
                              </div>
                            </div>
                          );
                        })
                      : "No data found"}

                    {/* <div className="ml-fp-r">
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Agree our terms and policy"
                        className="check-label"
                      />
                      <button className="btn-label">Back</button>
                    </div> */}

                    <div className="r-btn-next">
                      <button
                        onClick={() => {
                          navigate("/my-profile-preference");
                        }}
                      >
                        Proceed To Next Step
                      </button>
                    </div>
                    {/* <div className="reg1-sign">
                      <p>
                        Already have an account? <span>Sign In Now.</span>
                      </p>
                    </div> */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfileOthers;
