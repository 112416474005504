import React, { useEffect, useState } from "react";
import "../css/Notice_Board.css";

import Button from "@mui/material/Button";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import FeedIcon from "@mui/icons-material/Feed";
import DescriptionIcon from "@mui/icons-material/Description";
import Stack from "@mui/material/Stack";

import baseUrlAPI from "./BaseURL";
import { useNavigate } from "react-router-dom";

import { Modal, ModalBody, Row, Col } from "reactstrap";
import ErrorIcon from "@mui/icons-material/Error";

// import LinkedInIcon from "@mui/icons-material/LinkedIn";

// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

const Notice_Board = () => {
  const uid = localStorage.getItem("uid");
  useEffect(() => {
    if (uid) {
      navigate("/notice-board");
      window.scrollTo(0, 0);
    } else {
      navigate("/register");
      window.scrollTo(0, 0);
    }
  }, []);

  const [notice, setNotice] = useState([]);
  // const [login, setLogin] = useState(false);

  const noticeURL = `${baseUrlAPI}/api/getNotice?token=18f4ff6f392b0b597cc878c4617add9c&is_public=1`;

  useEffect(() => {
    fetch(noticeURL).then((resultNotice) => {
      resultNotice.json().then((noticeRes) => {
        console.log(noticeRes);
        setNotice(noticeRes);
      });
    });
  }, [noticeURL]);

  const navigate = useNavigate("");

  const tokenid = localStorage.getItem("candidateToken");
  useEffect(() => {
    if (!tokenid) {
      // setLogin(true)
      window.scrollTo(0, 0);
    }
  }, []);

  // const MemberLogIn = () =>{
  //   navigate("/member-log-in");
  // }

  return (
    <div className="nb-not">
      <div className="Notice-Board">
        <h1 className="NB-heading">Notice Board</h1>
        <p className="nb-p-para">
          Please find below the critical messages and updates for the ongoing
          hiring proceses:
        </p>
        <div>
          {/* <Stack className="nb-btn-b" direction="row" spacing={2}>
            <Button
              className="btn-comp-nbn-n"
              variant="outlined"
              startIcon={<NewspaperIcon />}
            >
              Notice
            </Button>

            <Button
              className="btn-comp-nbn-1"
              variant="outlined"
              startIcon={<FeedIcon />}
            >
              Selection Notice
            </Button>
            <Button
              className="btn-comp-nbn-1"
              variant="outlined"
              startIcon={<DescriptionIcon />}
            >
              General Notice
            </Button>
          </Stack> */}
        </div>
      </div>

      <div className="NB-main-component">
        <div className="container co-out-com">
          {/* <Stack className="nb-btn-b" direction="row" spacing={2}>
            <Button
              className="btn-comp-nbn-n"
              variant="outlined"
              startIcon={<NewspaperIcon />}
            >
              Notice
            </Button>

            <Button
              className="btn-comp-nbn-1"
              variant="outlined"
              startIcon={<FeedIcon />}
            >
              Selection Notice
            </Button>
            <Button
              className="btn-comp-nbn-1"
              variant="outlined"
              startIcon={<DescriptionIcon />}
            >
              General Notice
            </Button>
          </Stack> */}
          {/* <p className="nb-p-para">
            Please find below the critical messages and updates for the ongoing
            hiring operations:
          </p> */}
          <br />
          {notice && notice.length != 0
            ? notice.map((value) => {
                return (
                  <div className="nb-job-main">
                    <div className="nb-job-list">
                      <div className="nb-icn">
                        {/* <img src={value.notice_image} alt="hi" /> */}
                      </div>{" "}
                      &nbsp; &nbsp;
                      <div className="nb-head">
                        <div className="flex">
                          {/* <img className="noticeImg" src={value.notice_image} /> */}
                        </div>
                        <div>
                          <p className="nb-head-heading">
                            {value.notice_title}
                          </p>
                          {/* <p className="nb-head-para">{value.notice_type}</p> */}
                        </div>
                        {/* <div>
                          <img className="noticeImg" src={value.notice_image} />
                        </div> */}
                      </div>
                      {/* <div className="nb-pa">
                            <p>Shortlisted Candidates: 10/108</p>
                          </div> */}
                    </div>
                    <hr className="nb-head-heading_line" />

                    <div className="Next-Round-flex">
                      <div className="Next-Round">
                        <h5>Notice Description:</h5>

                        <div
                          style={{ padding: 0, margin: 0 }}
                          dangerouslySetInnerHTML={{
                            __html: value.notice_description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : "No data founds"}
        </div>
      </div>

      {/* <div className="Get-in-Touch">
            <div className="git-flex container">
                <div className="git-flex1">
                    <h1>Get in <span>Touch</span></h1>
                    <p className='git-flex1-text'>
                    Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas <br />
                    accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida.
                    </p>
                    <div className="name">
                        <input type="text" placeholder='Enter Your Name' />
                        <input type="text" placeholder='Category (Optional)' />
                    </div>

                    <div className="name">
                        <input type="text" placeholder='Your Email' />
                        <input type="text" placeholder='Your Number' />
                    </div>
                    <textarea name="" id="" cols="30" rows="10" placeholder='Your Message Description'/>
                </div>
                <div className="git-flex2">
                    <img className='git-flex2-img111111-nb' src="https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGNvbnRhY3R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" alt="" />
                    <img className='git-flex2-img222222-nb' src="https://media.istockphoto.com/photos/colleagues-discussing-business-picture-id1319571139?b=1&k=20&m=1319571139&s=170667a&w=0&h=kh-o5VxMCPPeF9bwjQB2qtsZGsmgiXhVX3X9WUEmrCQ=" alt="" />
                </div>
            </div>
            <div className="git-btn-flex container">
                <button>Send Message</button> 
                <FormControlLabel control={<Checkbox defaultChecked />} label="By clicking contact us button, you agree our terms and policy," />
            </div>

            
      </div> */}

      <div className="hei-height"></div>
    </div>
  );
};

export default Notice_Board;
