import React from "react";
import "../css/Privacy_Policy.css";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Privacy_Policy = () => {
  return (
    <div className="ba-ba-com">
      <div className="Privacy-Policy">
        <h1 className="pp-heading">Privacy Policy</h1>
      </div>

      <div className="pp-heading-main">
        <div className="container cokkkk">
          <div className="pp-heading-1">
            <div className="pph-flex">
              {/* <div style={{ fontSize: "20px", marginRight: "10px"
    }}></div> */}
              <p className="pph-flex-para">
                We, at PHC are committed to respecting your online privacy and
                recognize the need for appropriate protection and management of
                any personally identifiable information you share with us.
              </p>
            </div>{" "}
            <br />
            <div className="pph-flex">
              {/* <div style={{ fontSize: "20px", marginRight: "10px" }}>•</div> */}
              <p className="pph-flex-para">
                We will only use your personal data in a fair and reasonable
                manner, and where we have a lawful reason to do so. We may
                process your Personal Information for providing our services and
                products to you including to send you job alerts, calendar
                alerts, relevant search results, recommended jobs and/or
                candidates (as the case maybe), and other social media
                communication facilities.
              </p>
            </div>{" "}
            <br />
            <div className="pph-flex">
              {/* <div style={{ fontSize: "20px", marginRight: "10px" }}>•</div> */}
              <p className="pph-flex-para">
                To use the Platform, you agree that you must be the minimum age,
                18 years or older
              </p>
            </div>{" "}
            <br />
            <div className="pph-flex">
              {/* <div style={{ fontSize: "20px", marginRight: "10px" }}>•</div> */}
              <p className="pph-flex-para">
                Your Personal Information will not be retained by PHC any longer
                than it is necessary for the purposes for which the Personal
                Information is processed and/or in accordance with legal,
                regulatory, contractual or statutory obligations as applicable.
                At the expiry of such periods, your Personal Information will be
                deleted or archived in compliance with applicable laws.
              </p>
            </div>{" "}
            <br />
          </div>
        </div>
      </div>
    </div>

  );
};

export default Privacy_Policy;
