import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import baseUrlAPI from "../BaseURL";
import NavigateTo from "../NavigateTo";
const ProfileSnapPreference = () => {
  // const formattedCTC = expected_ctc.map((ctc) => ctc / 100000);
  const [preference, setPreference] = useState(null);
  const token = localStorage.getItem("candidateToken");
  const uid = localStorage.getItem("uid");
  console.log({ uid, token });
  const getPreference = async () => {
    const { data } = await axios.post(`${baseUrlAPI}/api/getPreference`, {
      candidate_id: uid,
      token,
    });
    setPreference(data[0]);
    console.log({ pref: data[0] });
  };
  useEffect(() => {
    getPreference();
  }, []);
  if (!preference) {
    return <NavigateTo label={"Preference"} url={"preference"} />;
  }
  return (
    <div>
      <div>
        <strong>Expected CTC : </strong>
        {""}
        {JSON.parse(preference.expected_ctc).map((e, i) => {
          if (i === 0) {
            return `${e / 100000} lakhs - `;
          }
          return `${e / 100000} lakhs `;
        })}
      </div>

      <div>
        <strong>Are you Serving Notice period : </strong>
        {preference.notice_period}
      </div>
      <div>
        <strong>Is Willing to Relocate : </strong>
        {preference.willing_relocate}
      </div>
    </div>
  );
};

export default ProfileSnapPreference;
