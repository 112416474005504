import react, { useState } from "react";
import axios from "axios";
import { Alert } from "@mui/material";

import baseUrlAPI from "./BaseURL";

import AddEducationForm from "./EducationForm";
import AddExperience from "./AddExperience";
import AddInternShipForm from "./AddInternshipForm";

import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/AddModal.css";
import AddCurricularForm from "./AddcurricularForm";
import AddLicenceForm from "./AddLicenceForm";

const AddModal = ({ type }) => {
  const candidate_id = localStorage.getItem("uid");
  const tokenid = localStorage.getItem("candidateToken");
  const [error, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const [licence, setLicence] = useState({
    title_l: "",
    year_l: "",
    category: "Licence",
    college: "",
    description_l: "",
  });

  const [Education, setEducation] = useState({
    college: "",
    degree: "",
    i_year: "",
    o_year: "",
    score: "",
  });
  const [internship, setInternship] = useState({
    title: "",
    starting_year: "",
    description: "",
  });
  const [experience, setExperience] = useState({
    company: "",
    job: "",
    location: "",
    i_year: "",
    o_year: "",
    description: "",
    job_role: "",
    achievement: "",
  });
  const [curricular, setCurricular] = useState({
    title_c: "",
    year: "",
    description_c: "",
  });
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  // Validators
  const validateEducation = () => {
    switch (true) {
      case Education.college === "":
        setErr("Please mention the college name");
        return false;
      case Education.degree === "":
        setErr("Plese mention the degree name");
        return false;
      case Education.i_year > Education.o_year:
        setErr("In year cannot be greater than the out year");
        return false;
      case Education.score > 100 || Education.score < 0:
        setErr("The score should be in between 0-100");
        return false;
      case Education.i_year < 1960 || Education.o_year < 1960:
        setErr("Please check the years, they are not valid.");
        return false;
    }
    return true;
  };
  const validateExperience = () => {
    switch (true) {
      case experience.company === "":
        setErr("Company Name is required");
        return false;
      case experience.job === "":
        setErr("Job title is required");
        return false;
      case experience.i_year === "":
        setErr("Initalization year is required.");
        return false;
      case experience.o_year === "":
        setErr("completion year is required.");
        return false;
    }
    return true;
  };

  //Handle submit
  const addExperience = async () => {
    const dataValid = validateExperience();
    if (!dataValid) {
      return;
    }
    const body = {
      candidate_id,
      token: tokenid,
      form_data: [experience],
    };

    const { data } = await axios.post(`${baseUrlAPI}/api/AddExperience`, body);
    setErr("");
    setSuccess("Experience added successfully, the page will reload shortly.");

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };
  const AddEducation = async () => {
    const isDataValid = validateEducation();
    if (!isDataValid) {
      return;
    }
    const body = {
      token: tokenid,
      candidate_id: candidate_id,
      form_data: [Education],
    };
    const { data } = await axios.post(
      `${baseUrlAPI}/api/AddQualification`,
      body
    );
    setErr("");
    setSuccess(
      "Qualification added sucessfully, the page will reload shortly. "
    );
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const addInternship = async () => {
    const body = {
      token: tokenid,
      candidate_id,
      form_data: [internship],
    };
    const { data } = await axios.post(`${baseUrlAPI}/api/addInternship`, body);
    setSuccess(
      "Internship added successfully, the page will be reloaded shortly"
    );
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };
  const addCurricular = async () => {
    const body = {
      token: tokenid,
      candidate_id,
      form_dataa: [curricular],
    };
    const { data } = await axios.post(`${baseUrlAPI}/api/addCurricular`, body);
    setSuccess(
      "Curricular added successfully, the page will be reloaded shortly"
    );
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };
  const addLicence = async () => {
    const body = {
      token: tokenid,
      candidate_id,
      form_dataaa: [licence],
    };
    const { data } = await axios.post(`${baseUrlAPI}/api/addLicence`, body);
    setSuccess(
      "Licence added successfully, the page will be reloaded shortly."
    );
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };
  // Handle change functions
  const handleChangeEducation = (e) => {
    const temp = { ...Education };
    temp[e.target.name] = e.target.value;
    setEducation(temp);
  };
  const handleChangeExperience = (e) => {
    const temp = { ...experience };
    temp[e.target.name] = e.target.value;

    setExperience(temp);
  };
  const handleChangeInternship = (e) => {
    const temp = { ...internship };
    temp[e.target.name] = e.target.value;
    setInternship(temp);
  };
  const handleChangeCurricular = (e) => {
    const temp = { ...curricular };
    temp[e.target.name] = e.target.value;
    setCurricular(temp);
  };
  const handleChangeLicence = (e) => {
    const temp = { ...licence };
    temp[e.target.name] = e.target.value;
    setLicence(temp);
  };

  const handleShow = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  const renderBody = () => {
    switch (type) {
      case 1:
        return <AddEducationForm handleChange={handleChangeEducation} />; // 1 for education
      case 2:
        return <AddExperience handleChange={handleChangeExperience} />; // 2 for experience
      case 3:
        return <AddInternShipForm handleChange={handleChangeInternship} />; // 3 for internship
      case 4:
        return <AddCurricularForm handleChanege={handleChangeCurricular} />; // 4 for cirricular
      case 5:
        return <AddLicenceForm handleChange={handleChangeLicence} />; // 5 for licence
    }
  };

  const renderError = () => {
    if (error !== "") {
      return <Alert severity="error">{error}</Alert>;
    }
  };
  const renderSuccess = () => {
    if (success !== "") {
      return <Alert severity="success">{success}</Alert>;
    }
  };
  return (
    <>
      <button className="add-int-li-pro " onClick={handleShow}>
        Add More +
      </button>

      <div
        className={`modal ${showModal ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">My Modal Title</h5>
              <button type="button" className="close" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {renderError()}
            {renderSuccess()}
            <div className="modal-body">{renderBody()}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="button"
                onClick={() => {
                  if (type === 1) {
                    AddEducation();
                  } else if (type === 2) {
                    addExperience();
                  } else if (type === 3) {
                    addInternship();
                  } else if (type === 4) {
                    addCurricular();
                  } else if (type === 5) {
                    addLicence();
                  }
                }}
                className="btn btn-primary"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal-backdrop ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
      ></div>
    </>
  );
};

export default AddModal;
