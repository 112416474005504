// Experience

import React, { useState, useEffect } from "react";
import "../css/RegExperience.css";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// import Button from '@mui/material/Button';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";

const steps = ["Basic Info", "Education", "Experience", "Others", "Preference"];

const RegExperience = () => {
  const uid = localStorage.getItem("uid");
  useEffect(() => {
    if (uid) {
      navigate("/candidate-experience");
      window.scrollTo(0, 0);
    } else {
      navigate("/register");
      window.scrollTo(0, 0);
    }
  }, []);
  // api input post experience

  // const [candidate_id, setCandidate_id] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [job_title, setJob_title] = useState("");

  const [location, setLocation] = useState("");
  const [starting_year, setStarting_year] = useState("");
  const [completion_year, setCompletion_year] = useState("");
  const [description, setDescription] = useState("");
  const [job_role, setJob_role] = useState("");

  // add more

  const [input, setInput] = useState([{ input: "" }]);

  const handleInputChang = (e, index) => {
    const { name, value } = e.target;
    const list = [...input];
    list[index][name] = value;
    setInput(list);
  };

  const addMore = () => {
    setInput([...input, { input: "" }]);
  };

  const navigate = useNavigate();

  const goToOthers = () => {
    navigate("/others");

    const tokenid = localStorage.getItem("token");

    const candidate_id = localStorage.getItem("uid");

    const Experience = {
      candidate_id,
      company_name,
      job_title,
      location,
      starting_year,
      completion_year,
      description,
      job_role,
      token: tokenid,
    };
    console.log(Experience);

    fetch("http://3.109.83.81/phc-admin/api/AddExperience", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(Experience),
    })
      .then((result) => result.json())
      .then((data) => {
        console.log("result", data);
        if (data.status === 200) {
          alert("success");
        }
      })

      .catch((e) => {
        console.log("e", e);
      });
  };

  return (
    <>
      <div className="Free-Today">
        <div className="container">
          <div className="ce-ex-re">
            <h5 className="reg-to">Register</h5>
            <h1 className="reg-start">
              Start for <span>Free Today</span>{" "}
            </h1>
            <p className="reg-para">
              Access to all features. No credit card required.
            </p>
          </div>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={3} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>

        <div className="r-form-first">
          <div className="container">
            <form action="">
              <div className="expe">
                <div className="company-name">
                  <label htmlFor="">Current Organization *</label> <br />
                  <select name="" id="" className="input">
                    <option value="">ABC Company</option>
                    <option value="">A Company</option>
                    <option value="">B Company</option>
                  </select>
                </div>
                <div className="job-title">
                  <label htmlFor="" className="cu-mar">
                    Current Role Name *
                  </label>{" "}
                  <br />
                  <select name="" id="">
                    <option value="">Software Engineer</option>
                    <option value="">Node Developer</option>
                    <option value="">PHP Developer</option>
                  </select>
                </div>
              </div>
              <div className="expe">
                <div className="company-name">
                  <label className="ptt" htmlFor="">
                    Work Exp in current organization: Year & Month *
                  </label>{" "}
                  <br />
                  <input
                    type="date"
                    placeholder="Work Exp in current organization: Year & Month"
                  />
                </div>
                <div className="job-title">
                  <label className="ptt" htmlFor="">
                    Total Work Experience: Year & Month *
                  </label>{" "}
                  <br />
                  <input
                    type="date"
                    placeholder="Total Work Experience: Year & Month"
                  />
                </div>
              </div>
              {/* <div className="rnr">
                <div className="rnr-flex">
                  <label htmlFor="">RNR Description*</label> 
                  <button>Add More</button>
                </div> <br />
                <input type="text" placeholder='Description' />
            </div> */}
              <div className="date">
                <div className="date1">
                  <div className="Started">
                    <label htmlFor=""> Total Current Total CTC *</label> <br />
                    <input type="text" placeholder="Current Total CTC: " />
                  </div>
                  <div className="Current">
                    <label htmlFor=""> Total Current Fixed CTC *</label> <br />
                    <input type="text" placeholder="Current Fixed CTC" />
                  </div>
                </div>
                <div className="date2">
                  <div className="Left">
                    <label htmlFor="">Job Tags/ Skill Set: *</label> <br />
                    <input type="text" placeholder="Job Tags/ Skill Set" />
                  </div>
                  <div className="Duration">
                    <label htmlFor="">Current Industry Category</label> <br />
                    <select name="" id="">
                      <option value="">Software Industry</option>
                      <option value="">Mechanical Industry</option>
                      <option value="">Aerospace Industry</option>
                      <option value="">Marine Industry</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="expe">
                <div className="company-name">
                  <label className="ptt" htmlFor="">
                    Notice Period *
                  </label>{" "}
                  <br />
                  <input type="text" placeholder="30 Days" />
                </div>
                <div className="job-title">
                  <label className="ptt" htmlFor="">
                    WFH Situation *
                  </label>{" "}
                  <br />
                  <select name="" id="">
                    <option value="">Complete</option>
                    <option value="">Partial</option>
                    <option value="">Work from office</option>
                  </select>
                </div>
              </div>{" "}
              <br />
              <div className="flex-ad-mo">
                <h4 className="post-gr-col-font" onClick={() => navigate(-1)}>
                  {" "}
                  Go Back{" "}
                </h4>
                <h4 className="post-gr-col-font">Work Experience Details: </h4>
                <h4 className="post-gr-col-font" onClick={addMore}>
                  Add More
                </h4>
              </div>
              {input.map((x, i) => {
                return (
                  <div
                    name="input"
                    onChange={(e) => handleInputChang(e, i)}
                    className="or-add-more-ad"
                  >
                    {/* <div className="j-titlee">
                  <div className="flex-ad-mo">
                   <label htmlFor="">Job Profile *</label>
                   <h4 className='post-gr-col-font'onClick={addMore}>Add More</h4>
                  </div>
                  <input type="text" placeholder='Job Profile' value={job_profile} onChange={(e)=>setJob_profile(e.target.value)}/>
                </div> */}

                    <div className="org-lo row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label htmlFor="">Job Title *</label> <br />
                        {/* <input type="text" placeholder='Candidate ID' value={candidate_id} onChange={(e)=>setCandidate_id(e.target.value)}/> */}
                        <input
                          type="text"
                          placeholder="Job Profile"
                          value={job_title}
                          onChange={(e) => setJob_title(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label htmlFor="">Company Name *</label> <br />
                        <input
                          type="text"
                          placeholder="Company Name"
                          value={company_name}
                          onChange={(e) => setCompany_name(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="org-lo row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label htmlFor="">Starting year *</label> <br />
                        <input
                          type="date"
                          placeholder="Starting year"
                          value={starting_year}
                          onChange={(e) => setStarting_year(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label htmlFor="">Completion Year *</label> <br />
                        <input
                          type="date"
                          placeholder="Completion Year"
                          value={completion_year}
                          onChange={(e) => setCompletion_year(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="org-lo row">
                      {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <label htmlFor="">Candidate ID *</label> <br />
                    <input type="text" placeholder='Candidate ID' value={candidate_id} onChange={(e)=>setCandidate_id(e.target.value)}/>
                  </div> */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label htmlFor="">Location *</label> <br />
                        <input
                          type="text"
                          placeholder="Location"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* <div className='token-education'>
                  <label htmlFor="">Token *</label> <br />
                  <input type="text" placeholder='Token' value={token} onChange={e=>setToken(e.target.value)}/>
                </div> */}

                    <div className="j-titlee">
                      <label htmlFor="">Description *</label> <br />
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        placeholder="Description ...."
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>

                    <div className="ml-fp-r">
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={job_role}
                            onChange={(e) => setJob_role(e.target.checked)}
                          />
                        }
                        label="This is my current job role"
                        className="check-label"
                      />
                      {/* <button className='btn-label' >Add More Work Experience Details</button> */}
                    </div>
                  </div>
                );
              })}
              {/* <h4 className='post-gr-col-font'>Licences, Cert & Workshops:</h4> */}
              {/* <div className="org-lo row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <label htmlFor="">Title *</label> <br />
                <input type="text" placeholder='Title'/>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <label htmlFor="">Institute Name *</label> <br />
                <input type="text" placeholder='Institute Name'/>
              </div>

            </div>

            <div className="org-lo row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <label htmlFor="">Year *</label> <br />
                <input type="text" placeholder='Year'/>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 cat-emp-reg">
                <label htmlFor="">Category *</label> <br />
                <select name="" id="">
                  <option value="">Licence</option>
                  <option value="">Certificate</option>
                  <option value="">Workshop</option>
                  <option value="">others </option>
                </select>
              </div>

            </div>

            <div className="j-titlee">
              <label htmlFor="">Description *</label> <br />
              <textarea name="" id="" cols="30" rows="5" placeholder='Description ....'></textarea>
            </div>

            <div className="ml-fp-r">
            <FormControlLabel control={<Checkbox  />} label="This is my current job role" className='check-label'/>
            <button className='btn-label' >Add More Licences, Cert & Workshops Details</button>
            </div> */}
            </form>
            {/* <hr /> */}
            <div className="ml-fp-r">
              <FormControlLabel
                control={<Checkbox />}
                label="Agree our terms and policy"
                className="check-label"
              />
              <button className="btn-label">Back</button>
            </div>

            <div className="r-btn-next">
              <button onClick={goToOthers}>Proceed To Next Step</button>
            </div>
            <div className="reg1-sign">
              <p>
                Already have an account? <span>Sign In Now.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegExperience;
