import React from "react";
import "../css/New_Home.css";
// import topimg1 from "../images/user.png";
import MySvgImage from "../images/phc-process.svg";
import topimg2 from "../images/briefcase.png";
import topimg3 from "../images/curriculum-vitae.png";
import topimg1 from "../images/membership.png";
import topimg4 from "../images/process.png";
import Form from "./Form";
import topimg5 from "../images/phc_img1.jpg";
import top20 from "../images/20.jpg";
import top21 from "../images/21.jpg";
import top18 from "../images/181.png";
import SearchIcon from "@mui/icons-material/Search";
import keyword from "../images/keyword.png";
import BadgeIcon from "@mui/icons-material/Badge";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import JobCard from "./Job_card";
import topimg6 from "../images/6.jpg";
import topimg13 from "../images/13.jpg";
import topimg14 from "../images/14.jpg";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import AllInboxIcon from "@mui/icons-material/AllInbox";

import Button from "@mui/material/Button";
// import DeleteIcon from '@mui/icons-material/Delete';

import GroupsIcon from "@mui/icons-material/Groups";
// import CampaignIcon from '@mui/icons-material/Campaign';
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
// import ManageSearchIcon from '@mui/icons-material/ManageSearch';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import AssignmentIcon from '@mui/icons-material/Assignment';

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import rp from "../images/rp.JPG";

import baseUrlAPI from "./BaseURL";

import { Modal, ModalBody, Row, Col } from "reactstrap";
import { Alert } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// import logo from '../images/logo.png';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1100 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const NewHome = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState(null);

  // const collegeURL = 'http://3.109.83.81/phc-admin/api/colleges';

  const collegeURL = `${baseUrlAPI}/api/colleges`;

  const [info, setInfo] = useState([]);

  useEffect(() => {
    fetch(collegeURL).then((result) => {
      result.json().then((res) => {
        setInfo(res.data);
        // localStorage.setItem("uid", res.data.uid)
      });
    });
  }, [collegeURL]);

  // const jobURL = 'http://3.109.83.81/phc-admin/api/jobs';
  const renderJobSearch = () => {
    if (!query) {
      return;
    }
    let searchQuery;
    // 1 is for mba domain 2 is for location
    switch (true) {
      case query.type === 1:
        searchQuery = `mba=${query.value}`;
        break;
      case query.type === 2:
        searchQuery = `location=${query.value}`;
        break;
      case query.type === 3:
        searchQuery = `keyword=${query.value}`;
        break;
    }

    navigate(`/job?${searchQuery}`);
  };
  const jobURL = `${baseUrlAPI}/api/jobs?page=1`;

  const [job, setJob] = useState([]);

  useEffect(() => {
    fetch(jobURL).then((result) => {
      result.json().then((res) => {
        setJob(res?.data || []);
      });
    });
    // if(!localStorage.getItem("candidateToken")){
    // }
  }, [navigate, jobURL]);

  // get Role API URL
  const getRoleUrl = `${baseUrlAPI}/api/getRole`;

  const [getRole, setGetRole] = useState([]);

  useEffect(() => {
    fetch(getRoleUrl).then((resultRole) => {
      resultRole.json().then((resRole) => {
        setGetRole(resRole?.data);

        // RoleButton({ uid: resRole?.data?.length ? resRole?.data[0]?.uid : "" });
        // localStorage.setItem("roleUID", resRole.data)
      });
    });
  }, [getRoleUrl]);

  const goToJobSearch = (value) => {
    window.scrollTo(0, 0);
    navigate("/job-search/" + value?.uid);
  };

  const contactUS = () => {
    window.scrollTo(0, 0);
    navigate("/contact-us");
  };

  const searchJOBS = () => {
    window.scrollTo(0, 0);
    navigate("/job");
  };

  const RegisterNOW = () => {
    window.scrollTo(0, 0);
    navigate("/register");
  };

  const RoleButton = (e) => {
    console.log(e);
    navigate(`/job?role_id=${e.uid}`);

    // if (!e) {
    //   return;
    // }
    // fetch(jobURL + "?role_id=" + e?.uid).then((result) => {
    //   result.json().then((res) => {
    //     setJob(res?.data || []);
    //   });
    // });
  };

  // addContact_query start

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");

  const [err, setErr] = useState({});
  const [sendMessage, setSendMessage] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    if (!name) {
      errors.name = " Please Fill Name";
    }
    if (!email) {
      errors.email = "Please Fill Email";
    }
    if (
      !email
        .toLocaleLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      errors.email = "Email is not valid";
    }
    if (!mobile) {
      errors.mobile = "Please Fill Mobile Number";
    }
    if (mobile.length !== 10) {
      errors.mobile = "Please Enter 10-digit Mobile Number";
    }
    if (isNaN(mobile)) {
      errors.mobile = "Mobile number is not valid";
    }
    if (!category) {
      errors.category = "Please Fill Category";
    }
    if (!description) {
      errors.description = "Please Fill Description";
    }
    if (Object.keys(errors).length === 0) {
      // submit the form
      setErr({});
      setSuccess(true);
    } else {
      setErr(errors);
    }

    const addContact_query_URL = `${baseUrlAPI}/api/addContact_query`;

    const token = localStorage.getItem("candidateToken");

    const Add_Contact_OBJ = {
      name,
      email,
      mobile,
      category,
      description,
      token,
    };
    // const Errors = [];
    // if (name === "") {
    //   Errors.push(" Please Fill Name");
    // }

    // if (email === "") {
    //   Errors.push("Please Fill Email");
    // }

    // if (
    //   !email
    //     .toLocaleLowerCase()
    //     .match(
    //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //     )
    // ) {
    //   Errors.push("Email is not valid");
    // }

    // if (mobile === "") {
    //   Errors.push("Please Fill Mobile Number");
    // }

    // if (mobile.length !== 10) {
    //   Errors.push("Please Enter 10-digit Mobile Number");
    // }
    // if (isNaN(mobile)) {
    //   Errors.push("Mobile number is not valid");
    // }

    // if (category === "") {
    //   Errors.push("Please Fill Category");
    // }

    // if (description === "") {
    //   Errors.push("Please Fill Description");
    // }

    // if (Errors.length !== 0) {
    //   setErr(Errors);
    //   return;
    // }
    // // alert(Errors)
    // Errors.push("Message Sent Successful");
    // setSuccess(Errors);
    fetch(addContact_query_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(Add_Contact_OBJ),
    }).then((result) => {
      result.json().then((res) => {
        setSendMessage(true);
      });
    });
  };

  // const renderError = () => {
  //   return (
  //     <Alert severity={success.length !== 0 ? "success" : "error"}>
  //       {err.map((e) => {
  //         return <h5>{e}</h5>;
  //       })}
  //     </Alert>
  //   );
  // };
  // addContact_query end
  return (
    <div className="home-main-container">
      {/* <Modal
        size="md"
        isOpen={sendMessage}
        toggle={() => setSendMessage(!sendMessage)}
      > */}
      {/* <ModalHeader toggle={()=>setPopup(!popup)}>
                PopUp
            </ModalHeader> */}
      {/* <ModalBody>
          <form action="">
            <Row>
              <Col lg={12}>
                <div className="err-icn">
                  {success.length !== 0 ? (
                    <CheckCircleIcon className="icn-icn" />
                  ) : (
                    <ErrorIcon className="icn-icn" />
                  )}
                </div>

              
                <h2 className="login-err">Success Alert</h2>
                <h6 className="saveup-cen">messege send successfully</h6>
                {err.length !== 0 ? renderError() : null}

                
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal> */}
      {/* Slider & Search Section */}
      <section className="main h-col1">
        <div className="flex-h h-pad container topContainer">
          <div className="row main-header-container">
            <div className="text col-lg-7 col-md-7">
              <h2 className="premium">Premium Management</h2>
              <h2 className="jobs">Jobs for the best in business!</h2>
              <p className="quest">
                {" "}
                Your quest for premium management jobs ends here! <br />{" "}
                Register free and escape the crowd of ordinary applicants to
                find jobs suited to your calibre on PHC within minutes.
              </p>

              <form className="search-bar-h" action="">
                <AllInboxIcon className="btn-serch-icn" />
                <input
                  className="no-outline"
                  type="text"
                  placeholder="Keyword"
                  style={{ borderRight: "2px solid rgba(0,0,0,.1)" }}
                  onChange={(e) => {
                    setQuery({ type: 3, value: e.target.value });
                  }}
                />{" "}
                <hr />
                <LocationOnOutlinedIcon className="btn-serch-icn" />
                <input
                  className="no-outline"
                  type="text"
                  placeholder="Location"
                  style={{ borderRight: "2px solid rgba(0,0,0,.1)" }}
                  onChange={(e) => {
                    setQuery({ type: 2, value: e.target.value });
                  }}
                />{" "}
                <hr />
                <ManageSearchIcon className="btn-serch-icn" />
                <select
                  name=""
                  id=""
                  style={{ borderRight: "2px solid rgba(0,0,0,.1)" }}
                  className="no-outline"
                  onChange={(e) => {
                    setQuery({ type: 1, value: e.target.value });
                  }}
                >
                  <option disabled selected>
                    Select the MBA Domain
                  </option>
                  {getRole.map((value) => {
                    return <option value={value.uid}>{value.role}</option>;
                  })}

                  {/* <option value="">Finance</option> */}
                </select>
                {/* <button type='submit'><SearchIcon/> &nbsp; Search</button>  */}
                <Button
                  onClick={() => {
                    renderJobSearch();
                  }}
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>
              </form>
            </div>
            <div className="photo col-lg-5 col-md-5 ">
              <img className="h-imgg img1" src={top20} alt="topimg6" />
              {/* <img className="h-imgg img2" src={top21} alt="" /> */}
            </div>

            {/* corection */}
            {/* <div>
              <p>Popular Searches: <span style={{ "text-decoration": "underline", "fontSize": "15px" }}>Designer, Web, IOS, Developer. PHP, Engineer</span></p>
            </div> */}
            {/* corection */}
          </div>
        </div>
      </section>

      {/* Why choose us */}
      <section className="why-choose-us globalPadding">
        <div className="container nn">
          <h2 className="mp-h mb-4">Why choose us?</h2>
          {/* <p className='mp-p'> Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum
                  suspendisse ultrices gravida. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
                  lacus vel facilisis.
              </p> */}

          <div className="row mt-5 why">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
              <div className="card">
                <div className="img-Card mb-3">
                  <img className="img-new" src={topimg1}></img>
                </div>
                <h4 className="dark-head">
                  Membership limited to the alumni of top business schools in
                  India
                </h4>
                <p>
                  PHC is open only to MBA graduates from the best B- schools of
                  India making it a hub of best talent in the market.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
              <div class="card">
                <div className="img-Card mb-3">
                  <img className="img-new" src={topimg2}></img>
                </div>
                <h4 className="dark-head">
                  {" "}
                  Access to a pool of premium job opportunities
                </h4>
                <br></br>
                <p>
                  PHC offers job seekers high end management positions, open
                  only for the top notch management professionals of India.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
              <div class="card">
                <div className="img-Card mb-3">
                  <img className="img-new" src={topimg3}></img>
                </div>
                <h4 className="dark-head">Auto resume generator</h4>
                <br></br>
                <br></br>
                <p>
                  PHC is equipped with a powerful resume builder that helps
                  users create impressive resumes quickly and easily.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
              <div class="card">
                <div className="img-Card mb-3">
                  <img className="img-new" src={topimg4}></img>
                </div>
                <h4 className="dark-head">
                  {" "}
                  Fast and efficient hiring process
                </h4>
                <br></br>
                <p>
                  PHC ensures quick closure of job openings with real time
                  updates through seamless communication with the employers
                  {/* time updates to applicant. */}
                </p>
              </div>
            </div>
          </div>
          <center className="mt-4">
            <button onClick={RegisterNOW} className="register-now-btn">
              Register Now
            </button>
          </center>
        </div>
      </section>
      {/* College Lists */}
      <section className="Premium-Membership globalPadding">
        <div className="container">
          <h2>
            <span>We accept membership only from alumni of below colleges</span>{" "}
          </h2>
          <div className="pm-flex">
            <Carousel
              // customTransition={true}
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              //   autoPlay={this.props.deviceType !== "mobile" ? true : false}
              autoPlay={1000}
              keyBoardControl={true}
              customTransition="transform 300ms ease-in-out"
              transitionDuration={600}
              containerClass="carousel-container"
              // removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-10-px bb"
              slidesToSlide={true}
            >
              {info.map((value, i) => {
                return (
                  <div className="college-carousel-container">
                    <img
                      src={value.college_logo}
                      className="college-carousel"
                      style={{ backgroundColor: "none" }}
                    />
                    <h6 className="c-name">{value.college_name}</h6>
                    <h5 className="c-location"> {value.college_location}</h5>
                  </div>
                );
              })}
            </Carousel>
          </div>
          <button className="pm-btn" onClick={contactUS}>
            Contact Us
          </button>
        </div>
      </section>
      {/* perks 1 ,2*/}
      <section className="pro-pad globalPadding">
        <div className="Problems container">
          <div style={{ marginTop: "-25px" }} className="p-img cont bba">
            <img src={topimg5} alt="" />
            {/* <div className="rel-pad-p"> */}
            {/* <div className="pp1" style={{ transform: "skew(0deg, 5deg)" }}> */}
            {/* <div className="f-icon-flex">
                  <AccountCircleIcon className="pp1-icon" />
                  <h3>Portal Perk 1</h3>
                </div> */}
            {/* <p>
                  <strong>
                    {" "}
                    PHC offers alumni of top B-schools in India with hand-picked
                    jobs from leading organisations
                  </strong>
                </p> */}
            {/* </div> */}
            {/* <div className="pp2" style={{ transform: "skew(0deg, 5deg)" }}>
                <div className="f-icon-flex">
                  <AccountCircleIcon className="pp2-icon" />
                  <h3>Portal Perk 2</h3>
                </div>
                <p>
                  <strong>
                    {" "}
                    Step-by-step visibility from applying into a job to landing
                    a role in the website
                  </strong>
                </p>
              </div> */}
            {/* </div> */}
          </div>
          <div className="p-textt ttrr mt-4 cont bba">
            <h2>
              <span className="clr ">
                Problems faced by job seekers{" "}
                <span className="problems-job-seeker">on </span> conventional
                platform
              </span>{" "}
            </h2>
            <br></br>
            <p style={{ transform: "skew(0deg, 5deg)" }} className="p_li">
              <div className="lispacing">
                <label>*</label>
                <span>
                  &nbsp;&nbsp; <b>Lack of Niche Specialization</b> :
                  Conventional hiring platforms are often limited in their
                  ability to personalize the job search experience for
                  management professionals{" "}
                </span>
              </div>
              <div className="lispacing">
                <label>*</label>
                <span>
                  &nbsp;&nbsp;<b>Missed Opportunities</b> : Premium high paying
                  job opportunities are often not floated on conventional job
                  platforms
                </span>
              </div>
              <div className="lispacing">
                <label>*</label>
                <span>
                  &nbsp;&nbsp; <b>Lack of Support</b> : Many conventional hiring
                  platforms do not provide the support and correct info to the
                  job seekers{" "}
                </span>
              </div>
              <div className="lispacing">
                <label>*</label>
                <span>
                  &nbsp;&nbsp; <b>Delayed Response</b> : Job seekers have no
                  idea about the status of openings due to mishandling of jobs
                  on conventional platforms
                </span>
              </div>
            </p>
          </div>
        </div>
      </section>

      {/* perks 3,4 */}
      <section className="pro-pad ">
        <div className="Problems container">
          <div className="p-textt ttrr ">
            <div className=" li::marker cont">
              <h2>
                <span className="clr ">
                  Problems faced by employers{" "}
                  <span className="problems-job-employee">on </span>{" "}
                  conventional platforms
                </span>{" "}
              </h2>

              <p style={{ transform: "skew(0deg, 5deg)" }} className="p_li">
                <div className="lispacing">
                  <label>*</label>
                  <span>
                    &nbsp;&nbsp; <b>Lack of Qualified Candidates</b>{" "}
                    :Conventional hiring platforms may not have identified pool
                    of candidates from top B-schools
                  </span>
                </div>
                <div className="lispacing">
                  <label>*</label>
                  <span>
                    &nbsp;&nbsp; <b>High Costs</b> : Using conventional hiring
                    platforms can be expensive for employers, especially for
                    small businesses with limited budgets
                  </span>
                </div>
                <div className="lispacing">
                  <label>*</label>
                  <span>
                    &nbsp;&nbsp; <b>Time-Consuming Process</b> : Posting job
                    openings and sorting through applications can be a long and
                    tedious process
                  </span>
                </div>
                <div className="lispacing">
                  <label>*</label>
                  <span>
                    &nbsp;&nbsp;<b>Lack of Support</b> : Employers get little to
                    no help during the pre onboarding stage on
                    conventional platforms
                  </span>
                </div>
                {/* <div className="main_bt">
                  <div className="bt">
                    <button className="btr">Search Job</button>
                
                    <button className="bty">Learn More</button>
                  </div>
                </div> */}
              </p>
            </div>
          </div>

          {/* <div className="cont"> */}
          <div style={{ marginTop: "-10px" }} className="p-img">
            <img src={top18} alt="" />
          </div>
          <div className="rel-pad-p">
            {/* <div className="pp11" style={{ transform: "skew(0deg, 5deg)" }}>
              <div className="f-icon-flex">
                <AccountCircleIcon className="pp11-icon" />
                <h3>Portal Perk 3</h3>
              </div>
              <p>
                <strong> Automated CV builder for standardized CVs </strong>
              </p>
            </div> */}
            {/* <div className="pp22" style={{ transform: "skew(0deg, 5deg)" }}>
              <div className="f-icon-flex">
                <AccountCircleIcon className="pp2-icon" />
                <h3>Portal Perk 4</h3>
              </div>
              <p>
                {" "}
                <strong>
                  {" "}
                  Indigenous and engaged candidate pool updated regularly to
                  reduce post joining attrition
                </strong>
              </p>
            </div> */}
          </div>
          {/* </div> */}
        </div>
      </section>

      <section className="rh-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="rh">
                Our Recruitment <span>Process</span>
              </h1>
              <img src={MySvgImage} className="img-our-req" />
            </div>
          </div>
        </div>
      </section>
      {/* Jobs of the day  */}
      <section className="day globalPadding">
        <h2>
          Jobs of the Day<span> </span>
        </h2>
        <div className="day-btns">
          {getRole?.map((value, i) => {
            if (i < 5)
              return (
                <Button
                  variant="outlined"
                  // startIcon={<GroupsIcon />}
                  onClick={() => RoleButton(value)}
                  // className="active"
                >
                  {" "}
                  {value.role}
                </Button>
              );
          })}
        </div>
        <div
          className="day-cards-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            // padding: "0px 100px",
            padding: "0px",
          }}
        >
          {job.slice(0, 4).map((value, i) => {
            return (
              <JobCard job={value} print={0} />
              // <div
              //   className="day-card-flex"
              //   key={i}
              //   onClick={() => goToJobSearch(value)}
              //   style={{ flexBasis: "20%" }}
              // >
              //   <div className="day-card-icn">
              //     <LinkedInIcon className="social-icon" />
              //     <div className="card-flex">
              //       <h6>Linked In</h6>
              //       <div className="align">
              //         <p>
              //           <LocationOnOutlinedIcon className="align-icn" />{" "}
              //           {value.job_location}
              //         </p>
              //       </div>
              //     </div>
              //   </div>
              //   <h5>{value.job_title}</h5>
              //   <div className="full-flex">
              //     <p className="full f1">{value.employment_type}</p>
              //     <p className="full">{value.last_modified}</p>
              //   </div>

              //   <p
              //     className="day-para"
              //     dangerouslySetInnerHTML={{
              //       __html:
              //         value.job_description.length > 100
              //           ? `${value.job_description.slice(0, 100)}...`
              //           : value.job_description,
              //     }}
              //   ></p>
              //   <div className="day-btn-flex">
              //     <div dangerouslySetInnerHTML={{ __html: value.req_skills }} />
              //     {/* <button>Figma</button>
              //                     <button>Photoshop</button> */}
              //   </div>

              //   <div className="day-btn-l">
              //     <div className="day-hour">
              //       <h5>{value.job_salary}/Month</h5>
              //     </div>
              //     <button>Apply Now</button>
              //   </div>
              // </div>
            );
          })}
        </div>
      </section>
      <Form />
      {/* <section className="day-cards">
        <div className="Get-in-Touch globalPadding">
          <div className="git-flex container">
            <div className="git-flex1">
              <h1>Get in Touch</h1>
              <form onSubmit={handleSubmit}>
                <div className="name">
                  <input
                    type="text"
                    placeholder="Enter Your Name"
                    className="getInTouchFormField"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {err.name && <span className="error">{err.name}</span>}
                  <br />
                  <select
                    className="getInTouchFormField"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    {err.category && (
                      <span className="error">{err.category}</span>
                    )}
                    <option value="General">General</option>
                    <option value="Job Reference">Job Reference</option>
                    <option value="Recruiter">Recruiter</option>
                  </select>
                </div>

                <div className="name">
                  <input
                    type="text"
                    placeholder="Your Email"
                    className="getInTouchFormField"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {err.email && <span className="error">{err.email}</span>}
                  <input
                    type="text"
                    placeholder="Your Number"
                    className="getInTouchFormField"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                  {err.mobile && <span className="error">{err.mobile}</span>}
                </div>
                <div>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Your Message Description"
                    className="getInTouchFormField"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  {err.description && (
                    <span className="error">{err.description}</span>
                  )}
                </div>
                <div className="git-btn-flex container">
                  {success ? (
                    <p className="success-message">
                      Form submitted successfully!
                    </p>
                  ) : (
                    <button type="submit">Submit</button>
                  )}
                </div>
              </form>
            </div>
            <div className="git-flex2">
              <img
                className="git-flex2-img1"
                src="https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGNvbnRhY3R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                alt=""
              />
              <img
                className="git-flex2-img2"
                src="https://media.istockphoto.com/photos/colleagues-discussing-business-picture-id1319571139?b=1&k=20&m=1319571139&s=170667a&w=0&h=kh-o5VxMCPPeF9bwjQB2qtsZGsmgiXhVX3X9WUEmrCQ="
                alt=""
              />
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};
export default NewHome;
