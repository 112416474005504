import React, { useEffect, useState } from "react";
import "../css/DashBorad.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { saveAs } from "file-saver";
import SideBar from "./SideBar";
import Chart from "react-google-charts";
import ProfileSnapshot from "./Snapshot/ProfileSnapshot";
import ProfileSnapQualification from "./Snapshot/ProfileSnapQualification";
import ProfileSnapExperience from "./Snapshot/ProfileSnapExperience";
import ProfileSnapLicences from "./Snapshot/ProfileSnapLicences";
import ProfileSnapCurricular from "./Snapshot/ProfileSnapCurricular";
import ProfileSnapInternship from "./Snapshot/ProfileSnapInternship";
import ProfileSnapPreference from "./Snapshot/ProfileSnapPreference";
import ProfileSnapCollege from "./Snapshot/ProfileSnapCollege";
import JobCard from "../component/Job_card";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import { redirect, useNavigate } from "react-router-dom";

import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import baseUrlAPI from "./BaseURL";
import { Login } from "@mui/icons-material";

const LineData = [
  ["x", "Applied Jobs", "Saved Jobs"],
  [0, 0, 0],
  [1, 10, 5],
  [2, 23, 15],
  [3, 17, 9],
  [4, 18, 10],
  [5, 9, 5],
  [6, 11, 3],
  [7, 27, 19],
];
const LineChartOptions = {
  hAxis: {
    title: "Time",
  },
  vAxis: {
    title: "Popularity",
  },
  series: {
    1: { curveType: "function" },
  },
};

const profileDisplay = {};

const DashBorad = () => {
  const [profile, setProfile] = useState(null);

  const fetchProfile = () => {
    const GetProfileURL = `${baseUrlAPI}/api/getProfile`;
    fetch(GetProfileURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(),
    });
    console.log(GetProfileURL);
  };
  const uid = localStorage.getItem("uid");

  useEffect(() => {
    if (uid) {
      navigate("/dashboard");
      window.scrollTo(0, 0);
    } else {
      // navigate("/register");
      window.scrollTo(0, 0);
    }
  }, []);

  // const [dashboard, setDashboard] = useState([]);
  // const [login, setLogin] = useState(false);

  const tokenidcheck = localStorage.getItem("candidateToken");
  console.log("tokenidcheck", tokenidcheck);
  useEffect(() => {
    if (!tokenidcheck) {
      // setLogin(true)
      redirect(Login);
    }
  }, []);
  const [progress, setProgress] = React.useState(10);

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) =>
  //       prevProgress >= 100 ? 0 : prevProgress + 10
  //     );
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  const navigate = useNavigate();

  const goToAppliedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/applied-job");
  };
  const getProfile = () => {};

  // const goToMessages = () => {
  //     window.scrollTo(0, 0)
  //     navigate('/messages')
  // }

  const goToMyProfile = () => {
    window.scrollTo(0, 0);
    navigate("/my-profile");
  };

  const goToSavedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/saved-jobs");
  };

  const goToGenerateCV = () => {
    window.scrollTo(0, 0);
    navigate("/generate-cv");
  };

  const goToChangePassword = () => {
    window.scrollTo(0, 0);
    navigate("/change-password");
  };

  const tokenid = localStorage.getItem("candidateToken");

  const candidate_id = localStorage.getItem("uid");

  const applyID = { token: tokenid, candidate_id: candidate_id };

  console.log(candidate_id);

  console.log(applyID);

  const [getApplyJob, setGetApplyJob] = useState([]);

  // const getApplyJobURL = "http://3.109.83.81/phc-admin/api/getApplyJob";

  const getApplyJobURL = `${baseUrlAPI}/api/getApplyJob`;

  useEffect(() => {
    fetch(getApplyJobURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(applyID),
    })
      .then((result) => result.json())

      .then((data) => {
        console.log("result", data);
        setGetApplyJob(data && data.length ? data : []);
        //   localStorage.setItem("appliedJob", data.length)
        if (data.status === 200) {
          alert("success");
        }
      })

      .catch((e) => {
        console.log("e", e);
      });
  }, []);

  console.log(getApplyJob);

  const goToJobSearch = (val) => {
    window.scrollTo(0, 0);

    navigate("/job-search/" + val?.job_id);
  };

  const DownloadCV = async (e) => {
    e.preventDefault();
    // import { saveAs } from "file-saver";
    const candidate_id = localStorage.getItem("uid");

    console.log(candidate_id);
    const DownloadResume = { uid: candidate_id };
    console.log(DownloadResume);

    const DownloadCVURL = `${baseUrlAPI}/api/pdf`;

    fetch(DownloadCVURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(DownloadResume),
    })
      .then((res) => res.blob())
      .then((data) => {
        saveAs(data, "cv.pdf");
      });
  };

  return (
    <>
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 className="dashboard-heading-d">DashBoard</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="Profile-Overview">
        <div className="container">
          <div className="row">
            {/* <SideBar /> */}
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
              <div className="flex-p-overview-m">
                <p className="pro-over-col bol-pro">
                  <ion-icon name="search-circle-outline"></ion-icon> Profile
                  Overview
                </p>

                <p className="bol-pro" onClick={goToAppliedJobs}>
                  <ion-icon name="business-outline"></ion-icon> Applied Jobs
                </p>
                <p className="bol-pro" onClick={goToSavedJobs}>
                  <ion-icon name="bookmark-outline"></ion-icon> Saved Jobs
                </p>
                <p className="bol-pro" onClick={goToMyProfile}>
                  <ion-icon name="person-circle-outline"></ion-icon> My Profile
                </p>
                <p className="bol-pro" onClick={DownloadCV}>
                  <ion-icon name="document-outline"></ion-icon> Generate CV
                </p>
                <p className="bol-pro" onClick={goToChangePassword}>
                  <ion-icon name="lock-closed-outline"></ion-icon> Change
                  Password
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Deactivate
                  Account
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Log Out
                </p>
              </div>
            </div>

            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-">
              <div className="diff-jobs bx-sdw">
                <div>
                  <p>Jobs Applied</p>
                  <h1 className="num-job">
                    {localStorage.getItem("appliedJob")}
                  </h1>
                </div>
                <div className="ver-line"></div>

                <div>
                  <p>Saved Jobs</p>
                  <h1 className="num-job">
                    {localStorage.getItem("savedJob")}
                  </h1>
                </div>
                {/* <div className="ver-line"></div>

                <div>
                  <p>Jobs Offers</p>
                  <h1 className="num-job">0</h1>
                </div> */}
              </div>

              <div className="Recent-Applied-Jobs algn bx-sdw">
                <h4>Recent Applied Jobs</h4>

                <div className="col-job-del-dash">
                  <div className="day-cards">
                    {getApplyJob?.map((val, i) => (
                      <JobCard job={val} />
                    ))}
                  </div>
                </div>
              </div>
              <div className="Profile-Snapshot bx-sdw">
                <div className="algn">
                  <h4>
                    <u>PROFILE SNAPSHOT:</u>
                  </h4>
                </div>

                <br></br>
                <div>
                  <ProfileSnapshot />

                  <div className="profile algn">
                    <h4>QUALIFICATION : </h4>
                  </div>
                  <ProfileSnapQualification />

                  {/* <ProfileSnapCollege /> */}
                  <div className="profile algn">
                    <h4>EXPERIENCE:</h4>
                  </div>
                  <ProfileSnapExperience />
                  <div>
                    <div className="profile algn">
                      <h4> INTERNSHIP,LICENCE AND ACTIVITIES :</h4>
                    </div>
                    <ProfileSnapInternship />
                    <ProfileSnapLicences />
                    <ProfileSnapCurricular />
                  </div>
                  <div className="profile algn">
                    <h4>PREFERENCES : </h4>
                  </div>
                  <div>
                    <ProfileSnapPreference />
                  </div>
                </div>
              </div>

              <div className="Trending-jobs algn  bx-sdw">
                <h4>Trending Jobs</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBorad;
