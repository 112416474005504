import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Verify_Account.css";

import { Modal, ModalBody, Row, Col } from "reactstrap";

import baseUrlAPI from "./BaseURL";

const Verify_Account = () => {
  // const uid = localStorage.getItem("uid");
  // useEffect(() => {
  //   if (uid) {
  //     navigate("/verify-account");
  //     window.scrollTo(0, 0);
  //   } else {
  //     navigate("/register");
  //     window.scrollTo(0, 0);
  //   }
  // }, []);

  const navigate = useNavigate();

  //  post API Input check Otp

  const [otp, setOTP] = useState("");
  const [login, setLogin] = useState(false);

  const goToValidateLoginOTP = () => {
    // const canData = {candidates,token}
    // sessionStorage.setItem("candidateData", canData)
    // localStorage.setItem("token", data?.data?.tokens)
    const mobile = localStorage.getItem("mobile");
    const email = localStorage.getItem("email");

    const myOTP = { otp, mobile };

    const urlLoinOTP = `${baseUrlAPI}/api/checkOtp`;

    fetch(urlLoinOTP, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(myOTP),
    })
      .then((result) => result.json())
      .then((data) => {
        console.log("result", data);
        localStorage.setItem("firstName", data.candidates.first_name);
        localStorage.setItem("lastName", data.candidates.last_name);

        localStorage.setItem("candidateData", data.candidates.mobile);
        localStorage.setItem("candidateToken", data.token);

        localStorage.setItem("uid", data.candidates.uid);

        localStorage.setItem("can_uid", data.candidates.uid);
        navigate("/dashboard");

        console.log("WOrking");
        setLogin(true);
      })

      .catch((e) => {
        console.log("e", e);
      });
  };

  const goToResendOTP = () => {};

  const MemberLogIn = () => {
    // navigate('/member-log-in')
  };

  const goToLoginWithPassword = () => {
    navigate("/login-with-password");
  };
  return (
    <>
      <div className="va-pad">
        <Modal size="sm" isOpen={login} toggle={() => setLogin(!login)}>
          {/* <ModalHeader toggle={()=>setPopup(!popup)}>
            PopUp
          </ModalHeader> */}
          <ModalBody>
            <form action="">
              <Row>
                <Col lg={12}>
                  <h2 className="login-err">Invalid OTP</h2>
                  <h6 className="saveup-cen">Please Enter Valid OTP</h6>

                  <button onClick={MemberLogIn} className="ok_popup">
                    OK
                  </button>
                </Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>
        <div className="Verify-your-Account containe">
          <div className="width-otp">
            <h5 className="vya-reg">Login</h5>
            <h2 className="vya-veri">
              Verify your <span>Account</span>{" "}
            </h2>
            <p className="vya-para-p">
              Please enter the One-Time Password to verify your account A
              One-Time Password has been sent to your registered email
              {localStorage.getItem("email")}
            </p>{" "}
            <br />
            {/* <h2 className='vya-otpp'></h2> <br /> <br /> */}
            <input
              type="text"
              className="in-otp-vc"
              placeholder="-----"
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
            />{" "}
            <br /> <br />
            <button className="validate" onClick={goToValidateLoginOTP}>
              Validate the OTP
            </button>{" "}
            <br />
            <button className="resend" onClick={goToResendOTP}>
              Resend the One-Time Password
            </button>{" "}
            <br />
            <button className="passwordLogin" onClick={goToLoginWithPassword}>
              Login With Password
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verify_Account;
