import React, { useEffect, useState } from "react";
import axios from "axios";
import "../css/MyProfile.css";
import Box from "@mui/material/Box";
import { Alert, AlertTitle } from "@mui/material";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { json, useNavigate } from "react-router-dom";
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import Button from '@mui/material/Button';
import { saveAs } from "file-saver";

// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import { useNavigate } from "react-router-dom";

import baseUrlAPI from "./BaseURL";

const steps = [
  "Basic Info",
  "Education",
  "Work Experience",
  "Others",
  "Preferance",
];

const MyProfilePreference = () => {
  const [updateMessage, setUpdateMessage] = useState("");
  const navigate = useNavigate();
  const uid = localStorage.getItem("uid");
  useEffect(() => {
    if (uid) {
      navigate("/my-profile-preference");
      window.scrollTo(0, 0);
    } else {
      navigate("/register");
      window.scrollTo(0, 0);
    }
  }, []);

  const goToSavedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/saved-jobs");
  };

  const goToAppliedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/applied-job");
  };

  const goToProfileOverview = () => {
    window.scrollTo(0, 0);
    navigate("/dashboard");
  };

  const goToGenerateCV = () => {
    window.scrollTo(0, 0);
    navigate("/generate-cv");
  };

  const goToChangePassword = () => {
    window.scrollTo(0, 0);
    navigate("/change-password");
  };

  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");

  // get Preference Start
  const tokenid = localStorage.getItem("candidateToken");
  const candidate_id = localStorage.getItem("uid");

  const GetPreference = { token: tokenid, candidate_id: candidate_id };

  const GetPreferenceURL = `${baseUrlAPI}/api/getPreference`;
  const [getPreference, setGetPreference] = useState("");

  const [willing_relocate, setWilling_relocate] = useState("");
  const [notice_period, setNotice_period] = useState("");
  const [recruiter_name, setRecruiter_name] = useState("");
  const [recruiter_number, setRecruiter_number] = useState("");
  const [expected_ctc, setExpected_ctc] = useState([0, 0]);
  const [uinqueId, setUid] = useState("");

  const [toggleGoBack, setoggleGoBack] = useState(false);

  const EditCandidateFormGoBack = () => {
    setDisabled(!disabled);
    setoggleGoBack(!toggleGoBack);
  };

  const getData = async () => {
    const { data } = await axios.post(
      `${baseUrlAPI}/api/getPreference`,
      GetPreference
    );
    const dataToSet = data[0];
    console.log({ dataToSet });
    const expectedCtc = JSON.parse(dataToSet.expected_ctc);
    setExpected_ctc([...expectedCtc]);
    setWilling_relocate(dataToSet.willing_relocate);
    setNotice_period(dataToSet.notice_period);
    setRecruiter_number(dataToSet.recruiter_number);
    setRecruiter_name(dataToSet.recruiter_name);
    setUid(dataToSet.uid);
  };
  useEffect(() => {
    getData();
  }, []);
  const updatePreference = async () => {
    try {
      const body = {
        expected_ctc: JSON.stringify(expected_ctc),
        willing_relocate,
        notice_period,
        recruiter_name: recruiter_name !== "" ? recruiter_name : "NA",
        recruiter_number: recruiter_number !== "" ? recruiter_number : "0",
        candidate_id,
        token: tokenid,
        uid: uinqueId,
      };
      const { data } = await axios.post(
        `${baseUrlAPI}/api/updatePreference`,
        body
      );
      setUpdateMessage("Preference  has been updated !");

      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);

      console.log({ data });
    } catch (e) {
      navigate("/dashboard");
    }
  };

  const [disabled, setDisabled] = useState(false);
  const [toggle, setToggle] = useState(false);

  const EditCandidateForm = () => {
    setDisabled(!disabled);
    setToggle(!toggle);
  };

  const Position = localStorage.getItem("position");

  const DownloadCV = async (e) => {
    e.preventDefault();
    // import { saveAs } from "file-saver";
    const candidate_id = localStorage.getItem("uid");

    const DownloadResume = { uid: candidate_id };

    const DownloadCVURL = `${baseUrlAPI}/api/pdf`;

    fetch(DownloadCVURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(DownloadResume),
    })
      .then((res) => res.blob())
      .then((data) => {
        saveAs(data, "cv.pdf");
      });
  };

  return (
    <>
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 className="dashboard-heading-d">My Profile</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="Profile-Overview">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
              <div className="flex-p-overview-my-pro">
                <p className="bol-pro" onClick={goToProfileOverview}>
                  <ion-icon name="search-circle-outline"></ion-icon> Profile
                  Overview
                </p>
                <p className="bol-pro" onClick={goToAppliedJobs}>
                  <ion-icon name="business-outline"></ion-icon> Applied Jobs
                </p>
                <p className="bol-pro" onClick={goToSavedJobs}>
                  <ion-icon name="bookmark-outline"></ion-icon> Saved Jobs
                </p>
                <p className="pro-over-col bol-pro">
                  <ion-icon name="person-circle-outline"></ion-icon> My Profile
                </p>
                {/* <p className='bol-pro' onClick={goToMessages}><ion-icon name="chatbox-ellipses-outline"></ion-icon> Messages</p> */}
                <p className="bol-pro" onClick={DownloadCV}>
                  <ion-icon name="document-outline"></ion-icon> Generate CV
                </p>
                <p className="bol-pro" onClick={goToChangePassword}>
                  <ion-icon name="lock-closed-outline"></ion-icon> Change
                  Password
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Deactivate
                  Account
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Log Out
                </p>
              </div>
            </div>

            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
              <div className="pad-mar-my-proo">
                <div className="my-pro-pad-mar">
                  {/* <img
                    className="g-i-avtar"
                    src="https://media.istockphoto.com/id/1288732130/photo/portrait-of-cheerful-mixed-race-teenage-girl.jpg?b=1&s=170667a&w=0&k=20&c=R3BwP5rv0nG441whzRmAaLXCs9OZMdZHKOfUw0oj4sQ="
                    alt="girl"
                  /> */}
                  <div>
                    <h3>
                      {firstName} {lastName}
                    </h3>
                    {/* <h4>{firstName} {lastName}</h4> */}
                    <p>
                      {Position} &nbsp; &nbsp;{" "}
                      {/* <ion-icon name="create-outline"></ion-icon> */}
                    </p>
                  </div>
                </div>

                <div className="box-pad-mar-k">
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={4} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </div>
                {/* {renderBasicInfo()} */}

                <div className="r-form-first">
                  <div className="edi-form">
                    {updateMessage && (
                      <Alert
                        onClose={() => {
                          setUpdateMessage("");
                        }}
                        severity="success"
                      >
                        {updateMessage}
                      </Alert>
                    )}
                    <span
                      className="edit_form"
                      style={{
                        backgroundColor: toggle ? "#68174a" : "#ef4625",
                      }}
                      onClick={EditCandidateForm}
                    >
                      Edit Form
                    </span>

                    <span
                      className="edit_form"
                      style={{
                        backgroundColor: toggleGoBack ? "#68174a" : "#ef4625",
                      }}
                      onClick={() => navigate("/my-profile-others")}
                      onChange={EditCandidateFormGoBack}
                    >
                      Go Back
                    </span>
                  </div>
                  <div className="container">
                    <form action="">
                      <div className="row">
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ecpe-k-select"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label htmlFor="">
                            Expected Fixed CTC Range From :{" "}
                            {expected_ctc
                              ? `${expected_ctc[0] / 100000} to ${
                                  expected_ctc[1] / 100000
                                }`
                              : null}{" "}
                          </label>{" "}
                          <RangeSlider
                            isVisible={true}
                            onInput={(e) => {
                              const values = e.map((e) => {
                                return e * 100000;
                              });
                              setExpected_ctc(values);
                            }}
                            defaultValue={expected_ctc}
                            min={12}
                            max={200}
                            step={1}
                            label={true}
                            disabled={!disabled}
                          />
                        </div>
                      </div>

                      <h5 className="please-note-four">Please note: </h5>
                      <p>
                        Your negotiation with the employer will be fixed between
                        this range so select carefully.More precise your
                        expected CTC range, higher chance of getting
                        shortlisted. You can change your expected CTC while
                        applying to different jobs as per your preference .{" "}
                        <br /> <br />
                        Fixed CTC range does not include variables, incentives,
                        joining and year-end bonuses etc and you are free to
                        negotiate on these over your Fixed CTC with the
                        employer.
                      </p>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 cl-yes-no">
                          <label htmlFor="">Willing To relocate *</label> <br />
                          <select
                            name=""
                            id=""
                            style={{
                              border: toggle ? "1px solid gray" : "none",
                              backgroundColor: "white",
                              appearance: toggle ? "auto" : "none",
                            }}
                            disabled={!disabled}
                            onChange={(e) =>
                              setWilling_relocate(e.target.value)
                            }
                          >
                            <option disabled selected>
                              {willing_relocate}
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 cl-yes-no">
                          <label htmlFor="">Notice Period Negotiable *</label>{" "}
                          <br />
                          <select
                            name=""
                            id=""
                            style={{
                              border: toggle ? "1px solid gray" : "none",
                              backgroundColor: "white",
                              appearance: toggle ? "auto" : "none",
                            }}
                            disabled={!disabled}
                            onChange={(e) => setNotice_period(e.target.value)}
                          >
                            <option disabled selected>
                              {notice_period}
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                      <p>
                        Please share the contact of a hiring team member in your
                        organization. It is not mandatory but will help us fetch
                        more jobs on our portal which will benefit the rest of
                        the members. Your identity or source of contact will
                        never be mentioned to the recruiter you are listing.
                      </p>

                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-num-phonn">
                          <label htmlFor="">Name of the recruiter: </label>{" "}
                          {/* <p>
                            (This contact will help us fetch more jobs on our
                            portal which will benefit everyone, Your identity or
                            source of the contact will never be mentioned to the
                            recruiter you are listing)
                          </p> */}
                          <br />
                          <input
                            type="text"
                            disabled={!disabled}
                            style={{
                              border: toggle ? "1px solid gray" : "none",
                              backgroundColor: "white",
                            }}
                            placeholder="Name of the recruiter"
                            defaultValue={recruiter_name}
                            onChange={(e) => setRecruiter_name(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-num-phon">
                          <label htmlFor="">
                            Any one Recruiter’s Contact number in your
                            organization:
                          </label>{" "}
                          <br />
                          <input
                            type="number"
                            placeholder="Any one Recruiter’s Contact number in your organization"
                            defaultValue={recruiter_number}
                            // value={recruiter_number}
                            onChange={(e) =>
                              setRecruiter_number(e.target.value)
                            }
                            disabled={!disabled}
                            style={{
                              border: toggle ? "1px solid gray" : "none",
                              backgroundColor: "white",
                            }}
                          />
                        </div>
                      </div>
                    </form>
                    <div className="r-btn-next">
                      <button onClick={updatePreference}>Update Profile</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfilePreference;
