import React, { useState } from "react";
import "../css/Contact_Us.css";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Form from "./Form.js";

import baseUrlAPI from "./BaseURL";
import { useNavigate } from "react-router-dom";

const Contact_Us = () => {
  // api post contact us

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [category, setCategory] = useState("General");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const sendMessage = (e) => {
    e.preventDefault();
    if (name === "") {
      setError(" Please Fill Name");
      return;
    } else if (email === "") {
      setError("Please Fill Email");
      return;
    } else if (
      !email
        .toLocaleLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setError("Email is not valid");
      return;
    } else if (mobile === "") {
      setError("Please Fill Mobile Number");
      return;
    } else if (mobile.length !== 10) {
      setError("Please Enter 10-digit Mobile Number");
      return;
    } else if (isNaN(mobile)) {
      setError("Mobile number is not valid");
      return;
    } else if (category === "") {
      setError("Please Fill Category");
      return;
    } else if (description === "") {
      setError("Please Fill Description");
      return;
    } else {
      setError("");
      setSuccess(true);
    }

    const tokenid = localStorage.getItem("candidateToken");

    const contactUS = {
      name,
      email,
      mobile,
      category,
      token: tokenid,
      description,
    };
    console.log(contactUS);

    const addContact_queryURL = `${baseUrlAPI}/api/addContact_query`;

    fetch(addContact_queryURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(contactUS),
    })
      .then((result) => result.json())
      .then((data) => {
        console.log("result", data);
        if (data.status === 200) {
          alert("success");
        }
      })

      .catch((e) => {
        console.log("e", e);
      });
  };

  const navigate = useNavigate();

  const searchJOBS = () => {
    navigate("/job");
  };
  return (
    <>
      <div style={{ background: "#fbcccc" }}>
        <div className="Contact-Us">
          <h1 className="cont cc">Contact Us</h1>
        </div>

        {/* We Are Here To Help */}

        <div className="wah-head skew globalPadding">
          <div className="We-Are-Here-To-Help container">
            <div className="row">
              <div className="wahth-img col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <img
                  src="https://media.istockphoto.com/photos/photo-of-candidates-waiting-for-a-job-interview-picture-id1137038796?b=1&k=20&m=1137038796&s=170667a&w=0&h=Fb47hwsSnsd9z71cKAeucZxMX18Za7tBb2Qla1_FHjM="
                  alt=""
                />
              </div>
              <div className="wahth-text col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                <h1 className="wahth-text-heading">We Are Here To Help</h1>{" "}
                <br />
                <p className="wahth-text-para">
                  Premium Hiring Company is the only job portal to look for a
                  new job if you are an elite management professional. Stay
                  ahead of the mediocre crowd by using our services available to
                  the best in class management professionals. <br /> <br />
                  Premium Hiring Company provides access to exclusive job
                  opportunities that may not be advertised elsewhere. We can
                  also offer guidance and support throughout the job search
                  process, from writing a stand-out resume to preparing for
                  interviews. <br /> <br />
                  For recruiters, Premium Hiring Company can help to source
                  high-quality candidates for open positions, saving time and
                  ensuring that only the best candidates are considered for the
                  role. In addition, we can provide valuable insights into the
                  current job market and trends, helping to ensure that your
                  company is always ahead of the curve.
                </p>{" "}
                <br /> <br />
                <div className="wahth-btn">
                  <button className="wahth-btn-search" onClick={searchJOBS}>
                    Search Jobs
                  </button>
                  <button className="wahth-btn-learn">Learn More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Form />
      </div>
    </>
  );
};

export default Contact_Us;
