import React, { useEffect, useState } from "react";
import "../css/Register.css";
import { LinkedInLogin } from "react-linkedin-login-oauth2";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { json, useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import SimpleReactValidator from "simple-react-validator";
import baseUrlAPI from "./BaseURL";
import { Modal, ModalBody, Row, Col } from "reactstrap";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import axios from "axios";

const steps = [
  "Basic Info",
  "Education",
  "Work Experience",
  "Others",
  "Preference",
];

const Register = () => {
  const [isback, setIsBack] = useState(false);
  const LinkedInButton = ({ handleLogin }) => {
    const onSuccess = (response) => {
      handleLogin(response);
    };

    const onFailure = (error) => {};
  };
  // get college

  const [college, setCollege] = useState([]);

  const [collegeId, setCollegeId] = useState("");

  const [course, setCourse] = useState([]);

  const [findUID, setFindUID] = useState([]);

  // useEffect(() => {
  //   fetch(collegeURL).then((result) => {
  //     result.json().then((res) => {
  //       setFindUID(res.data[0].uid);
  //       setCollege(res.data);
  //     });
  //   });
  // }, [collegeURL]);
  useEffect(() => {
    const collegeURL = `${baseUrlAPI}/api/colleges`;
    fetch(collegeURL).then((result) => {
      result.json().then((res) => {
        setCollege(res.data);
      });
    });
  }, []);

  const handleCollege = (event) => {
    const getCollegeId = event.target.value;
    setCollegeId(getCollegeId);
  };

  // const courseURL =  collegeId ? "http://3.109.83.81/phc-admin/api/course?college_id=" + collegeId : "http://3.109.83.81/phc-admin/api/course?college_id=63aa7d3c3cab8";

  // const courseURL =  "http://3.109.83.81/phc-admin/api/course?college_id=20";

  useEffect(() => {
    if (collegeId === "") {
      return;
    }
    const courseURL = `${baseUrlAPI}/api/course?college_id=${collegeId}`;
    fetch(courseURL).then((result) => {
      result.json().then((res) => {
        if (!Array.isArray(res)) {
          setCourse([]);
          return;
        } else {
          const temp = [...res];
          setCourse(temp);
          setMba_specialization(temp[0].course_name);
        }
      });
    });
  }, [collegeId]);

  // api post
  const [error, setError] = useState([]);
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("Male");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [current_location, setCurrent_location] = useState("");
  const [linkedIn_profile_link, setLinkedIn_profile_link] = useState("");
  const [validationError, setValidationError] = useState(null);
  const [b_school_name, setB_school_name] = useState("");
  const [mba_specialization, setMba_specialization] = useState("");
  const [occupations, setOccupations] = useState("");
  const [collegeDoesNotExist, setCollegeDoesNotExist] = useState(false);
  const [basicPop, setBasicPop] = useState(false);
  const navigate = useNavigate();
  // const LinkedInLogin = async (data) => {
  //   try {
  //     const { access_token } = data;
  //     const profile = await fetch("https://api.linkedin.com/v2/userinfo", {
  //       headers: {
  //         Authorization: `Bearer ${access_token}`,
  //         "cache-control": "no-cache",
  //         "X-Restli-Protocol-Version": "2.0.0",
  //       },
  //     });

  //     const userData = {
  //       firstName: profile.firstName.localized.en_US,
  //       lastName: profile.lastName.localized.en_US,
  //       email: profile.emailAddress,
  //       linkedinId: profile.id,
  //       accessToken: access_token,
  //     };

  //     // send userData and accessToken to your server for registration
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // return (
  //   <LinkedInLogin
  //     clientId="Y77tv1d3cdsi4vn"
  //     redirectUri="https://www.linkedin.com/developers/tools/oauth/redirect"
  //     onSuccess={LinkedInLogin}
  //     renderElement={({ onClick, disabled }) => (
  //       <button onClick={onClick} disabled={disabled}>
  //         Sign in with LinkedIn
  //       </button>
  //     )}
  //   />
  // );
  const validator = () => {
    const errors = [];
    if (first_name === "") {
      errors.push("First name is required");

      // navigate("/register");
    }
    if (occupations === "") {
      errors.push("Please select the occupation");
    }
    if (last_name === "") {
      errors.push("Last name is required");
      // navigate("/register");
    }
    if (mobile === "") {
      errors.push("Mobile number is required");
      // navigate("/register");
    }
    if (gender === "") {
      errors.push("Gender is  required");
    }
    if (email === "") {
      errors.push("Email is required");
      // navigate("/register");
    }
    if (password === "") {
      errors.push("Password is required");
      // navigate("/register");
    }
    if (current_location === "") {
      errors.push("Location is required");
    }

    if (b_school_name === "" && !collegeDoesNotExist) {
      errors.push("College name is required");
    }
    if (mba_specialization === "") {
      errors.push("Mba specialization is required");
    }
    if (dob === "") {
      errors.push("Date of birth is required");
    }
    if (linkedIn_profile_link === "") {
      errors.push("LinkedIn profile is required");
    }

    if (password !== cpassword) {
      // alert("password not match");
      errors.push("Passwords dont match");
    }
    if (
      !linkedIn_profile_link.match(
        /(^((https?:\/\/)?((www|\w\w)\.)?)linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/gim
      )
    ) {
      errors.push("Linkden profile is not valid");
    }
    if (mobile.length != 10) {
      errors.push("Mobile number is not valid");
    }

    if (
      !email
        .toLocaleLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      errors.push("Email is not valid");
    }
    if (errors.length != 0) {
      setError(errors);
      window.scrollTo(0, 0);
      return false;
    } else {
      return true;
    }
  };

  const goToEducation = async (e) => {
    e.preventDefault();
    console.log("ADDING DETAILS....");
    const isDataValid = validator();
    if (!isDataValid) {
      return;
    }
    let conData = {
      first_name,
      last_name,
      mobile,
      email,
      password,
      dob,
      gender,
      current_location,
      b_school_name,
      mba_specialization,
      linkedIn_profile_link,
      occupations,
    };

    localStorage.setItem("firstName", first_name);
    localStorage.setItem("lastName", last_name);
    localStorage.setItem("password", password);

    const registrationURL = `${baseUrlAPI}/api/registration`;
    try {
      const { data } = await axios.post(registrationURL, conData);
      localStorage.setItem("candidateToken", data?.data?.tokens);
      localStorage.setItem("uid", data?.data?.uid);
      navigate("/Welcome");
      window.scrollTo(0, 0);
    } catch (e) {
      // setError(["Email or phone number is already in use"]);
      if (e.response.data.data.mobile) {
        setError(e.response.data.data.mobile);
      }
      if (e.response.data.data.email) {
        setError(e.response.data.data.email);
      }
      window.scrollTo(0, 0);
    }
  };

  const SignInNow = () => {
    navigate("/member-log-in");
  };
  const renderErrors = () => {
    return (
      <Alert severity="error">
        {/* <AlertTitle>Error</AlertTitle> */}
        {error.map((e) => {
          return (
            <div>
              <strong>{e}</strong>
              <br />
            </div>
          );
        })}
      </Alert>
    );
  };
  //Create Get data function with local storage token and uid
  //If object is not empty then set the local state
  //
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const candidateToken = localStorage.getItem("candidateToken");

    const id = localStorage.getItem("uid");
    const { data } = await axios.post(`${baseUrlAPI}/api/getProfile`, {
      id,
      token: candidateToken,
    });
    const existingData = data[0];
    if (!existingData || data.length === 0) {
      return;
    } else {
      setIsBack(true);
    }
    console.log({ existingData }, "Dfdfdf");
    setFirst_name(existingData.first_name);
    setLast_name(existingData.last_name);
    setDOB(existingData.dob);
    setGender(existingData.gender);
    setEmail(existingData.email);
    setMobile(existingData.mobile);
    setCurrent_location(existingData.current_location);
    setLinkedIn_profile_link(existingData[" linkedIn_profile_link"]);
    setB_school_name(existingData.b_school_name);
    setMba_specialization(existingData.mba_specialization);
  };

  //update Registration
  const updateData = async () => {
    console.log("Updating DETAILS....");

    const isDataValid = validator();
    if (!isDataValid) {
      return;
    }
    const candidateToken = localStorage.getItem("candidateToken");
    const id = localStorage.getItem("uid");
    const updateObj = {
      first_name,
      last_name,
      token: candidateToken,
      uid: id,
      mobile,
      email,
      password,
      dob,
      gender,
      current_location,
      b_school_name,
      mba_specialization,
      linkedIn_profile_link: linkedIn_profile_link.trim(),
      occupations,
    };

    try {
      const { data } = await axios.post(
        `${baseUrlAPI}/api/updateCandidate`,
        updateObj
      );
      navigate("/education");
    } catch (e) {}
  };

  //============== User Info ==============
  // const userData = JSON.parse(localStorage.getItem("usrInfo"));
  // console.log(userData, "bbbbb");
  return (
    <>
      <div className="Free-Today">
        {/* Will add the error messages herer */}

        <div className="container">
          <div className="cen-start-cen">
            <h5 className="reg-to">Register</h5>
            <h1 className="reg-start">
              Welcome to <span>PHC</span>{" "}
            </h1>
            <p className="reg-para">
              The registration process will take approximately 10 minutes. Once
              registered, an automated CV will be generated for you and you can
              avail all the services of our membership. You can transfer data
              from your existing CV as per your convenience{" "}
            </p>
          </div>

          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={0} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>

        <div
          className="r-form-first"
          style={{
            boxShadow: "0px 0px 40px rgba(0,0,0,.05)",
          }}
        >
          <div className="container">
            {error.length != 0 ? renderErrors() : null}
            <form action="" className="r-form-first-flex">
              <div className="flex-left">
                <div className="f-l-name">
                  <div className="f-name">
                    <label htmlFor="">First Name *</label> <br />
                    <input
                      type="text"
                      placeholder="First Name"
                      value={first_name}
                      onChange={(e) => setFirst_name(e.target.value)}
                      className={"input"}
                    />
                  </div>
                  <div className="l-name">
                    <label htmlFor="">Last Name *</label> <br />
                    <input
                      type="text"
                      placeholder="Last Name"
                      value={last_name}
                      onChange={(e) => setLast_name(e.target.value)}
                      className={"input"}
                    />
                  </div>
                </div>

                <div className="r-f-email">
                  <label htmlFor="">Email ID*</label> <br />
                  <input
                    type="text"
                    placeholder="Email Here"
                    className={"input"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="r-f-pass">
                  <label htmlFor="">Password *</label> <br />
                  <input
                    type="password"
                    placeholder="**********"
                    className={"input"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div className="r-f-pass">
                  <label htmlFor="">Current Location City *</label> <br />
                  <input
                    className={"input"}
                    type="text"
                    placeholder="Enter City Name"
                    value={current_location}
                    onChange={(e) => setCurrent_location(e.target.value)}
                  />
                </div>

                <div className="r-f-school">
                  <label htmlFor="">B - School Name *</label> <br />
                  {/* <input type="text" placeholder='B - School Name' /> */}
                  <select
                    style={{
                      // height: "60px",
                      padding: "0px 2px",
                      // fontSize: "16px",
                      border: "border: 1px solid rgb(209, 209, 209) ",
                    }}
                    className={"input"}
                    name="college_name"
                    id="college_name"
                    value={b_school_name}
                    onChange={(e) => {
                      handleCollege(e);
                      setB_school_name(e.target.value);
                    }}
                    placeholder="Select your college"
                  >
                    {/* <option value="">College does not exist</option> */}
                    {college.map((val) => {
                      return (
                        <option key={val.uid} value={val.uid}>
                          {val.college_name}
                        </option>
                      );
                    })}
                    <option value="" disabled>
                      {" "}
                      Select your college
                    </option>
                  </select>
                  {/* <FormControlLabel
                    control={<Checkbox />}
                    label="My college is not listed here"
                    className="check-label"
                    onChange={() => {
                      setCollegeDoesNotExist(!collegeDoesNotExist);
                    }}
                  /> */}
                </div>
              </div>

              <div className="flex-right">
                <div className="dob-gen">
                  <div className="dob">
                    <label htmlFor="">DOB *</label> <br />
                    <input
                      className={"input"}
                      type="date"
                      value={dob}
                      onChange={(e) => setDOB(e.target.value)}
                    />
                  </div>
                  <div className="gen">
                    <label htmlFor="">Gender *</label> <br />
                    <select
                      name=""
                      style={{
                        // height: "60px",
                        padding: "0px 2px",
                        // fontSize: "16px",
                        border: "border: 1px solid rgb(209, 209, 209) ",
                      }}
                      id=""
                      className={"input"}
                      defaultValue={gender}
                      onChange={(e) => {
                        setGender(e.target.value);
                      }}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="phone">
                  <label htmlFor="">Phone*</label> <br />
                  <input
                    className={"input"}
                    type="number"
                    placeholder="Phone"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>

                <div className="phone">
                  <label htmlFor="">Confirm Password *</label> <br />
                  <input
                    type="password"
                    placeholder="**********"
                    className={"input"}
                    value={cpassword}
                    onChange={(e) => setCpassword(e.target.value)}
                  />
                </div>

                <div className="phone">
                  <label htmlFor="">LinkedIn *</label> <br />
                  <input
                    type="text"
                    placeholder="LinkedIn Profile Link"
                    value={linkedIn_profile_link}
                    className={"input"}
                    onChange={(e) => setLinkedIn_profile_link(e.target.value)}
                  />
                </div>

                <div className="r-f-school-m">
                  <label htmlFor="">MBA Specialization *</label> <br />
                  {/* <input type="text" placeholder='MBA Specialization'  /> */}
                  <select
                    style={{
                      // height: "60px",
                      padding: "0px 2px",
                      // fontSize: "16px",
                      border: "border: 1px solid rgb(209, 209, 209) ",
                    }}
                    name="mba"
                    id="mba"
                    value={mba_specialization}
                    className={"input"}
                    onChange={(e) => setMba_specialization(e.target.value)}
                  >
                    {course.length !== 0 ? (
                      course.map((value, i) => {
                        return (
                          <option
                            multiple={true}
                            key={i}
                            value={value.course_name}
                          >
                            {value.course_name}
                          </option>
                        );
                      })
                    ) : (
                      <div>not found</div>
                    )}
                  </select>
                </div>
              </div>
            </form>
            {collegeDoesNotExist && (
              <form action="" className="r-form-first-flex">
                <div className="Upload__file">
                  <label htmlFor="">Upload Resume</label> <br />
                  <input type="file" className="upload__input" />
                </div>
              </form>
            )}

            <div>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="med-gg"
                  value={occupations}
                  onChange={(e) => setOccupations(e.target.value)}
                >
                  <FormLabel
                    className="tick-mark-reg"
                    id="demo-row-radio-buttons-group-label"
                  >
                    Occupational Status *
                  </FormLabel>{" "}
                  &nbsp; &nbsp;
                  <FormControlLabel
                    value="Campus-Student"
                    control={<Radio />}
                    label="Campus Student"
                  />
                  <FormControlLabel
                    value="Professional"
                    control={<Radio />}
                    label="Professional"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            {/* <div className="ml-fp-r">
              <FormControlLabel
                control={<Checkbox />}
                label="Agree our terms and policy"
                className="check-label"
              />
              <button className="btn-label">Learn More</button>
            </div> */}

            <div className="r-btn-next">
              <button
                onClick={(e) => {
                  console.log(isback);
                  if (!isback) {
                    console.log("edu");
                    goToEducation(e);
                  } else {
                    console.log("update");
                    updateData();
                  }
                }}
              >
                Proceed To Next Step
              </button>
            </div>
            <div className="reg1-sign">
              <p>
                Already have an account?{" "}
                <span onClick={SignInNow}>Sign In Now.</span>
              </p>
            </div>

            {/* <div className="Or-continue-with">
              <p>Or continue with</p>
            </div> */}

            {/* <div className="btn-link-sign">
              <Button
                className="btn-comp-nb-r"
                variant="outlined"
                startIcon={<LinkedInIcon className="link-icnn" />}
              >
                Sign Up with Linkedin
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
