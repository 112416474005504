import React, { useState } from "react";
import logo from "../images/logophc.png";
import { NavLink, useNavigate } from "react-router-dom";
import "../css/List.css";

import DehazeIcon from "@mui/icons-material/Dehaze";

const List = () => {
  const navigate = useNavigate();

  const goToLogIn = () => {
    navigate("/member-log-in");
  };
  const goToRegister = () => {
    navigate("/register");
  };

  const candidateInfo = localStorage.getItem("candidateData");
  console.log(candidateInfo);
  const candidateTokens = localStorage.getItem("candidateToken");
  console.log(candidateTokens);

  const Logout = () => {
    localStorage.clear();
    navigate("/member-log-in");
  };

  const goToDashboard = () => {
    if (localStorage.getItem("candidateToken")) {
      navigate("/dashboard");
    } else {
      navigate("/member-log-in");
    }
  };

  const goToHome = () => {
    navigate("/");
  };

  const [hamburger, setHamburger] = useState(false);

  const uid = localStorage.getItem("uid");
  // const noticeBoard = () => {
  //     if (uid) {
  //         navigate("/notice-board");
  //         window.scrollTo(0, 0);
  //       } else {
  //         navigate("/register");
  //         window.scrollTo(0, 0);
  //       }
  // }
  return (
    <>
      <header>
        <div className="container containe container-flex">
          <div className="logoContainer">
            <img src={logo} alt="logo" className="logo" onClick={goToHome} />
            {/* <h3 className='h3-logo-here'>Logo Here</h3> */}
          </div>

          <nav>
            <div
              className={hamburger ? "nav-links-mobile" : "list "}
              onClick={() => setHamburger(false)}
            >
              <NavLink to="/" end className="listItem">
                Home
              </NavLink>
              <NavLink to="/job" end className="listItem">
                Job Search
              </NavLink>
              <NavLink to="/employers" end className="listItem">
                Employers
              </NavLink>
              <NavLink
                to={uid ? "/notice-board" : "/register"}
                end
                className="listItem"
              >
                Notice Board
              </NavLink>
              {/* <NavLink to='/faqs'end className="listItem">FAQs ⏷</NavLink> */}
              <NavLink to="/about-us" end className="listItem">
                About Us
              </NavLink>
            </div>
          </nav>

          {localStorage.getItem("candidateToken") ? (
            <div
              className={hamburger ? "nav-links-mobilee" : "btns btn-hide"}
              onClick={() => setHamburger(false)}
            >
              <button className="bttn wbkkk" onClick={goToDashboard}>
                Dashboard
              </button>
              <button className="bttn bk" onClick={Logout}>
                Logout
              </button>
            </div>
          ) : (
            <div
              className={hamburger ? "nav-links-mobilee" : "btns btn-hide"}
              onClick={() => setHamburger(false)}
            >
              <button className="bttn bkb" onClick={goToRegister}>
                Register
              </button>
              <button className="bttn bk" onClick={goToLogIn}>
                Sign In
              </button>
            </div>
          )}
          <div className="btns ham-hide">
            <DehazeIcon onClick={() => setHamburger(!hamburger)} />
          </div>
        </div>
      </header>
    </>
  );
};

export default List;
