import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import baseUrlAPI from "../BaseURL";
// import { useEffect } from "react";
import NavigateTo from "../NavigateTo";

const ProfileSnapQualification = () => {
  const [education, setEducation] = useState(null);
  const token = localStorage.getItem("candidateToken");
  const uid = localStorage.getItem("uid");
  console.log({ uid, token });
  const getEducation = async () => {
    const { data } = await axios.post(`${baseUrlAPI}/api/getQualification`, {
      candidate_id: uid,
      token,
    });
    setEducation(data[0]);
    console.log({ data: data[0] });
  };
  useEffect(() => {
    getEducation();
  }, []);
  if (!education) {
    return <NavigateTo label={"Experience"} url={"education"} />;
  }

  return (
    <div>
      <div>
        <strong>College name : </strong>
        {education.college_name}
      </div>
      <div>
        <strong> Degree Name : </strong>
        {education.degree_name}
      </div>
      <div>
        <strong>Intitation year : </strong>
        {education.initiation_year}
      </div>
      <div>
        <strong>Passing year : </strong>
        {education.passout_year}
      </div>
      <div>
        <strong>Passing Score : </strong>
        {education.passing_score}
      </div>
    </div>
  );
};

export default ProfileSnapQualification;
