import react from "react";

const AddInternShipForm = ({ handleChange }) => {
  return (
    <div name="input">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div className="title-other">
            <label htmlFor="">Title </label> <br />
            <input
              type="text"
              placeholder="Title"
              name="title"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div className="title-other">
            <label htmlFor="">Year </label> <br />
            <input
              type="text"
              placeholder="Year "
              name="starting_year"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="des-otherr">
            <label htmlFor="">
              Description
              <span className="red-span">
                (Add ";" for adding bullet point)
              </span>
            </label>{" "}
            <br />
            <textarea
              id=""
              className="dec-des-dec"
              cols="30"
              rows="5"
              placeholder="Description ...."
              name="description"
              // defaultValue={getIntern.description}
              onChange={(e) => handleChange(e)}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInternShipForm;
