import React, { useState } from "react";

import "../css/Welcome.css";

import AddTaskIcon from "@mui/icons-material/AddTask";
import { useNavigate } from "react-router-dom";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Welcome = () => {
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  function handleAgreeChange(event) {
    setAgree(event.target.checked);
  }

  function handleSubmit(event) {
    event.preventDefault();
    // handle profile completion
  }

  const goToDashboard = () => {
    if (localStorage.getItem("candidateToken")) {
      navigate("/dashboard");
      window.scrollTo(0, 0);
    } else {
      navigate("/member-log-in");
    }
  };

  // const viewJob = () => {
  //   navigate("/job");
  // };
  return (
    <>
      <div className="welcome">
        <div className="container">
          <div className="row ">
            <div className=" col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <AddTaskIcon sx={{ fontSize: 50 }} className="icn-wel" /> <br />
              <h6 className="wel-par">Welcome to our Portal!</h6>
              <h2 className="wel-head">
                Thank you For <span>Registration</span>{" "}
              </h2>
              <p className="wel-paragraph">
                Welcome to the elite lobby of management professionals of India.
                Here you will get curated job openings <br />
                meant only for the best in the business. Please complete the
                last step of CV creation process after which <br />
                you will be all setup for applying for various job openings.
              </p>
              <div>
                {/* <span className="wel-view" onClick={goToDashboard}>
                  Go to the dashboard
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rule-wel">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 className="wel-rule">
                Rules of <span>Community</span>{" "}
              </h2>

              <div className="rule-exp">
                <div className="rule-exp-p-g">
                  <div className="ver-l-ine"></div> &nbsp; &nbsp;
                  <p>
                    The objective of this website is to make the recruitment
                    process simpler and faster for both the recruiters and the
                    job seekers. To fulfil our mission we need your support!!
                  </p>
                </div>

                <div className="rule-exp-p-g">
                  <div className="ver-l-ine"></div> &nbsp; &nbsp;
                  <p>
                    Please apply to an opening only if you are genuinely
                    interested in it. Read the JD, location, expected CTC etc to
                    be sure if role is right for you
                  </p>
                </div>
                <div className="rule-exp-p-g">
                  <div className="ver-l-ine"></div> &nbsp; &nbsp;
                  <p>
                    Once an offer has been made by a company, it should not be
                    rejected. The candidates who reject final offers will be
                    placed in least preferred list for next job openings
                  </p>
                </div>

                <div className="rule-exp-p-g">
                  <div className="ver-l-ine"></div> &nbsp; &nbsp;
                  <p>
                    The interview schedule & all the process of selection are
                    carried upon by the employers and PHC partners have no
                    influence over the recruitment process
                  </p>
                </div>

                <div className="rule-exp-p-g">
                  <div className="ver-l-ine-m"></div> &nbsp; &nbsp;
                  <p>
                    Please revert to the mails sent by the recruiter even if you
                    are planning not to attend the interview or further rounds.
                    It will save the recruiter valuable time and provide an
                    opportunity to other participants
                  </p>
                </div>

                <div className="rule-exp-p-g">
                  <div className="ver-l-ine-m"></div> &nbsp; &nbsp;
                  <p>
                    Please maintain the professional conduct throughout the
                    recruitment process with the employers. Complaints regarding
                    rude, indecent or careless behaviour towards employers may
                    lead to debarring from our website
                  </p>
                </div>

                <div className="rule-exp-p-g">
                  <div className="ver-l-ine-m"></div> &nbsp; &nbsp;
                  <p>
                    We are available in case you face any difficulty or issue
                    during the recruitment process. But please acknowledge that
                    we are a very small team now working with very stringent
                    timelines and we might take some time responding to your
                    queries
                  </p>
                </div>
                <div className="ml-fp-r">
                  <FormControlLabel
                    onSubmit={handleSubmit}
                    control={<Checkbox />}
                    label=" I Agree  to the terms & conditions"
                    className="check-label"
                    checked={agree}
                    onChange={handleAgreeChange}
                  />
                </div>
                <center>
                  <button
                    className="wel-btn"
                    disabled={!agree}
                    onClick={() => {
                      navigate("/education");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Complete your profile
                  </button>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
