import React, { useEffect, useState } from "react";
import "../css/AppliedJobs.css";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useNavigate } from "react-router-dom";

import { saveAs } from "file-saver";
import JobCard from "../component/Job_card";
import baseUrlAPI from "./BaseURL";
import SideBar from "./SideBar";

const AppliedJobs = () => {
  const [sorting, setSorting] = useState("");
  const uid = localStorage.getItem("uid");
  useEffect(() => {
    if (uid) {
      navigate("/applied-job");
      window.scrollTo(0, 0);
    } else {
      navigate("/register");
      window.scrollTo(0, 0);
    }
  }, []);

  const navigate = useNavigate();

  const goToSavedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/saved-jobs");
  };

  // const goToMessages = () => {
  //     window.scrollTo(0, 0)
  //     navigate('/messages')
  // }

  const goToMyProfile = () => {
    window.scrollTo(0, 0);
    navigate("/my-profile");
  };

  const goToGenerateCV = () => {
    window.scrollTo(0, 0);
    navigate("/generate-cv");
  };

  const goToProfileOverview = () => {
    window.scrollTo(0, 0);
    navigate("/dashboard");
  };

  const goToChangePassword = () => {
    window.scrollTo(0, 0);
    navigate("/change-password");
  };

  const tokenid = localStorage.getItem("candidateToken");

  const candidate_id = localStorage.getItem("uid");

  const applyID = { token: tokenid, candidate_id: candidate_id };

  // console.log(candidate_id);

  // console.log(applyID);

  const [getApplyJob, setGetApplyJob] = useState([]);

  // const getApplyJobURL = "http://3.109.83.81/phc-admin/api/getApplyJob";

  const getApplyJobURL = `${baseUrlAPI}/api/getApplyJob`;

  useEffect(() => {
    getAppliedJobs();
  }, [getApplyJobURL, sorting]);

  const getAppliedJobs = () => {
    fetch(getApplyJobURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(applyID),
    })
      .then((result) => result.json())
      .then((data) => {
        const dataFilter = data.filter(
          (dataItem) =>
            dataItem.jc_status.toLowerCase() === sorting.toLocaleLowerCase()
        );
        setGetApplyJob(sorting ? dataFilter : data);
        if (data.status === 200) {
          alert("success");
        }
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  // console.log(getApplyJob);
  localStorage.setItem("appliedJob", getApplyJob.length);
  const goToJobSearch = (val) => {
    navigate("/job-search/" + val?.job_id);
  };
  const DownloadCV = async (e) => {
    e.preventDefault();
    // import { saveAs } from "file-saver";
    const candidate_id = localStorage.getItem("uid");

    console.log(candidate_id);
    const DownloadResume = { uid: candidate_id };
    console.log(DownloadResume);

    const DownloadCVURL = `${baseUrlAPI}/api/pdf`;

    fetch(DownloadCVURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(DownloadResume),
    })
      .then((res) => res.blob())
      .then((data) => {
        saveAs(data, "cv.pdf");
      });
  };

  return (
    <>
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 className="dashboard-heading-d">Applied Jobs</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="Profile-Overview">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
              <div className="flex-p-overview-m">
                <p className="bol-pro" onClick={goToProfileOverview}>
                  <ion-icon name="search-circle-outline"></ion-icon> Profile
                  Overview
                </p>
                <p className="pro-over-col bol-pro">
                  <ion-icon name="business-outline"></ion-icon> Applied Jobs
                </p>
                <p className="bol-pro" onClick={goToSavedJobs}>
                  <ion-icon name="bookmark-outline"></ion-icon> Saved Jobs
                </p>
                <p className="bol-pro" onClick={goToMyProfile}>
                  <ion-icon name="person-circle-outline"></ion-icon> My Profile
                </p>
                <p className="bol-pro" onClick={DownloadCV}>
                  <ion-icon name="document-outline"></ion-icon> Generate CV
                </p>
                <p className="bol-pro" onClick={goToChangePassword}>
                  <ion-icon name="lock-closed-outline"></ion-icon> Change
                  Password
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Deactivate
                  Account
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Log Out
                </p>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
              <div className="pad-col-dash-appl">
                <div className="pad-col-flex-appl">
                  <h5>Recent Applied Jobs</h5>
                  <div className="short-div-apll">
                    <span>Sort by:</span>
                    <select
                      name=""
                      id="opt-applied"
                      onChange={(e) => {
                        setSorting(e.target.value);
                      }}
                    >
                      <option value="new">New </option>
                      <option value="inprogress">Inprogress</option>
                      <option value="shortlisted">Shortlisted</option>
                      <option value="hired">Hired</option>
                      <option value="rejected">Rejected</option>
                    </select>
                  </div>
                </div>

                <div className="day-cards">
                  {getApplyJob.length === 0 ? (
                    <div>No jobs found</div>
                  ) : (
                    getApplyJob?.map((val, i) => (
                      <JobCard key={i} job={val} print={3} />
                    ))
                  )}

                  {/* {getApplyJob?.map((val, i) => {
                    return <JobCard job={val} print={3} />;
                    // return (
                    //   <div
                    //     className="day-card-flexx"
                    //     key={i}
                    //     onClick={() => goToJobSearch(val)}
                    //   >
                    //     <div className="day-card-icn">
                    //       <LinkedInIcon className="social-icon" />
                    //       <div className="card-flex">
                    //         <div className="align">
                    //           <p>
                    //             <LocationOnOutlinedIcon className="align-icn" />{" "}
                    //             {val.job_location}
                    //           </p>
                    //         </div>
                    //       </div>
                    //       <div className="jobCardImageContainer">
                    //         <img
                    //           src={
                    //             val.company_logo !== "" || !val.company_logo
                    //               ? val.company_logo
                    //               : `https://cdn-icons-png.flaticon.com/512/174/174857.png`
                    //           }
                    //           className="jobCardImage"
                    //         />
                    //       </div>
                    //     </div>
                    //     <h5>{val.job_title}</h5>
                    //     <div className="full-flex">
                    //       <p className="full f1">{val.employment_type}</p>
                    //       <p className="full">{val.date_added}</p>
                    //     </div>
                    //     <p className="day-para">
                    //       {val
                    //         ? val.short_description > 100
                    //           ? `${val.short_description.slice(0, 100)}...`
                    //           : val.short_description
                    //         : "Loading"}
                    //     </p>
                    //     <div className="day-btn-flex">
                    //       <div
                    //         dangerouslySetInnerHTML={{
                    //           __html: val.req_skills,
                    //         }}
                    //       />
                    //     </div>
                    //     <div className="day-btn-l">
                    //       <button disabled>Applied!</button>
                    //     </div>
                    //   </div>
                    // );
                  })} */}

                  {/* <div className="day-card-flexx">
                                    <div className="day-card-icn">
                                        <LinkedInIcon className='social-icon'/>
                                        <div className="card-flex">
                                            <h6>Linked In</h6>
                                            <div className="align">
                                            <p><LocationOnOutlinedIcon className='align-icn'/> New York, US</p>
                                            </div>
                                            
                                        </div>
                                    
                                    </div>
                                    <h5>UI/UX Desginer</h5>
                                    <div className="full-flex">
                                        <p className='full f1'>Fulltime</p>
                                        <p className='full'>4 minutes ago</p>
                                    </div>
                                    <div className="flex-col-jb">
                                        <span>Status</span>
                                        <select name="" id="">
                                            <option value="">Selected for Interview</option>
                                            <option value="">Under Interview</option>
                                            <option value="">Resume Selected</option>
                                            <option value="">Selected</option>
                                            <option value="">Resume Rejected</option>
                                            <option value="">In Progress</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="day-card-flexx">
                                    <div className="day-card-icn">
                                        <LinkedInIcon className='social-icon'/>
                                        <div className="card-flex">
                                            <h6>Linked In</h6>
                                            <div className="align">
                                            <p><LocationOnOutlinedIcon className='align-icn'/> New York, US</p>
                                            </div>
                                            
                                        </div>
                                    
                                    </div>
                                    <h5>UI/UX Desginer</h5>
                                    <div className="full-flex">
                                        <p className='full f1'>Fulltime</p>
                                        <p className='full'>4 minutes ago</p>
                                    </div>
                                    <div className="flex-col-jb">
                                        <span>Status</span>
                                        <select name="" id="">
                                            <option value="">Selected for Interview</option>
                                            <option value="">Under Interview</option>
                                            <option value="">Resume Selected</option>
                                            <option value="">Selected</option>
                                            <option value="">Resume Rejected</option>
                                            <option value="">In Progress</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="day-card-flexx">
                                    <div className="day-card-icn">
                                        <LinkedInIcon className='social-icon'/>
                                        <div className="card-flex">
                                            <h6>Linked In</h6>
                                            <div className="align">
                                            <p><LocationOnOutlinedIcon className='align-icn'/> New York, US</p>
                                            </div>
                                            
                                        </div>
                                    
                                    </div>
                                    <h5>UI/UX Desginer</h5>
                                    <div className="full-flex">
                                        <p className='full f1'>Fulltime</p>
                                        <p className='full'>4 minutes ago</p>
                                    </div>
                                    <div className="flex-col-jb">
                                        <span>Status</span>
                                        <select name="" id="">
                                            <option value="">Selected for Interview</option>
                                            <option value="">Under Interview</option>
                                            <option value="">Resume Selected</option>
                                            <option value="">Selected</option>
                                            <option value="">Resume Rejected</option>
                                            <option value="">In Progress</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="day-card-flexx">
                                    <div className="day-card-icn">
                                        <LinkedInIcon className='social-icon'/>
                                        <div className="card-flex">
                                            <h6>Linked In</h6>
                                            <div className="align">
                                            <p><LocationOnOutlinedIcon className='align-icn'/> New York, US</p>
                                            </div>
                                            
                                        </div>
                                    
                                    </div>
                                    <h5>UI/UX Desginer</h5>
                                    <div className="full-flex">
                                        <p className='full f1'>Fulltime</p>
                                        <p className='full'>4 minutes ago</p>
                                    </div>
                                    <div className="flex-col-jb">
                                        <span>Status</span>
                                        <select name="" id="">
                                            <option value="">Selected for Interview</option>
                                            <option value="">Under Interview</option>
                                            <option value="">Resume Selected</option>
                                            <option value="">Selected</option>
                                            <option value="">Resume Rejected</option>
                                            <option value="">In Progress</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="day-card-flexx">
                                    <div className="day-card-icn">
                                        <LinkedInIcon className='social-icon'/>
                                        <div className="card-flex">
                                            <h6>Linked In</h6>
                                            <div className="align">
                                            <p><LocationOnOutlinedIcon className='align-icn'/> New York, US</p>
                                            </div>
                                            
                                        </div>
                                    
                                    </div>
                                    <h5>UI/UX Desginer</h5>
                                    <div className="full-flex">
                                        <p className='full f1'>Fulltime</p>
                                        <p className='full'>4 minutes ago</p>
                                    </div>
                                    <div className="flex-col-jb">
                                        <span>Status</span>
                                        <select name="" id="">
                                            <option value="">Selected for Interview</option>
                                            <option value="">Under Interview</option>
                                            <option value="">Resume Selected</option>
                                            <option value="">Selected</option>
                                            <option value="">Resume Rejected</option>
                                            <option value="">In Progress</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="day-card-flexx">
                                    <div className="day-card-icn">
                                        <LinkedInIcon className='social-icon'/>
                                        <div className="card-flex">
                                            <h6>Linked In</h6>
                                            <div className="align">
                                            <p><LocationOnOutlinedIcon className='align-icn'/> New York, US</p>
                                            </div>
                                            
                                        </div>
                                    
                                    </div>
                                    <h5>UI/UX Desginer</h5>
                                    <div className="full-flex">
                                        <p className='full f1'>Fulltime</p>
                                        <p className='full'>4 minutes ago</p>
                                    </div>
                                    <div className="flex-col-jb">
                                        <span>Status</span>
                                        <select name="" id="">
                                            <option value="">Selected for Interview</option>
                                            <option value="">Under Interview</option>
                                            <option value="">Resume Selected</option>
                                            <option value="">Selected</option>
                                            <option value="">Resume Rejected</option>
                                            <option value="">In Progress</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="day-card-flexx">
                                    <div className="day-card-icn">
                                        <LinkedInIcon className='social-icon'/>
                                        <div className="card-flex">
                                            <h6>Linked In</h6>
                                            <div className="align">
                                            <p><LocationOnOutlinedIcon className='align-icn'/> New York, US</p>
                                            </div>
                                            
                                        </div>
                                    
                                    </div>
                                    <h5>UI/UX Desginer</h5>
                                    <div className="full-flex">
                                        <p className='full f1'>Fulltime</p>
                                        <p className='full'>4 minutes ago</p>
                                    </div>
                                    <div className="flex-col-jb">
                                        <span>Status</span>
                                        <select name="" id="">
                                            <option value="">Selected for Interview</option>
                                            <option value="">Under Interview</option>
                                            <option value="">Resume Selected</option>
                                            <option value="">Selected</option>
                                            <option value="">Resume Rejected</option>
                                            <option value="">In Progress</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="day-card-flexx">
                                    <div className="day-card-icn">
                                        <LinkedInIcon className='social-icon'/>
                                        <div className="card-flex">
                                            <h6>Linked In</h6>
                                            <div className="align">
                                            <p><LocationOnOutlinedIcon className='align-icn'/> New York, US</p>
                                            </div>
                                            
                                        </div>
                                    
                                    </div>
                                    <h5>UI/UX Desginer</h5>
                                    <div className="full-flex">
                                        <p className='full f1'>Fulltime</p>
                                        <p className='full'>4 minutes ago</p>
                                    </div>
                                    <div className="flex-col-jb">
                                        <span>Status</span>
                                        <select name="" id="">
                                            <option value="">Selected for Interview</option>
                                            <option value="">Under Interview</option>
                                            <option value="">Resume Selected</option>
                                            <option value="">Selected</option>
                                            <option value="">Resume Rejected</option>
                                            <option value="">In Progress</option>
                                        </select>
                                    </div>
                                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppliedJobs;
