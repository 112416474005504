

import React from 'react';
import { useNavigate } from 'react-router-dom';

import '../css/Messages.css';

const Messages = () => {

    const navigate= useNavigate();
    const goToGenerateCV = () => {
        window.scrollTo(0, 0)
        navigate('/generate-cv');
    }

    const goToMyProfile = () =>{
        window.scrollTo(0, 0)
        navigate('/my-profile');
    }

    const goToSavedJobs = () => {
        window.scrollTo(0, 0)
        navigate('/saved-jobs')
    }

    const goToAppliedJobs = () => {
        window.scrollTo(0, 0)
        navigate('/applied-job');
    }

    const goToProfileOverview = () => {
        window.scrollTo(0, 0)
        navigate('/dashboard')
    }
  return (
    <>

        <div className="dashboard">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <h2 className='dashboard-heading-d'>DashBoard</h2>
                    </div>
                </div>
            </div>
        </div>

        <div className="Profile-Overview">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <div className="flex-p-overview-me">
                            <p className='bol-pro' onClick={goToProfileOverview}><ion-icon name="search-circle-outline"></ion-icon> Profile Overview</p>
                            <p className='bol-pro' onClick={goToAppliedJobs}><ion-icon name="business-outline"></ion-icon> Applied Jobs</p>
                            <p className='bol-pro' onClick={goToSavedJobs}><ion-icon name="bookmark-outline"></ion-icon> Saved Jobs</p>
                            <p className='bol-pro' onClick={goToMyProfile}><ion-icon name="person-circle-outline"></ion-icon> My Profile</p>
                            <p className='pro-over-col bol-pro'><ion-icon name="chatbox-ellipses-outline"></ion-icon> Messages</p>
                            <p className='bol-pro' onClick={goToGenerateCV}><ion-icon name="document-outline"></ion-icon> Generate CV</p>
                            <p className='bol-pro'><ion-icon name="lock-closed-outline"></ion-icon> Change Password</p>
                            <p className='bol-pro'><ion-icon name="log-out-outline"></ion-icon> Log Out</p>
                        </div>
                    </div>
                
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">

                       <div className="pad-col-dash-appl">
                            <div className="pad-col-flex-appl">
                                <h5>6 Messages</h5>
                                <div className="short-div-apll">
                                    <span>Sort by:</span>
                                    <select name="" id="opt-applied">
                                        <option value="">Newest Post</option>
                                        <option value="">Resume Selected</option>
                                        <option value="">Selected</option>
                                        <option value="">Resume Rejected</option>
                                        <option value="">In Progress</option>
                                    </select>
                                    
                                </div>
                            </div>

                            <div className="d-pad-mar">
                                <div className="flex-pa-ma">
                                 <h5>John Doe | Company Name</h5>
                                 <div  className="flex-pa-ma">
                                   <span>Reply</span>
                                   <span>Delete</span>
                                  </div>
                                </div>
                                <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                                wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure
                                dolor in hendrerit in vulputate velit esse molestie consequat.
                                </p>
                            </div>

                            <div className="d-pad-mar">
                                <div className="flex-pa-ma">
                                 <h5>John Doe | Company Name</h5>
                                 <div  className="flex-pa-ma">
                                   <span>Reply</span>
                                   <span>Delete</span>
                                  </div>
                                </div>
                                <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                                wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure
                                dolor in hendrerit in vulputate velit esse molestie consequat.
                                </p>
                            </div>

                            <div className="d-pad-mar">
                                <div className="flex-pa-ma">
                                 <h5>John Doe | Company Name</h5>
                                 <div  className="flex-pa-ma">
                                   <span>Reply</span>
                                   <span>Delete</span>
                                  </div>
                                </div>
                                <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                                wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure
                                dolor in hendrerit in vulputate velit esse molestie consequat.
                                </p>
                            </div>

                            <div className="d-pad-mar">
                                <div className="flex-pa-ma">
                                 <h5>John Doe | Company Name</h5>
                                 <div  className="flex-pa-ma">
                                   <span>Reply</span>
                                   <span>Delete</span>
                                  </div>
                                </div>
                                <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                                wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure
                                dolor in hendrerit in vulputate velit esse molestie consequat.
                                </p>
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Messages
