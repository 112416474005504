import React from "react";
import "../css/ChangePassword.css";
import axios from "axios";

const ChangePassword = () => {
  return (
    <>
      <div className="ChangePassword">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3"></div>
            <div className="col-lg-6 col-md-6">
              {/* <h6 className='wChePas'>Welcome to Change Password!</h6> */}
              {/* <h1 className='chen_Pass'>Change <span>Password</span></h1> */}
              <h1 className="chen_Pass">Change Password</h1>
              <form action="" className="change_pass_form">
                <label htmlFor="">Old Password *</label> <br />
                <input type="text" placeholder="Old Password" /> <br />
                <label htmlFor="">New Password *</label> <br />
                <input type="text" placeholder="New Password" />
                <br />
                <label htmlFor="">Confirm New Password *</label> <br />
                <input type="text" placeholder="Confirm New Password" /> <br />
                <button className="chem_pass_btn">Change Password</button>
              </form>
            </div>
            <div className="col-lg-3 col-md-3"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
