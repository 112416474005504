import React, { useEffect, useState } from "react";
import "../css/Reset_Password.css";
import { useNavigate } from "react-router-dom";
import baseUrlAPI from "./BaseURL";
import { Modal, ModalBody, Row, Col } from "reactstrap";
import axios from "axios";

const Reset_Password = () => {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [otp, setOTP] = useState("");
  const [error, setError] = useState();
  const [login, setLogin] = useState(false);
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // const navigate = useNavigate();
  const handleSubmit = async (e) => {
    const mobile = localStorage.getItem("mobile");
    // const vaOTP = localStorage.getItem("otp");
    try {
      const responce = await axios.post(`${baseUrlAPI}/api/resetpassword`, {
        password_confirmation,
        otp,
        password,
        mobile,
      });
      console.log(responce.data);
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
    }
  };
  const errors = [];

  // if (!password) {
  //   errors.password = {
  //     field: "password",
  //     message: "Password is required.",
  //   };
  // }
  // if (!password_confirmation) {
  //   errors.password_confirmation = {
  //     field: "passwordConfirmation",
  //     message: "Password confirmation is required.",
  //   };
  // }
  // if (password !== password_confirmation) {
  //   errors.password = {
  //     field: "passwordConfirmation",
  //     message: "Password confirmation must match the password.",
  //   };
  // }
  // if (!otp) {
  //   errors.otp = {
  //     field: "otp",
  //     message: "OTP is required.",
  //   };
  // }

  // // If there are errors, set them and return
  // if (errors.length > 0) {
  //   setError(errors);
  //   return;
  // } else {
  //   setSuccessMessage("Password reset successfully");
  //   // Redirect to member login page
  //   // window.location.href = "/member-log-in";
  // }

  const Reset = () => {
    navigate("/reset-password");
  };
  const Login = () => {
    navigate("/member-log-in");
  };

  return (
    <>
      <div className="Member-Login">
        <Modal size="sm" isOpen={login} toggle={() => setLogin(!login)}>
          {/* <ModalHeader toggle={()=>setPopup(!popup)}>
            PopUp
          </ModalHeader> */}
          <ModalBody>
            <form action="">
              <Row>
                <Col lg={12}>
                  <h2 className="login-err">Invalid OTP</h2>
                  <h6 className="saveup-cen">Please Enter Valid OTP</h6>

                  <button onClick={Reset} className="ok_popup">
                    OK
                  </button>
                </Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>
        <h5 className="ml-wel">Welcome back!</h5> <br />
        <h1 className="ml-mem">
          Reset your <span>Password!</span>{" "}
        </h1>
        <p className="vya-para-p">
          Please enter the One-Time Password sent to registered Email Account
          for verifying your PHC Account
          {/* <br></br>A One-Time Password has been sent to Registered Email Account
          related to {localStorage.getItem("mobile")}. */}
        </p>{" "}
        <br />
        {/* <input
          type="text"
          className="in-otp-vc"
          placeholder="-----"
          value={otp}
          onChange={(e) => setOTP(e.target.value)}
        />{" "} */}
        {/* <p className="ml-access">
          The verification email will be sent to the emails box.
        </p>{" "} */}
        <br />
        <form action="" className="ml-form">
          {error && <div>{error}</div>}
          <label htmlFor="" className="eu-mll">
            Enter New Password
          </label>{" "}
          <br />
          <input
            className="input-userr"
            type="password"
            placeholder="Enter New password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />{" "}
          {errors.password && <div className="error">{errors.password}</div>}
          <br /> <br />
          <label htmlFor="" className="eu-mll">
            Confirm Password
          </label>{" "}
          <br />
          <input
            className="input-userr"
            type="password"
            placeholder="Confirm Password"
            value={password_confirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />{" "}
          {errors.password_confirmation && (
            <div className="error">{errors.password_confirmation}</div>
          )}
          <br />
          <br />
          <label htmlFor="" className="eu-mll">
            Enter OTP
          </label>{" "}
          <br />
          <input
            className="input-userr"
            type="number"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
          />
          {errors.otp && <div className="error">{errors.otp}</div>}
          {""}
          {/* <label htmlFor="" className="pass-mll">
            Verification Code *
          </label>{" "} */}
          {/* <input className="input-passs" type="text" placeholder="2024" /> */}
        </form>{" "}
        <br />
        <button onClick={handleSubmit} className="validate">
          CONFIRM
        </button>{" "}
        {errors.successMessage && (
          <div className="success">{errors.successMessage}</div>
        )}
        <br /> <br />
        <div className="signUp">
          <button className="btn-sign" onClick={Login}>
            Back to LogIn
          </button>
        </div>
      </div>
    </>
  );
};

export default Reset_Password;
