import React, { useEffect, useState } from "react";
import { useHistory, useNavigate } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import axios from "axios";

const LinkedInApi = {
  clientId: "77tv1d3cdsi4vn",
  redirectUrl: "http://localhost:3000/linkden",
  oauthUrl:
    "https://www.linkedin.com/oauth/v2/authorization?response_type=code",
  scope: "r_liteprofile%20r_emailaddress",
  state: Math.random().toString(36).substring(7), // randomly generated string
};

// const NodeServer = {
//   baseURL: "http://phc-hosting.s3-website.ap-south-1.amazonaws.com",
//   getUserCredentials: "/getUserCredentials",
// };

const App = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);

  const checkAuthentication = () => {
    const token = localStorage.getItem("token");
    return !!token; // Example: Assume the user is authenticated
  };
  const checkIdRegistration = () => {
    const registeredIds = ["id1", "id2", "id3"];
    const idToCheck = "your_id";
    return registeredIds.includes(idToCheck);
  };
  useEffect(() => {
    const email = localStorage.getItem("email"); // Retrieve the email from storage
    const isAuthenticated = checkAuthentication();
    const isIdRegistered = checkIdRegistration();

    if (!isAuthenticated) {
      navigate("/member-log-in"); // Redirect to the login page
    } else if (!isIdRegistered) {
      navigate("/id-not-registered"); // Redirect to ID not registered page
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", handlePostMessage);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);

  const handlePostMessage = (event) => {
    if (event.data.type === "authorization_code") {
      const { code } = event.data;
      // getUserCredentials(code);
      getAccessToken(code);
    }
  };

  const showPopup = () => {
    const { clientId, redirectUrl, scope, state } = LinkedInApi;
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77tv1d3cdsi4vn&redirect_uri=http://localhost:3000/linkden&state=foobar&scope=r_liteprofile%20r_emailaddress%20w_member_social`;

    const width = 500;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const popup = window.open(
      url,
      "popup",
      `width=${width},height=${height},left=${left},top=${top}`
    );
    if (window.focus) {
      popup.focus();
    }
  };

  const getAccessToken = async (code) => {
    const clientId = "77tv1d3cdsi4vn";
    const clientSecret = "pzEs0n32Z3RTLnJu";
    const redirectUrl = "http://localhost:3000/linkden";
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      };
      fetch(
        `https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code=${code}&client_id=77tv1d3cdsi4vn&client_secret=pzEs0n32Z3RTLnJu&redirect_uri=http://localhost:3000/linkden`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data?.access_token) {
            const response = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
            };
            fetch(
              `http://15.206.6.75/phc-admin/api/linkedin-auth?access_token=${data?.access_token}`,
              response
            )
              .then((response) => response.json())
              .then((response) => {
                // console.log(response.candidates);

                // console.log(usrInfo, "aaa");
                if (response && response.candidates) {
                  const usrInfo = {
                    current_location: response.candidates.current_location,
                    dob: response.candidates.dob,
                    email: response.candidates.email,
                    first_name: response.candidates.first_name,
                    last_name: response.candidates.last_name,
                    gender: response.candidates.gender,
                    mobile: response.candidates.mobile,
                    linkedIn_profile_link:
                      response.candidates.linkedIn_profile_link,
                  };
                  localStorage.setItem(
                    "usrInfo",
                    JSON.stringify(usrInfo) || ""
                  );
                  localStorage.setItem("candidateToken", response.token || "");
                  localStorage.setItem("uid", response.candidates.uid || "");
                  localStorage.setItem(
                    "can_uid",
                    response.candidates.uid || ""
                  );
                }
                navigate("/dashboard");
              });
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const contentWhenLoggedIn = (
    <>
      <img src={user?.profileImageURL} alt="Profile image" />
      <h3>{`${user?.firstName} ${user?.lastName}`}</h3>
      <h3>{user?.email}</h3>
    </>
  );

  const contentWhenLoggedOut = (
    <>
      <button className="btn-linkden" onClick={showPopup}>
        <LinkedInIcon className="btn-linden-icon" />
      </button>
    </>
  );

  return (
    <div>
      {loggedIn && Object.keys(user).length > 0
        ? contentWhenLoggedIn
        : contentWhenLoggedOut}
    </div>
  );
};

export default App;
