import React from "react";
import "../css/JobCard_small.css";
import { useNavigate } from "react-router-dom";

const JobCardSmall = ({ jobDetail }) => {
  console.log({ jobDetail });
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate(`/job-search/${jobDetail.uid}`);
  };
  return (
    <div className="jobShort">
      {" "}
      <div onClick={navigateTo} className="flex">
        <div className="jobShortHeader">
          <h5>{jobDetail.job_title}</h5>
          <img
            src={
              jobDetail.company_logo !== "" || !jobDetail.company_logo
                ? jobDetail.company_logo
                : `https://cdn-icons-png.flaticon.com/512/174/174857.png`
            }
            className="jobCardImage"
          />
        </div>
      </div>
      <div className="jd-content-left-text">
        <div className="jd-content-f">
          <span>
            <p>{jobDetail.employment_type}</p>
          </span>

          <span>
            <p>{jobDetail.job_location}</p>
          </span>
        </div>
        <div className="jobShortHeader">
          <span>
            <h5>{jobDetail.job_salary}LPA</h5>
          </span>
          <span className="sim-apply">
            <button>Apply Now</button>
          </span>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default JobCardSmall;
