import React, { useState } from "react";
import "../css/Employers.css";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import baseUrlAPI from "./BaseURL";
import { useNavigate } from "react-router-dom";
import { Label } from "@mui/icons-material";
import top19 from "../images/19.jpg";
import top18 from "../images/181.png";
import top18e from "../images/top18.png";
const Employers = () => {
  const [first_name, setFirst_name] = useState("");
  const [middle_name, setMiddle_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [phone, setPhone] = useState("");
  const [job_title, setJob_title] = useState("");
  const [company_email, setCompany_email] = useState("");
  const [formError, setFormError] = useState("");
  const [organization_name, setOrganizationName] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);

  const SendMessage = (e) => {
    e.preventDefault();
    const errors = {};
    if (!first_name) {
      errors.first_name = "Please enter your first name";
    }
    if (!middle_name) {
      errors.middle_name = "Please enter your middle name";
    }
    if (!last_name) {
      errors.last_name = "Please enter your last name";
    }
    if (!job_title) {
      errors.job_title = "Please enter your job title";
    }
    if (!phone) {
      errors.phone = "Please enter your phone number";
    }
    if (!company_email) {
      errors.company_email = "Please enter your company email";
    }
    if (!organization_name) {
      errors.organization_name = "Please enter your organization name";
    }
    if (
      !company_email
        .toLocaleLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      errors.company_email = "Email is not valid";
    }
    if (phone.length !== 10) {
      errors.phone = "Please Enter 10-digit Mobile Number";
    }
    if (isNaN(phone)) {
      errors.phone = "Mobile number is not valid";
    }
    if (Object.keys(errors).length === 0) {
      // submit the form
      setFormError({});
      setFormSuccess(true);
      console.log("form submitted successfully ");
    } else {
      // submit the form
      setFormError(errors);
      // perform any other necessary form submission logic here
    }
    // if (
    //   first_name.length == 0 ||
    //   last_name.length == 0 ||
    //   phone.length == 0 ||
    //   job_title.length == 0 ||
    //   company_email.length == 0
    // ) {
    //   setError(true);
    // }

    const tokenid = localStorage.getItem("candidateToken");
    // const first_name = e.target.first_name.value;
    // const last_name = e.target.last_name.value;
    // const phone = e.target.phone.value;
    // const job_title = e.target.job_title.value;
    // const company_email = e.target.company_email.value;
    // if (first_name && last_name && phone && job_title && company_email) {
    //   setSuccess("Query submitted successfully");
    //   // perform any other necessary form submission logic here
    // } else {
    //   setError("Query not submited carefully ");
    // }

    const Employers = {
      first_name,
      middle_name,
      last_name,
      organization_name,
      phone,
      job_title,
      company_email,
      token: tokenid,
    };
    console.log(Employers);

    const addEmployer_queryURL = `${baseUrlAPI}/api/addEmployer_query`;
    // window.success("query successfully  submited");
    fetch(addEmployer_queryURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(Employers),
    })
      .then((resultEmp) => resultEmp.json())
      .then((dataEMP) => {
        console.log("result", dataEMP);
        if (dataEMP.status === 200) {
          alert("success");
        }
      })
      .catch((e) => {
        console.log("e", e);
      });
  };
  const navigate = useNavigate();
  //   const contactUS = () => {
  //     window.scrollTo(0, 0)
  //     navigate("/contact-us")
  // }

  const searchJOBS = () => {
    window.scrollTo(0, 0);
    navigate("/job");
  };

  return (
    <div className="clo-p-col-j">
      <div className="Employers">
        <div className="container">
          <div className="row mar-b-mar">
            <div className=" col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="emp-hed">
                <h2>
                  SMARTER. FASTER. <br /> BETTER.
                </h2>
                <p>Hire the best talent from top business schools in India</p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pos-rel">
              <div className="emp-form">
                <h3>Start Recruiting Today</h3>
                {formSuccess ? (
                  <p className="success-message">
                    Form submitted successfully!
                  </p>
                ) : (
                  <form onSubmit={SendMessage}>
                    <div className="f-namer">
                      <div style={{ width: "auto" }} className="wdt">
                        <label htmlFor="" className="col-emp-j">
                          FIRST NAME
                        </label>{" "}
                        <br />
                        <input
                          className="inpt"
                          type="text"
                          placeholder="First Name"
                          value={first_name}
                          onChange={(e) => setFirst_name(e.target.value)}
                        />
                        {formError.first_name && (
                          <span className="error">{formError.first_name}</span>
                        )}
                      </div>
                      {/*======================= Middle Name Start ===============*/}
                      <div style={{ width: "auto" }} className="wdt">
                        <label htmlFor="" className="col-emp-j">
                          MIDDLE NAME
                        </label>{" "}
                        <br />
                        <input
                          className="inpt"
                          type="text"
                          placeholder="Middle Name"
                          value={middle_name}
                          onChange={(e) => setMiddle_name(e.target.value)}
                        />
                        {formError.middle_name && (
                          <span className="error">{formError.middle_name}</span>
                        )}
                      </div>
                      {/*======================= Middle Name End ==============*/}
                      <div style={{ width: "auto " }}>
                        <label htmlFor="" className="col-emp-j">
                          LAST NAME
                        </label>{" "}
                        <br />
                        <input
                          type="text"
                          className="l-n-emp"
                          placeholder="Last Name"
                          value={last_name}
                          onChange={(e) => setLast_name(e.target.value)}
                        />
                        {formError.last_name && (
                          <span className="error">{formError.last_name}</span>
                        )}
                      </div>
                    </div>

                    <div className="j-title">
                      <label htmlFor="" className="col-emp-j">
                        JOB TITLE
                      </label>{" "}
                      <br />
                      <input
                        type="text"
                        placeholder="Job Title"
                        value={job_title}
                        onChange={(e) => setJob_title(e.target.value)}
                      />
                      {formError.job_title && (
                        <span className="error">{formError.job_title}</span>
                      )}
                    </div>
                    <div className="emp-phone">
                      <label htmlFor="" className="col-emp-j">
                        PHONE NUMBER
                      </label>{" "}
                      <br />
                      <input
                        type="tel"
                        placeholder="Phone Number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      {formError.phone && (
                        <span className="error">{formError.phone}</span>
                      )}
                    </div>
                    <div className="emp-email">
                      <label htmlFor="" className="col-emp-j">
                        COMPANY EMAIL
                      </label>
                      <br />
                      <input
                        type="text"
                        placeholder="Company Email"
                        value={company_email}
                        onChange={(e) => setCompany_email(e.target.value)}
                      />
                      {formError.company_email && (
                        <span className="error">{formError.company_email}</span>
                      )}
                    </div>
                    <div className="emp-email">
                      <label htmlFor="" className="col-emp-j">
                        ORGANIZATION NAME
                      </label>
                      <br />
                      <input
                        type="text"
                        placeholder="Organization Name"
                        value={organization_name}
                        onChange={(e) => setOrganizationName(e.target.value)}
                      />
                      {formError.organization_name && (
                        <span className="error">
                          {formError.organization_name}
                        </span>
                      )}
                    </div>
                    <div className="git-btn-flex container"></div>
                    <button className="emp-s-m" onClick={SendMessage}>
                      Send Message
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="Hire-Easily"
        style={{ boxShadow: "0px 0px 20px rgb(0 0 0 / 50%)" }}
      >
        <div className="container h-e-easily success">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 hhh">
              <img
                className="emp-immg"
                src={top19}
                // src="https://media.istockphoto.com/photos/photo-of-candidates-waiting-for-a-job-interview-picture-id1137038796?b=1&k=20&m=1137038796&s=170667a&w=0&h=Fb47hwsSnsd9z71cKAeucZxMX18Za7tBb2Qla1_FHjM="
                alt=""
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ali-cen hhh">
              <h2 className="emp-search-hire search">
                Search Successfully , Hire Easily
              </h2>{" "}
              <br />
              <p className="emp-use-para">
                Use our database of professionals to hire top talent based on
                desired salary, location, industry, role, experience, education
                and more. Our standard CV template, exclusive talent pool,
                advanced filters, and support makes sourcing and hiring
                qualified professionals simple
              </p>{" "}
              <br />
              {/* <div className="aoc-btn-flex">
                <button className='aoc-btn-contact' onClick={contactUS}>Contact Us</button>
                <button className='emp-btn-learn-more'>Learn More</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="pro-pad">
        <div className="Problems container">
          <div className="p-textt">
            <h2>
              <span>{/* <h3>j</h3> */}</span>
              <span>
                Problems faced by employers on conventional platforms
              </span>{" "}
            </h2>
            <p className="h-e-easily">
              <p className="lispacing">
                {" "}
                <span>⋆</span>{" "}
                <label>
                  <b>Lack of Qualified Candidates</b> : Conventional hiring
                  platforms may not have access to a large enough pool of
                  qualified job candidates, making it difficult for employers to
                  find the right fit for the job
                </label>
              </p>
              <p className="lispacing">
                <span>⋆</span>{" "}
                <label>
                  <b>High Costs</b> : Using conventional hiring platforms can be
                  expensive for employers, especially for small businesses with
                  limited budgets
                </label>
              </p>
              <p>
                <span>⋆</span>{" "}
                <label>
                  <b> Time-Consuming Process</b>: Posting job openings and
                  sorting through applications can be a long and tedious
                  process, especially for employers with limited resources
                </label>
              </p>
              <p>
                <span>⋆</span>{" "}
                <label>
                  <b>Limited Ability to Track Results</b> : Employers may not be
                  able to track the success of their job postings or the
                  effectiveness of their recruiting efforts when using
                  conventional hiring platforms
                </label>
              </p>
            </p>

            <button className="p-btn h-e-easily" onClick={searchJOBS}>
              Search Jobs
            </button>
            {/* <button className="p-btn2 h-e-easily">Learn More</button> */}
          </div>
          <div className="p-img">
            <img
              src={top18e}
              // src="https://media.istockphoto.com/photos/photo-of-candidates-waiting-for-a-job-interview-picture-id1137038796?b=1&k=20&m=1137038796&s=170667a&w=0&h=Fb47hwsSnsd9z71cKAeucZxMX18Za7tBb2Qla1_FHjM="
              alt=""
              className="imgg"
            />
          </div>
        </div>
      </div>

      <div className="Why-Recruiters">
        <div className="wr-con">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h2 className="why-land">Why Recruiters Love Us</h2>
                <br />
                {/* <p className="when-land">
                  When it comes to choosing the right web hosting provider, we
                  know how easy it <br />
                  is to get overwhelmed with the number.
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="client-review-emp">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <figure className="customerr ohc-p-cus">
                  <blockquote>
                    PHC maintains its own talent pool and doesn’t borrow data
                    from any existing job platforms. Their candidate profile
                    data is up to date and they personally made sure that only
                    interested candidates’ profiles are forwarded to us.
                  </blockquote>
                  <div className="arrow">
                    <PlayArrowIcon
                      className="arrow-rot"
                      sx={{ fontSize: 40 }}
                    />
                  </div>

                  <div className="author-flex">
                    {/* <img
                      className="cus-img"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRugMCl7Y_-i2mW0Y6Y1jjrrl5sp-q9c1B6Kw&usqp=CAU"
                      alt="sq-sample3"
                    /> */}
                    <div className="author">
                      <h5>
                        Shefali
                        <br />{" "}
                        <span> Chief People Officer, Emerging IT Startup </span>
                      </h5>
                    </div>
                  </div>
                </figure>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <figure className="customerr ohc-p-cus">
                  <blockquote>
                    PHC's CV builder tool makes sure that all the shortlisted
                    CVs are in the same format which makes candidate selection
                    time-efficient and accurate.
                  </blockquote>
                  <div className="arrow">
                    <PlayArrowIcon
                      className="arrow-rot"
                      sx={{ fontSize: 40 }}
                    />
                  </div>
                  <div className="author-flex">
                    {/* <img
                      className="cus-img"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRugMCl7Y_-i2mW0Y6Y1jjrrl5sp-q9c1B6Kw&usqp=CAU"
                      alt="sq-sample3"
                    /> */}
                    <div className="author">
                      <h5>
                        Abhinav
                        <br />{" "}
                        <span>
                          {" "}
                          Hiring Specialist at a Leading Conglomerate
                        </span>
                      </h5>
                    </div>
                  </div>
                </figure>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <figure className="customerr">
                  <blockquote>
                    PHC provides the best match for any management vacancy and
                    they have a shorter turnaround time compared to any other
                    player I have worked with.
                  </blockquote>
                  <div className="arrow">
                    <PlayArrowIcon
                      className="arrow-rot"
                      sx={{ fontSize: 40 }}
                    />
                  </div>
                  <div className="author-flex">
                    {/* <img
                      className="cus-img"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRugMCl7Y_-i2mW0Y6Y1jjrrl5sp-q9c1B6Kw&usqp=CAU"
                      alt="sq-sample3"
                    /> */}
                    <div className="author">
                      <h5>
                        Mansi
                        <br /> <span> HRBP at a Private Bank</span>
                      </h5>
                    </div>
                  </div>
                </figure>
              </div>
            </div>
            {/* <div className="btn-ohc">
              <button className='ohc-load-more'>Load More</button>
            </div> */}
          </div>
        </div>
      </div>

      {/* <div className="Trusted-Partners">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 className='emp-land-trust'>Trusted Partners</h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="land-flex-emp">
              <img src="https://zeevector.com/wp-content/uploads/Tata-Group-logo-PNG.png" alt="" />
              <img src="https://logos-download.com/wp-content/uploads/2016/07/Airtel_logo.png" alt="" />
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cc/SBI-logo.svg/1024px-SBI-logo.svg.png" alt="" />
              <img src="https://w7.pngwing.com/pngs/454/420/png-transparent-hindustan-petroleum-hp-lubricant-business-business-text-rectangle-people.png" alt="" />
              <img src="https://download.logo.wine/logo/Suzlon/Suzlon-Logo.wine.png" alt="" />
              <img src="https://toppng.com/uploads/preview/titan-vector-logo-download-free-11574029507aj7fv0mril.png" alt="" />
              <img src="https://upload.wikimedia.org/wikipedia/en/4/46/Make_In_India.png" alt="" />
              <img src="https://w7.pngwing.com/pngs/763/564/png-transparent-bajaj-logo-bajaj-auto-chakan-car-logo-motorcycle-logo-blue-angle-company.png" alt="" />
              <img src="https://w7.pngwing.com/pngs/124/600/png-transparent-microsoft-logo-microsoft-thumbnail.png" alt="" />
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 jut">
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                <div className="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src="https://zeevector.com/wp-content/uploads/Tata-Group-logo-PNG.png" className="d-block w-100" alt="tata"/>
                  </div>
                  <div className="carousel-item">
                    <img src="https://logos-download.com/wp-content/uploads/2016/07/Airtel_logo.png" className="d-block w-100" alt="airtel"/>
                  </div>
                  <div className="carousel-item">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cc/SBI-logo.svg/1024px-SBI-logo.svg.png" className="d-block w-100" alt="SBI"/>
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
              
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Employers;
