// Education

import React, { useState, useEffect } from "react";

import "../css/RegEducation.css";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Alert } from "@mui/material";
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

import { useNavigate } from "react-router-dom";
// import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import YearPicker from "react-year-picker";
import baseUrlAPI from "./BaseURL";
// import { useHistory } from "react-router-dom";
import axios from "axios";

const steps = [
  "Basic Info",
  "Education",
  "Work Experience",
  "Others",
  "Preference",
];

const Education = () => {
  const [isback, setIsBack] = useState(false);

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    if (uid) {
      navigate("/education");
      window.scrollTo(0, 0);
    } else {
      navigate("/register");
      window.scrollTo(0, 0);
    }
  }, []);

  const [error, setError] = useState([]);
  const [success, setSuccess] = useState(null);
  const [formValues, setFormValues] = useState([
    { college: "", degree: "", i_year: "", o_year: "", score: "" },
    { college: "", degree: "", i_year: "", o_year: "", score: "" },
    { college: "", degree: "", i_year: "", o_year: "", score: "" },
    { college: "", degree: "", i_year: "", o_year: "", score: "" },
  ]);
  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { college: "", degree: "", i_year: "", o_year: "", score: "" },
    ]);
  };

  const navigate = useNavigate();
  const goToExp = () => {
    navigate("/candidate-experience");
  };
  const validateData = () => {
    const errors = [];
    formValues.forEach((e) => {
      if (e.college === "") {
        errors.push("College name is required");
      }
      if (e.degree === "") {
        errors.push("Degreee is required");
      }
      if (e.i_year === "" || parseInt(e.i_year) < 1960) {
        errors.push("In year is not valid");
      }
      if (e.o_year === "" || parseInt(e.o_year) < 1960) {
        errors.push("Out year is not valid");
      }
      if (e.score === "") {
        errors.push("Score is required");
      }
      if (isNaN(e.score)) {
        errors.push("Score is not valid");
      }
      if (isNaN(e.i_year) || isNaN(e.o_year)) {
        errors.push("Either in year or out is invalid ");
      }
      if (parseInt(e.i_year) > parseInt(e.o_year)) {
        errors.push("In year cannot be greater than out year");
      }
      if (e.score > 100 || e.score < 0) {
        errors.push("Score cannot be less than 0 or greater than 100");
      }
    });
    if (errors.length != 0) {
      setError(errors);
      window.scrollTo(0, 0);
      return false;
    } else {
      return true;
    }
  };
  const goToExperience = (e) => {
    e.preventDefault();
    const isDataValid = validateData();
    if (!isDataValid) {
      return;
    }
    const tokenid = localStorage.getItem("candidateToken");

    const candidate_id = localStorage.getItem("uid");

    const obj_data = {
      form_data: formValues,
      token: tokenid,
      candidate_id: candidate_id,
    };
    const educationURL = `${baseUrlAPI}/api/AddQualification`;
    fetch(educationURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj_data),
    })
      .then((result) => result.json())

      .then((data) => {
        if (data.status === 200) {
          alert("success");
        }
      })

      .catch((e) => {});
    navigate("/candidate-experience");
    window.scrollTo(0, 0);
  };
  const goToUpdate = (e) => {
    e.preventDefault();
    const errors = [];
    formValues.forEach((e) => {
      if (e.college === "") {
        errors.push("College name is required");
      }
      if (e.degree === "") {
        errors.push("Degreee is required");
      }
      if (e.i_year === "" || parseInt(e.i_year) < 1960) {
        errors.push("In year is not valid");
      }
      if (e.o_year === "" || parseInt(e.o_year) < 1960) {
        errors.push("Out year is not valid");
      }
      if (e.score === "") {
        errors.push("Score is required");
      }
      if (isNaN(e.score)) {
        errors.push("Score is not valid");
      }
      if (isNaN(e.i_year) || isNaN(e.o_year)) {
        errors.push("Either in year or out is invalid ");
      }
      if (parseInt(e.i_year) > parseInt(e.o_year)) {
        errors.push("In year cannot be greater than out year");
      }
      if (e.score > 100 || e.score < 0) {
        errors.push("Score cannot be less than 0 or greater than 100");
      }
    });
    if (errors.length != 0) {
      setError(errors);
      window.scrollTo(0, 0);
      return;
    }

    const tokenid = localStorage.getItem("candidateToken");

    const candidate_id = localStorage.getItem("uid");

    const obj_data = {
      form_data: formValues,
      token: tokenid,
      candidate_id: candidate_id,
    };

    navigate("/candidate-experience");
    window.scrollTo(0, 0);
  };
  const update = async (i) => {
    setError([]);
    const isDataValid = validateData();
    if (!isDataValid) {
      return;
    }
    console.log(i);
    const token = localStorage.getItem("candidateToken");
    const candidate_id = localStorage.getItem("uid");
    const itemToUpdate = formValues[i];
    console.log({
      token,
      candidate_id,
      itemToUpdate,
    });
    const updateObj = await axios.post(
      `${baseUrlAPI}/api/updateQualification`,
      {
        token,
        candidate_id,
        uid: itemToUpdate.uid,
        college_name: itemToUpdate.college,
        degree_name: itemToUpdate.degree,
        initiation_year: itemToUpdate.i_year,
        passout_year: itemToUpdate.o_year,
        passing_score: itemToUpdate.score,
      }
    );
    setSuccess("Your education has been updated succesfully.");
    // console.log(updateObj);
    // getData();
  };
  const updateEducation = async () => {
    const token = localStorage.getItem("candidateToken");
    const candidate_id = localStorage.getItem("uid");
    const apiPromises = formValues.map(async (e) => {
      const updateObj = {
        token,
        candidate_id,
        uid: e.uid,
        college_name: e.college,
        degree_name: e.degree,
        initiation_year: e.i_year,
        passout_year: e.o_year,
        passing_score: e.score,
      };
      const { data } = await axios.post(
        `${baseUrlAPI}/api/updateQualification`,
        updateObj
      );
      return data;
    });
    // const { data } = await axios.post(`${baseUrlAPI}/api/updateQualification`, {
    //   token,
    //   candidate_id,
    //   uid: uidValue,
    // });
  };
  //GEt DATA
  const getData = async () => {
    const candidateToken = localStorage.getItem("candidateToken");
    const id = localStorage.getItem("uid");

    const { data } = await axios.post(`${baseUrlAPI}/api/getQualification`, {
      candidate_id: id,
      token: candidateToken,
    });
    console.log({ data });
    if (data.length === 0) {
      return;
    } else {
      setIsBack(true);
    }
    const formData = data.map((el) => {
      return {
        uid: el.uid,
        college: el.college_name,
        degree: el.degree_name,
        i_year: el.initiation_year,
        o_year: el.passout_year,
        score: el.passing_score,
      };
    });
    setFormValues(formData);
  };
  useEffect(() => {
    getData();
  }, []);
  //UPDATE EDUCATION

  const collegeURL = `${baseUrlAPI}/api/colleges`;

  const [college, setCollege] = useState([]);

  const [collegeId, setCollegeId] = useState("");

  const [course, setCourse] = useState([]);

  useEffect(() => {
    fetch(collegeURL).then((result) => {
      result.json().then((res) => {
        setCollege(res.data);
      });
    });
  }, [collegeURL]);

  const handleCollege = (event) => {
    // const getCollegeId = event.target.value;
    // setCollegeId(getCollegeId);
  };
  const handleDelete = (i) => {
    let temp = [...formValues];
    temp.splice(i, 1);
    setFormValues(temp);
  };

  // const courseURL =  collegeId ? "http://3.109.83.81/phc-admin/api/course?college_id=" + collegeId : "http://3.109.83.81/phc-admin/api/course?college_id=63aa7d3c3cab8";

  const courseURL = collegeId
    ? `${baseUrlAPI}/api/course?college_id=` + collegeId
    : `${baseUrlAPI}/api/course?college_id=63cb863f0ef37`;

  useEffect(() => {
    fetch(courseURL).then((result) => {
      result.json().then((res) => {
        setCourse(res);
      });
    });
  }, [courseURL]);

  const renderErrors = () => {
    if (error.length != 0) {
      return (
        <Alert severity="error">
          {/* <AlertTitle>Error</AlertTitle> */}
          {error.map((e) => {
            return (
              <div>
                <strong>{e}</strong>
                <br />
              </div>
            );
          })}
        </Alert>
      );
    } else {
      return;
    }
  };
  const renderSuccess = () => {
    if (success) {
      return (
        <Alert severity="success">
          {/* <AlertTitle>Error</AlertTitle> */}
          <b>{success}</b>
        </Alert>
      );
    } else {
      return;
    }
  };

  const renderLabel = (i) => {
    switch (true) {
      case i === 0:
        return <label htmlFor="college_name">MBA *</label>;
      case i === 1:
        return <label htmlFor="college_name">Graduation *</label>;
      case i === 2:
        return <label htmlFor="college_name">XII *</label>;
      case i === 3:
        return <label htmlFor="college_name">X *</label>;
    }
  };
  return (
    <>
      <div className="Free-Today">
        <div className="container">
          <div className="cen-start-cen">
            <h5 className="reg-to">Register</h5>
            <h1 className="reg-start">
              Start for <span>Free Today</span>{" "}
            </h1>
            <p className="reg-para">
              Access to all features. No credit card required.
            </p>
          </div>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={1} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>

        <div className=" space-zero">
          <div className="container">
            <form
              action=""
              style={{
                padding: "25px 50px ",
                boxShadow: "0px 0px 40px rgba(0,0,0,.05)",
                background: "white",
                borderRadius: "20px",
              }}
            >
              {renderErrors()}
              {renderSuccess()}
              <div className="flex-h-ad-m">
                {/* <h4 className="add-m-ore-gold" onClick={() => navigate(-1)}>
                  Go Back
                </h4> */}
                <h4 className="post-gr-col-font">Education Details</h4>
                {/* <h4 className="add-m-ore-gold" onClick={() => addFormFields()}>
                  Add More
                </h4> */}
                <h4
                  className="add-int-li-pro"
                  onClick={() => navigate("/register")}
                >
                  Go Back
                </h4>
              </div>
              {formValues.map((element, index) => (
                <div name="input" key={index}>
                  <div className="reg2">
                    <div className="school">
                      {/* <label htmlFor="college_name">{index === 0 College/School*}</label>{" "} */}
                      {isback ? (
                        <label htmlFor="college_name">
                          Qualification {index + 1}
                        </label>
                      ) : (
                        renderLabel(index)
                      )}
                      <br />
                      <input
                        defaultValue={element.college}
                        id="college_name"
                        className="input"
                        onChange={(e) => {
                          handleCollege(e);
                          e.target.name = "college";
                          handleChange(index, e);
                        }}
                        placeholder={"Enter the institute's name"}
                      />
                    </div>
                    <div className="pro">
                      <label htmlFor="">Degree Name *</label> <br />
                      <input
                        id="college_name"
                        className="input"
                        defaultValue={element.degree || ""}
                        onChange={(e) => {
                          e.target.name = "degree";

                          handleChange(index, e);
                        }}
                        placeholder={"Specialization"}
                      />
                    </div>
                  </div>

                  <div className="reg3">
                    <div className="pass-out-year-h">
                      <label htmlFor="">Joining Year *</label> <br />
                      <input
                        type="number"
                        name="i_year"
                        className="input"
                        placeholder="Pass In Year"
                        value={element.i_year || ""}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>

                    <div className="pass-out-year-h-mm">
                      <label htmlFor="">Pass Out Year*</label> <br />
                      <input
                        type="number"
                        className="input"
                        name="o_year"
                        placeholder="Pass Out Year"
                        value={element.o_year || ""}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>

                    <div className="pass-out-year-h-mm">
                      <label htmlFor="">Percentage*</label> <br />
                      <input
                        type="number"
                        className="input input_percentage_border"
                        placeholder="Grade"
                        name="score"
                        value={element.score || ""}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="note-res">
                    <h5>
                      Note : If your score was not in percentage then please
                      convert it to percentage.
                    </h5>
                  </div>
                  {isback ? (
                    <button
                      className="Updates"
                      onClick={(e) => {
                        e.preventDefault();
                        update(index);
                      }}
                    >
                      Update
                    </button>
                  ) : null}

                  <div className="token-education">
                    <input
                      className="input"
                      type="hidden"
                      placeholder="Token"
                    />
                  </div>
                  <hr />
                </div>
              ))}
            </form>

            {/* <div className="ml-fp-r">
          <FormControlLabel control={<Checkbox  />} label="Agree our terms and policy" className='check-label'/>
          <button className='btn-label' >Back</button>
          </div> */}

            <div className="r-btn-next">
              <button
                onClick={(e) => {
                  isback
                    ? navigate("/candidate-experience")
                    : goToExperience(e);
                }}
              >
                Proceed To Next Step
              </button>
            </div>
            {/* <div className="reg1-sign">
              <p>
                Already have an account? <span>Sign In Now.</span>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Education;
