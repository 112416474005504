import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import baseUrlAPI from "../BaseURL";
import NavigateTo from "../NavigateTo";
const ProfileSnapExperience = () => {
  const [experience, setExperience] = useState(null);
  const token = localStorage.getItem("candidateToken");
  const uid = localStorage.getItem("uid");
  const [role, setRole] = useState([]);
  const getExperience = async () => {
    const { data } = await axios.post(`${baseUrlAPI}/api/Current_experience`, {
      candidate_id: uid,
      token,
    });
    console.log({ exp: data[0] });
    setExperience(data[0]);
    // console.log;
  };
  const getRoles = async () => {
    if (!experience) {
      return;
    }
    const { data } = await axios.get(`${baseUrlAPI}/api/getRole`);
    console.log({ data });
    const expRole = data.data.find((e) => {
      return (e.uid = experience.role_id);
    });
    console.log({ expRole });
    setRole(expRole);
  };
  useEffect(() => {
    getExperience();
  }, []);
  useEffect(() => {
    getRoles();
  }, [experience]);
  if (!experience) {
    return <NavigateTo label={"Experience"} url={"candidate-experience"} />;
  }
  return (
    <div>
      <div>
        <strong>Designation : </strong>
        {experience.current_role_name}
      </div>
      <div>
        <strong>Company Name : </strong>
        {experience.current_organization}
      </div>
      <div>
        <strong>Domain : </strong>
        {role?.role}
      </div>
    </div>
  );
};

export default ProfileSnapExperience;
