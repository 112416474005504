import React, { useEffect } from "react";
import axios from "axios";
const LinkdenSuccess = (props) => {
  //   useEffect(() => {
  //     getAccess(window.location.search.split("=")[1]);
  //   }, []);
  const getAccess = async (code) => {
    try {
      const data = await axios.get(
        `http://localhost:3001/getUserCredentials?code=${code}`
      );
      console.log(data);
    } catch (e) {
      console.log(e);
    }
  };
  const sendDataToParent = (data) => {
    window.opener.postMessage(data, "*");
    window.close();
  };
  useEffect(() => {
    // const { code } = this.props.match.params; // Assuming the authorization code is passed as a parameter in the URL
    const code = window.location.search.split("=")[1];

    window.opener.postMessage({ type: "authorization_code", code }, "*");
    window.close();
  }, []);
  const onSuccess = async (accessToken) => {
    try {
      const {
        data: { localizedLastName, localizedFirstName, profilePicture },
      } = await axios.get(
        "https://api.linkedin.com/v2/me?projection=(id,firstName,lastName,profilePicture(displayImage~:playableStreams))",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(
        localizedFirstName,
        localizedLastName,
        profilePicture["displayImage~"].elements[0].identifiers[0].identifier
      );
    } catch (err) {
      console.error(err);
    }
  };

  console.log(window.location.search);
  return <div>Login succcess please dont close the window</div>;
};

export default LinkdenSuccess;
