import React from "react";
import { useState } from "react";
import baseUrlAPI from "./BaseURL";

const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [category, setCategory] = useState("Message Category");
  const [description, setDescription] = useState("");

  const [err, setErr] = useState({});
  const [sendMessage, setSendMessage] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    if (!name) {
      errors.name = " Please Fill Name";
    }
    if (!email) {
      errors.email = "Please Fill Email";
    }
    if (
      !email
        .toLocaleLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      errors.email = "Email is not valid";
    }
    if (!mobile) {
      errors.mobile = "Please Fill Mobile Number";
    }
    if (mobile.length !== 10) {
      errors.mobile = "Please Enter 10-digit Mobile Number";
    }
    if (isNaN(mobile)) {
      errors.mobile = "Mobile number is not valid";
    }
    if (!category) {
      errors.category = "Please Fill Category";
    }
    if (!description) {
      errors.description = "Please Fill Description";
    }
    if (Object.keys(errors).length === 0) {
      // submit the form
      setErr({});
      setSuccess(true);
      console.log("form submitted successfully ");
    } else {
      return setErr(errors);
    }

    const addContact_query_URL = `${baseUrlAPI}/api/addContact_query`;

    const token = localStorage.getItem("candidateToken");

    const Add_Contact_OBJ = {
      name,
      email,
      mobile,
      category,
      description,
      token,
    };

    fetch(addContact_query_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(Add_Contact_OBJ),
    }).then((result) => {
      result.json().then((res) => {
        console.log("res", res);
        setSendMessage(true);
        resetFields();
      });
    });
  };
  const resetFields = () => {
    setName("");
    setEmail("");
    setMobile("");
    setDescription("");
  };

  return (
    <div>
      <section className="day-cards">
        <div className="Get-in-Touch globalPadding">
          <div className="git-flex container">
            <div className="git-flex1">
              <h1>
                {/* Get in <span>Touch</span> */}
                Get in Touch
              </h1>

              {err.name && <div className="error">{err.name}</div>}
              {err.category && <div className="error">{err.category}</div>}
              {err.email && <div className="error">{err.email}</div>}
              {err.mobile && <div className="error">{err.mobile}</div>}
              {err.description && (
                <div className="error">{err.description}</div>
              )}

              <form onSubmit={handleSubmit}>
                <div className="name">
                  <input
                    type="text"
                    placeholder="Enter Your Name"
                    className="getInTouchFormField"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <br />
                  <select
                    className="getInTouchFormField"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="disabled">Message Category</option>
                    <option value="General">General</option>
                    <option value="Job Reference">Job Reference</option>
                    <option value="Recruiter">Recruiter</option>
                  </select>
                </div>

                <div className="name">
                  <input
                    type="text"
                    placeholder="Your Email"
                    className="getInTouchFormField"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Your Number"
                    className="getInTouchFormField"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
                <div>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Your Message Description"
                    className="getInTouchFormField"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="git-btn-flex container">
                  {success ? (
                    <p className="success-message">
                      Form submitted successfully!
                    </p>
                  ) : (
                    <button type="submit">Submit</button>
                  )}
                </div>
              </form>
            </div>
            <div className="git-flex2">
              <img
                className="git-flex2-img1"
                src="https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGNvbnRhY3R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                alt=""
              />
              <img
                className="git-flex2-img2"
                src="https://media.istockphoto.com/photos/colleagues-discussing-business-picture-id1319571139?b=1&k=20&m=1319571139&s=170667a&w=0&h=kh-o5VxMCPPeF9bwjQB2qtsZGsmgiXhVX3X9WUEmrCQ="
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Form;
