import react from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const AddExperience = ({ type, handleChange }) => {
  return (
    <div name="input" className="or-add-more-ad">
      <div className="org-lo row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label htmlFor="">Job Title *</label> <br />
          <input
            className="input"
            type="text"
            placeholder="Job Profile"
            name="job"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label htmlFor="">Company Name *</label> <br />
          <input
            className="input"
            type="text"
            placeholder="Company Name"
            name="company"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>

      <div className="org-lo row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label htmlFor="">Starting year *</label> <br />
          <input
            className="input"
            type="month"
            placeholder="Starting year"
            name="i_year"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label htmlFor="">Completion Year *</label> <br />
          <input
            type="month"
            placeholder="Completion Year"
            name="o_year"
            onChange={(e) => handleChange(e)}
            // className={element.job_role ? "hide-cy" : "input"}
          />
        </div>
        <div className="ml-fp-r">
          {
            <FormControlLabel
              control={
                <Checkbox name="job_role" onChange={(e) => handleChange(e)} />
              }
              label="This is my current job role"
              className="check-label"
            />
          }
        </div>
        <div className="org-lo ">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <label htmlFor="">Location *</label> <br />
            <input
              type="text"
              className="loc-loc-1px"
              placeholder="Location"
              name="location"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="j-titlee">
        <label htmlFor="">
          Role & Responsibilities*{" "}
          <span className="red-span">(Add ";" for adding bullet point)</span>
        </label>{" "}
        <br />
        <textarea
          id=""
          cols="30"
          rows="5"
          placeholder="Role & Responsibilities ...."
          name="description"
          onChange={(e) => handleChange(e)}
        ></textarea>
      </div>

      <div className="j-titlee">
        <label htmlFor="">
          Initiatives and Achievements*{" "}
          <span>
            <span className="red-span">(Add ";" for adding bullet point)</span>
          </span>
        </label>{" "}
        <br />
        <textarea
          id=""
          cols="30"
          rows="5"
          placeholder="Achievement ...."
          name="job_role"
          onChange={(e) => handleChange(e)}
        ></textarea>
      </div>
    </div>
  );
};

export default AddExperience;
