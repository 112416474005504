import React, { useEffect, useState } from "react";
import "../css/Job_Search.css";

import JobCard from "../component/Job_card";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WorkIcon from "@mui/icons-material/Work";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

import StarIcon from "@mui/icons-material/Star";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import { useLocation, useParams } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import JobCardSmall from "./JobCard_small";
// import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';

import { Modal, ModalBody, Row, Col } from "reactstrap";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import baseUrlAPI from "./BaseURL";
import axios from "axios";

const Job_Search = () => {
  const navigate = useNavigate();

  const goToJobSearch = (value) => {
    window.scrollTo(0, 0);
    navigate("/job-search/" + value?.uid);
  };

  const params = useParams();

  const jobDetailURL = `${baseUrlAPI}/api/singleJob?id=` + params?.uid;

  const [jobDetail, setJobDetail] = useState([]);
  const [job_id, setJob_id] = useState("");

  useEffect(() => {
    fetch(jobDetailURL, {}).then((result) => {
      result.json().then((res) => {
        // console.log(res);
        setJobDetail(res);
        setJob_id(res.uid);
        // console.log({ res });
      });
    });
  }, [jobDetailURL, job_id]);

  const jobURL = `${baseUrlAPI}/api/jobs?page=1`;

  const [job, setJob] = useState([]);

  useEffect(() => {
    fetch(jobURL).then((result) => {
      result.json().then((res) => {
        console.log({ j: res.data });
        setJob(res.data);
      });
    });
  }, [jobURL]);

  // const navigate = useNavigate();

  const [popup, setPopup] = useState(false);

  const [is_dreamjob, setIs_dreamjob] = useState("Yes");
  const [remarks, setRemarks] = useState("");

  const PopUp = (e) => {
    e.preventDefault();
    navigate("/applied-job");
    window.scrollTo(0, 0);

    const tokenid = localStorage.getItem("candidateToken");
    const candidate_id = localStorage.getItem("uid");

    const obj_dataa = {
      job_id: job_id,
      token: tokenid,
      candidate_id: candidate_id,
      is_dreamjob,
      remarks,
    };

    console.log(JSON.stringify(obj_dataa));

    const JobApplyURL = `${baseUrlAPI}/api/JobApply`;
    fetch(JobApplyURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj_dataa),
    })
      .then((result) => result.json())

      .then((data) => {
        console.log("result", data);
        if (data.status === 200) {
          alert("success");
        }
      })

      .catch((e) => {
        console.log("e", e);
      });
  };

  const applyNow = (e) => {
    e.preventDefault();
    setPopup(true);
  };

  const [saveup, setSaveup] = useState(false);
  // console.log(saveup, "dfsdf");

  const PopUpSave = (e) => {
    e.preventDefault();
    navigate("/saved-jobs");
    window.scrollTo(0, 0);

    const tokenid = localStorage.getItem("candidateToken");

    const candidate_id = localStorage.getItem("uid");

    const obj_dataa = {
      job_id: job_id,
      token: tokenid,
      candidate_id: candidate_id,
    };

    console.log(JSON.stringify(obj_dataa));

    const SavejobURL = `${baseUrlAPI}/api/Savejob`;
    fetch(SavejobURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj_dataa),
    })
      .then((result) => result.json())

      .then((data) => {
        console.log("result", data);
        if (data.status === 200) {
          alert("success");
        }
      })
      .catch((e) => {
        console.log("e", e);
      });
  };
  const dateDiffInDays = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  };
  const [similarJobs, setSimilarJobs] = useState(null);
  const getSimilarJob = async () => {
    if (jobDetail.length === 0) return;
    const jobURL = `${baseUrlAPI}/api/jobs?page=${1}`;
    const { data } = await axios.get(
      `${jobURL}&keyword=${jobDetail?.job_title?.split(" ")[0]}`
    );
    console.log({ dataOfSim: data.data });
    setSimilarJobs(data.data);
  };
  useEffect(() => {
    getSimilarJob();
  }, [jobDetail]);
  const saveJob = (e) => {
    e.preventDefault();
    setSaveup(true);

    const tokenid = localStorage.getItem("candidateToken");

    const candidate_id = localStorage.getItem("uid");

    const obj_dataa = {
      job_id: job_id,
      token: tokenid,
      candidate_id: candidate_id,
    };

    console.log(JSON.stringify(obj_dataa));

    const SavejobURL = `${baseUrlAPI}/api/Savejob`;
    fetch(SavejobURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj_dataa),
    })
      .then((result) => result.json())

      .then((data) => {
        console.log("result", data);
        if (data.status === 200) {
          alert("success");
        }
      })

      .catch((e) => {
        console.log("e", e);
      });
  };
  if (!jobDetail) {
    return <div>Loading</div>;
  }

  return (
    <div className="b-h-bacj">
      <div className="Job-Details">
        <h1 className="jd-heading">Job Details</h1>
      </div>
      <Modal size="sm" isOpen={popup} toggle={() => setPopup(!popup)}>
        {/* <ModalHeader toggle={()=>setPopup(!popup)}>
            PopUp
          </ModalHeader> */}
        <ModalBody>
          <form action="">
            <Row>
              <Col lg={12}>
                <div className="popup">
                  <div className="popup-ap">
                    <div className="ap-icn">
                      <CheckCircleOutlineIcon className="icn-icn" />
                    </div>
                    <h2 className="popup-h2-ap">Apply Now</h2>
                  </div>
                  {/* <label htmlFor="">Is your Dream Job?</label> <br />
                    <select name="" id="" value={is_dreamjob} onChange={(e)=>setIs_dreamjob(e.target.value)}>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select> <br /> */}
                  <label htmlFor="">Remark</label> <br />
                  <input
                    type="text"
                    placeholder="Good"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={is_dreamjob}
                        onChange={(e) => setIs_dreamjob(e.target.checked)}
                      />
                    }
                    label="This is my Dream Job"
                  />{" "}
                  <br />
                </div>

                <button onClick={PopUp} className="ok_popup">
                  Apply Job
                </button>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={saveup} toggle={() => setSaveup(!saveup)}>
        {/* <ModalHeader toggle={()=>setPopup(!popup)}>
            PopUp
          </ModalHeader> */}
        <ModalBody>
          <form action="">
            <Row>
              <Col lg={12}>
                <div className="ap-icn">
                  <BookmarkBorderIcon className="icn-icn" />
                </div>
                <h6 className="saveup-cen">Job are saved</h6>

                <button onClick={PopUpSave} className="ok_popup">
                  Return to Saved Jobs
                </button>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>

      {/* UI/UX Desginer Full Time */}
      <div className="">
        <div className="job-search-pad ">
          <div className="container  kakakak">
            <div className="job-details-content container ">
              <div className="jd-content-left">
                <div className="jd-content-left-img">
                  <div className="jobCardImageContainer">
                    <img
                      src={
                        jobDetail.company_logo !== "" || !job.company_logo
                          ? jobDetail.company_logo
                          : `https://cdn-icons-png.flaticon.com/512/174/174857.png`
                      }
                      className="jobCardImage"
                    />
                  </div>

                  {/* <LinkedInIcon className="link-icon" sx={{ fontSize: 60 }} /> */}
                </div>
                <div className="jd-content-left-text">
                  <h5>{jobDetail.job_title}</h5>
                  <div className="jd-content-f">
                    <span>
                      <LocationOnIcon /> {jobDetail.job_location}
                    </span>
                    <span>
                      <AccessTimeIcon />{" "}
                      {dateDiffInDays(
                        new Date(jobDetail?.date_added),
                        new Date(Date.now())
                      )}
                      &nbsp; days ago
                    </span>
                    <span>
                      <WorkIcon /> {jobDetail.employment_type}
                    </span>
                  </div>
                </div>
              </div>
              <div className="jd-content-rightt ">
                <button className="save-jpb-api" onClick={saveJob}>
                  Save Job
                </button>
                <button className="app-now-api" onClick={applyNow}>
                  Apply Now
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="job-details-pad ">
          <div className="container kakakak">
            <div className="job-details-sub-content container">
              <div className="row">
                <div className="job-details-sub-content-left col-lg-8 col-md-12 col-sm-12 col-xs-12">
                  <div className="jd-left-border">
                    <h4>Employment Information</h4> <br />
                    <div className="ei-flex">
                      <div className="ei1">
                        <div className="ei1-f">
                          <p className="ei-head">Industry</p>
                          <p>{jobDetail.industry}</p>
                        </div>{" "}
                        <br />
                        <div className="ei1-f">
                          <p className="ei-head"> Median Salary Expected</p>
                          <p>{jobDetail.job_salary}</p>
                        </div>{" "}
                        <br />
                        <div className="ei1-f">
                          <p className="ei-head">Job Type</p>
                          <p>{jobDetail.employment_type}</p>
                        </div>
                      </div>
                      <div className="ei2">
                        <div className="ei2-f">
                          <p className="ei-head"> Min Exp Required </p>
                          <p>{jobDetail.req_experience} Years</p>
                        </div>{" "}
                        <br />
                        <div className="ei2-f">
                          {/* <p className="ei-head">Deadline</p>
                          <p>10/08/2022</p> */}
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <br /> <br />
                  <div className="welcome-m">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: jobDetail.job_description,
                      }}
                    />
                    <br /> <br />
                    <div className="jd-wel-flex">
                      <div className="btn-jd-wel">
                        <button className="jd-wel-btn-apply" onClick={applyNow}>
                          Apply Now
                        </button>
                        <button className="jd-wel-btn-save" onClick={saveJob}>
                          Save Job
                        </button>
                      </div>

                      <div className="share-icon">
                        <p>Share This</p>
                        <FacebookIcon
                          className="share-icn-brd"
                          sx={{ fontSize: 18 }}
                        />
                        <TwitterIcon
                          className="share-icn-brd"
                          sx={{ fontSize: 18 }}
                        />
                        <InstagramIcon
                          className="share-icn-brd"
                          sx={{ fontSize: 18 }}
                        />
                        <YouTubeIcon
                          className="share-icn-brd"
                          sx={{ fontSize: 18 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="job-details-sub-content-right col-lg-4 col-md-12 col-sm-12 col-xs-12">
                  <div className="jd-right-similar">
                    <h4 className="similar-head-top">Similar Jobs</h4>
                    {similarJobs && similarJobs.length !== 0
                      ? similarJobs.map((e, i) => {
                          console.log({ e: e.uid, job: job.uid });
                          if (e.uid === job_id) {
                            return null;
                          }
                          return <JobCardSmall jobDetail={e} key={i} />;
                        })
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Featured Jobs */}

      <div className="Featured-Jobs">
        <div className="container kakakak">
          <h1 className="fj-heading">Featured Jobs</h1>
          <p className="fj-para">Get the latest news, updates and tips</p>{" "}
          <br /> <br />
          <div className="day-cards">
            {job.map((value, i) => {
              return <JobCard job={value} print={0} />;
            })}
          </div>
          <div className="cen-btn">
            <button className="fj-btn">View More Jobs</button>
          </div>
        </div>
      </div>

      {/* Get in Touch */}

      <div className="day-cards">
        <div className="Get-in-Touch globalPadding">
          <div className="git-flex container">
            <div className="git-flex1">
              <h1>
                {/* Get in <span>Touch</span> */}
                Get in Touch
              </h1>
              <p className="git-flex1-text">
                {/* Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas <br />
                accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices
                gravida. */}
              </p>
              <div className="name">
                <input
                  type="text"
                  placeholder="Enter Your Name"
                  className="getInTouchFormField"
                />
                {/* <input type="text" placeholder="Category (Optional)" /> */}
                <select className="getInTouchFormField">
                  <option value="disabled">Message Category</option>
                  <option value="General">General</option>
                  <option value="Job Reference">Job Reference</option>
                  <option value="Recruiter">Recruiter</option>
                </select>
              </div>

              <div className="name">
                <input
                  type="text"
                  placeholder="Your Email"
                  className="getInTouchFormField"
                />
                <input
                  type="text"
                  placeholder="Your Number"
                  className="getInTouchFormField"
                />
              </div>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Your Message Description"
                className="getInTouchFormField"
              />
            </div>
            <div className="git-flex2">
              <img
                className="git-flex2-img1"
                src="https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGNvbnRhY3R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                alt=""
              />
              <img
                className="git-flex2-img2"
                src="https://media.istockphoto.com/photos/colleagues-discussing-business-picture-id1319571139?b=1&k=20&m=1319571139&s=170667a&w=0&h=kh-o5VxMCPPeF9bwjQB2qtsZGsmgiXhVX3X9WUEmrCQ="
                alt=""
              />
            </div>
          </div>
          <div className="git-btn-flex container">
            <button>Send Message</button>
            {/* <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="By clicking contact us button, you agree our terms and policy,"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Job_Search;
