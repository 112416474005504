import axios from "axios";
import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";

import { useNavigate } from "react-router-dom";

import "../css/GenerateCV.css";
import { Buffer } from "buffer";

import baseUrlAPI from "./BaseURL";

const GenerateCV = () => {
  const uid = localStorage.getItem("uid");
  useEffect(() => {
    if (uid) {
      navigate("/generate-cv");
      window.scrollTo(0, 0);
    } else {
      navigate("/register");
      window.scrollTo(0, 0);
    }
  }, []);
  const navigate = useNavigate();
  const [cv, setCv] = useState(null);

  // const goToMessages = () => {
  //     window.scrollTo(0, 0)
  //     navigate('/messages')
  // }

  const goToMyProfile = () => {
    window.scrollTo(0, 0);
    navigate("/my-profile");
  };

  const goToSavedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/saved-jobs");
  };

  const goToAppliedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/applied-job");
  };

  const goToProfileOverview = () => {
    window.scrollTo(0, 0);
    navigate("/dashboard");
  };

  const goToChangePassword = () => {
    window.scrollTo(0, 0);
    navigate("/change-password");
  };

  function base64ToArrayBuffer(data) {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
  const DownloadCV = async (e) => {
    e.preventDefault();

    const candidate_id = localStorage.getItem("uid");

    console.log(candidate_id);
    const DownloadResume = { uid: candidate_id };
    console.log(DownloadResume);

    const DownloadCVURL = `${baseUrlAPI}/api/pdf`;

    fetch(DownloadCVURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(DownloadResume),
    })
      .then((res) => res.blob())
      .then((data) => {
        saveAs(data, "cv.pdf");
      });
  };
  console.log(cv);

  return (
    <>
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 className="dashboard-heading-d">Generate CV</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="Profile-Overview">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
              <div className="flex-p-overview-mh">
                <p className="bol-pro" onClick={goToProfileOverview}>
                  <ion-icon name="search-circle-outline"></ion-icon> Profile
                  Overview
                </p>
                <p className="bol-pro" onClick={goToAppliedJobs}>
                  <ion-icon name="business-outline"></ion-icon> Applied Jobs
                </p>
                <p className="bol-pro" onClick={goToSavedJobs}>
                  <ion-icon name="bookmark-outline"></ion-icon> Saved Jobs
                </p>
                <p className="bol-pro" onClick={goToMyProfile}>
                  <ion-icon name="person-circle-outline"></ion-icon> My Profile
                </p>
                {/* <p className='bol-pro' onClick={goToMessages}><ion-icon name="chatbox-ellipses-outline"></ion-icon> Messages</p> */}
                <p className="pro-over-col bol-pro">
                  <ion-icon name="document-outline"></ion-icon> Generate CV
                </p>
                <p className="bol-pro" onClick={goToChangePassword}>
                  <ion-icon name="lock-closed-outline"></ion-icon> Change
                  Password
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Deactivate
                  Account
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Log Out
                </p>
              </div>
            </div>

            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
              <div className="pad-col-dash-appl">
                <h5 className="generate-cv-pro">
                  Generate CV from Profile Details
                </h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                  aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                  nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                  aliquip ex ea commodo consequat. Duis autem vel eum iriure
                  dolor in hendrerit in vulputate velit esse molestie consequat.
                </p>
                <button className="btn-n-pad-mar-a" onClick={DownloadCV}>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateCV;
