import React, { useState, useEffect } from "react";

import "../css/Others.css";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useSubmit } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';

import baseUrlAPI from "./BaseURL";
import { Alert } from "@mui/material";
// import RegPreference from ".component/RegPrefrence";

const steps = [
  "Basic Info",
  "Education",
  "Work Experience",
  "Others",
  "Preference",
];

const Others = () => {
  const uid = localStorage.getItem("uid");
  useEffect(() => {
    if (uid) {
      navigate("/others");
      window.scrollTo(0, 0);
    } else {
      navigate("/register");
      window.scrollTo(0, 0);
    }
  }, []);

  // post api internship

  // const [title, setTitle] = useState("");
  // const [starting_year, setStarting_year] = useState("");
  // const [completion_year, setCompletion_year] = useState("");
  // const [description, setDescription] = useState("");

  // post api curricular

  // const [title_c, setTitle_c] = useState("");
  // const [year, setYear] = useState("");
  // const [description_c, setDescription_c] = useState("");

  // post api licence

  // const [title_l, setTitle_l] = useState("");
  // const [year_l, setYear_l] = useState("");
  // const [category, setCategory] = useState("");
  // const [organization, setOrganization] = useState("");
  // const [description_l, setDescription_l] = useState("");
  const [isBack, setIsBack] = useState(false);
  // post api internship
  const [errors, setErrors] = useState([]);
  const handleSetError = (e) => {
    const temp = errors;
    temp.push(e);
    setErrors(temp);
  };

  const [formValues, setFormValues] = useState([
    { title: "", starting_year: "", completion_year: "", description: "" },
  ]);

  let handleChange = (i, e) => {
    console.log({ i, name: e.target.name, vlaue: e.target.value });

    let newFormValues = [...formValues];
    if (e.target.type === "checkbox") {
      newFormValues[i][e.target.name] = e.target.checked;
      setFormValues(newFormValues);
    } else {
      newFormValues[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);
    }
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { title: "", i_year: "", o_year: "", description: "" },
    ]);
  };

  // post api curricular

  const [curricularValues, setCurricularValues] = useState([
    { title_c: "", year: "", description_c: "" },
  ]);

  let handleChangeCurricular = (i, e) => {
    let newCurricularValues = [...curricularValues];

    newCurricularValues[i][e.target.name] = e.target.value;
    setCurricularValues(newCurricularValues);
  };

  let addFormFieldsCurricular = () => {
    setCurricularValues([
      ...curricularValues,
      { title_c: "", year: "", description_c: "" },
    ]);
  };

  // post api licence

  const [licenceValues, setLicenceValues] = useState([
    {
      title_l: "",
      year_l: "",
      category: "Licence",
      college: "",
      description_l: "",
    },
  ]);

  let handleChangeLicence = (i, e) => {
    let newLicenceValues = [...licenceValues];

    newLicenceValues[i][e.target.name] = e.target.value;
    setLicenceValues(newLicenceValues);
  };

  let addFormFieldsLicence = () => {
    setLicenceValues([
      ...licenceValues,
      {
        title_l: "",
        year_l: "",
        category: "Licence",
        college: "",
        description_l: "",
      },
    ]);
  };
  const tokenid = localStorage.getItem("candidateToken");
  const candidate_id = localStorage.getItem("uid");
  const navigate = useNavigate();
  const createInternships = () => {};
  const goToPreference = () => {
    const Internship = {
      form_data: formValues,
      token: tokenid,
      candidate_id: candidate_id,
    };
    const addInternshipURL = `${baseUrlAPI}/api/addInternship`;

    fetch(addInternshipURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(Internship),
    })
      .then((result) => result.json())
      .then((data) => {
        if (data.status === 200) {
          alert("success");
        }
      })

      .catch((e) => {});

    const Curricular = {
      form_dataa: curricularValues,
      token: tokenid,
      candidate_id: candidate_id,
    };

    const addCurricularURL = `${baseUrlAPI}/api/addCurricular`;

    fetch(addCurricularURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(Curricular),
    })
      .then((result) => result.json())
      .then((data) => {
        if (data.status === 200) {
          alert("success");
        }
      })

      .catch((e) => {});

    const Licence = {
      form_dataaa: licenceValues,
      token: tokenid,
      candidate_id: candidate_id,
    };

    const addLicenceURL = `${baseUrlAPI}/api/addLicence`;

    fetch(addLicenceURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(Licence),
    })
      .then((result) => result.json())
      .then((data) => {
        if (data.status === 200) {
          alert("success");
        }
      })

      .catch((e) => {});
    navigate("/preference");
  };

  const renderError = () => {
    return errors.map((e) => {
      return <Alert>{e}</Alert>;
    });
  };

  const [inputBox, setInputBox] = useState(false);
  const otherOption = (value) => {
    if (value === "Others") {
      setInputBox(true);
    } else {
      setInputBox(false);
    }
  };
  const handleDelete = (type, i) => {
    if (type === "int") {
      const temp = formValues;
      temp.splice(i, 1);
      setFormValues([...temp]);
    } else if (type === "cir") {
      const temp = curricularValues;
      temp.splice(i, 1);
      setCurricularValues([...temp]);
    } else {
      const temp = licenceValues;
      temp.splice(i, 1);
      setLicenceValues([...temp]);
    }
  };
  //GET INTERNSHIP
  const getDataInternship = async () => {
    const candidateToken = localStorage.getItem("candidateToken");
    const id = localStorage.getItem("uid");
    const { data } = await axios.post(`${baseUrlAPI}/api/getInternship`, {
      token: candidateToken,
      candidate_id: id,
    });

    const formData = data.map((el) => {
      return {
        title: el.title,
        starting_year: +el.starting_year,
        description: el.description,
        uid: el.uid,
      };
    });
    setFormValues(formData);
  };

  useEffect(() => {
    getDataInternship();
  }, []);
  //GET LINCENSE
  const updateLicences = async (i) => {
    const candidateToken = localStorage.getItem("candidateToken");
    const id = localStorage.getItem("uid");
    const dataToUpdate = licenceValues[i];
    const updateData = await axios.post(`${baseUrlAPI}/api/updateLicences`, {
      candidate_id: id,
      token: candidateToken,
      uid: dataToUpdate.uid,
      title: dataToUpdate.title_l,
      category: dataToUpdate.category,
      organization: dataToUpdate.college,
      year: dataToUpdate.year_l,
      description: dataToUpdate.description_l,
    });
    setSuccess("Licence updated successfully.");
    console.log({ updateData });
    try {
    } catch (e) {
      console.log(e);
    }
  };

  const getLincense = async () => {
    const candidateToken = localStorage.getItem("candidateToken");
    const id = localStorage.getItem("uid");
    const { data } = await axios.post(`${baseUrlAPI}/api/getLicence`, {
      token: candidateToken,
      candidate_id: id,
    });
    const form_dataaa = data.map((el) => {
      return {
        title_l: el.title,
        year_l: el.year,
        category: el.category,
        description_l: el.description,
        college: el.organization,
        uid: el.uid,
      };
    });
    setLicenceValues(form_dataaa);
  };
  const checkValues = (type) => {
    let dataExsits = false;
    switch (true) {
      case type === 1: //1 for internship data
        formValues.forEach((el) => {
          if (el.uid) {
            dataExsits = true;
          }
        });
        break;
      case type === 2: // 2 for licence
        licenceValues.forEach((el) => {
          if (el.uid) {
            dataExsits = true;
          }
        });
        break;
      case type === 3: // for cetificate
        curricularValues.forEach((el) => {
          if (el.uid) {
            dataExsits = true;
          }
        });
    }
    return dataExsits;
  };
  useEffect(() => {
    getLincense();
  }, []);
  // GET CURRIRCULAR
  const getCurrircular = async () => {
    const candidateToken = localStorage.getItem("candidateToken");
    const id = localStorage.getItem("uid");
    const { data } = await axios.post(`${baseUrlAPI}/api/getCurricular`, {
      token: candidateToken,
      candidate_id: id,
    });
    const form_dataa = data.map((el) => {
      return {
        title_c: el.title,
        year: el.year,
        description_c: el.description,
        uid: el.uid,
      };
    });
    setCurricularValues(form_dataa);
  };
  const updateCurricular = async (i) => {
    try {
      const candidateToken = localStorage.getItem("candidateToken");
      const id = localStorage.getItem("uid");
      const dataToUpdate = curricularValues[i];
      const updateData = await axios.post(
        `${baseUrlAPI}/api/updateCurricular`,
        {
          candidate_id: id,
          token: candidateToken,
          uid: dataToUpdate.uid,
          title: dataToUpdate.title_c,
          year: dataToUpdate.year,
          description: dataToUpdate.description_c,
        }
      );
      setSuccess("Extra – curricular updated successfully");

      console.log({ updateData });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getCurrircular();
  }, []);
  const [success, setSuccess] = useState("");
  const renderSuccess = () => {
    if (success) {
      return (
        <Alert severity="success">
          {/* <AlertTitle>Error</AlertTitle> */}
          <b>{success}</b>
        </Alert>
      );
    } else {
      return;
    }
  };

  //UPDATE INTERNSHIP
  const updateInternship = async (i) => {
    const itemToUpdate = formValues[i];
    const candidateToken = localStorage.getItem("candidateToken");
    const id = localStorage.getItem("uid");
    const { data } = await axios.post(`${baseUrlAPI}/api/updateInternship`, {
      token: candidateToken,
      candidate_id: id,
      uid: itemToUpdate.uid,
      title: itemToUpdate.title,
      starting_year: itemToUpdate.starting_year,
      description: itemToUpdate.description,
      completion_year: "-",
    });
    setSuccess("Internship updated successfully");
    console.log(data);
  };

  // useEffect(() => {
  //   updateInternship();
  // }, []);

  return (
    <>
      <section className="internship">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="cen-start-cen">
                <h5 className="reg-to">Register</h5>
                <h1 className="reg-start">
                  Start for <span>Free Today</span>{" "}
                </h1>
                <p className="reg-para">
                  Access to all features. No credit card required.
                </p>
              </div>

              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={3} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </div>
          </div>
        </div>

        <section className="intern-form">
          <div className="container">
            {renderSuccess()}
            <br />
            <h4
              className="add-int-li-pro"
              onClick={() => navigate("/candidate-experience")}
            >
              Go Back
            </h4>{" "}
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <h4 className="int-li-pro">Internship & Live Projects : </h4>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <h4 className="add-int-li-pro " onClick={() => addFormFields()}>
                  Add More
                </h4>
              </div>
            </div>
            {/* {errors.length !== 0 ? renderError() : null} */}
            {formValues.map((element, index) => {
              return (
                <div name="input" key={index}>
                  <div className="handleDelete">
                    {index === 0 ? null : (
                      <DeleteIcon
                        className="deleteIcon ab"
                        onClick={() => {
                          handleDelete("int", index);
                        }}
                      />
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="title-other">
                        <label htmlFor="">Title</label> <br />
                        <input
                          className="input"
                          type="text"
                          placeholder="Title"
                          name="title"
                          value={element.title || ""}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="title-other">
                        <label htmlFor=""> Year </label> <br />
                        {console.log({ starting: element })}
                        <input
                          type="number"
                          className="input"
                          placeholder="Starting Year"
                          name="starting_year"
                          value={element.starting_year || ""}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="des-other">
                        <label htmlFor="">
                          Description{" "}
                          <span className="red-span">
                            (Add ";" for adding bullet point)
                          </span>
                        </label>{" "}
                        <br />
                        <textarea
                          id=""
                          cols="30"
                          rows="5"
                          placeholder="Description ...."
                          className="input"
                          style={{ height: "200px" }}
                          name="description"
                          value={element.description || ""}
                          onChange={(e) => handleChange(index, e)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  {checkValues(1) ? (
                    <button
                      className="Updates"
                      onClick={(e) => {
                        e.preventDefault();
                        updateInternship(index);
                      }}
                    >
                      Update
                    </button>
                  ) : null}
                  <hr />
                </div>
              );
            })}
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <h4 className="int-li-pro">Licences, Cert & Workshops: </h4>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <h4
                  className="add-int-li-pro"
                  onClick={() => addFormFieldsLicence()}
                >
                  Add More
                </h4>
              </div>
            </div>
            {licenceValues.map((element, index) => {
              return (
                <div name="input" key={index}>
                  <div className="handleDelete">
                    {index === 0 ? null : (
                      <DeleteIcon
                        className="deleteIcon"
                        onClick={() => {
                          handleDelete("lic", index);
                        }}
                      />
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="title-other">
                        <label htmlFor="">Title </label> <br />
                        <input
                          type="text"
                          placeholder="Title"
                          name="title_l"
                          className="input"
                          value={element.title_l || ""}
                          onChange={(e) => handleChangeLicence(index, e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="title-other">
                        <label htmlFor="">Year </label> <br />
                        <input
                          className="input"
                          type="number"
                          placeholder="Year "
                          name="year_l"
                          value={element.year_l || ""}
                          onChange={(e) => handleChangeLicence(index, e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="title-other">
                        <label htmlFor="">Category </label> <br />
                        <select
                          name="category"
                          id=""
                          className="input"
                          style={{
                            // height: "60px",
                            padding: "0px 2px",
                            border: "border: 1px solid rgb(209, 209, 209) ",
                          }}
                          defaultValue={element.category}
                          onChange={(e) => {
                            handleChangeLicence(index, e);
                            otherOption(e.target.value);
                          }}
                        >
                          <option value="Licence">Licence</option>
                          <option value="Certificate">Certificate</option>
                          <option value="Workshop">Workshop</option>
                          <option value="Others">Others</option>
                        </select>
                        {/* {inputBox ? <input type="text" /> : null} */}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="title-other">
                        <label htmlFor=""> Institute Name </label> <br />
                        <input
                          type="text"
                          className="input"
                          placeholder=" Institute Name "
                          name="college"
                          value={element.college || ""}
                          onChange={(e) => handleChangeLicence(index, e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="des-other">
                        <label htmlFor="">
                          Description{" "}
                          <span className="red-span">
                            (Add ";" for adding bullet point)
                          </span>
                        </label>{" "}
                        <br />
                        <textarea
                          name="description_l"
                          className="input"
                          style={{ height: "200px" }}
                          id=""
                          cols="30"
                          rows="5"
                          placeholder="Description ...."
                          value={element.description_l || ""}
                          onChange={(e) => handleChangeLicence(index, e)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  {checkValues(2) ? (
                    <button
                      className="Updates"
                      onClick={(e) => {
                        e.preventDefault();
                        updateLicences(index);
                      }}
                    >
                      Update
                    </button>
                  ) : null}
                  <hr />
                </div>
              );
            })}
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <h4 className="int-li-pro">Extra – curricular: </h4>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <h4
                  className="add-int-li-pro"
                  onClick={() => addFormFieldsCurricular()}
                >
                  Add More
                </h4>
              </div>
            </div>
            {curricularValues.map((element, index) => {
              return (
                <div name="input" key={index}>
                  <div className="handleDelete">
                    {index === 0 ? null : (
                      <DeleteIcon
                        className="deleteIcon"
                        onClick={() => {
                          handleDelete("cir", index);
                        }}
                      />
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="title-other">
                        <label htmlFor="">Title </label> <br />
                        <input
                          className="input"
                          type="text"
                          placeholder="Title"
                          name="title_c"
                          value={element.title_c || ""}
                          onChange={(e) => handleChangeCurricular(index, e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="title-other">
                        <label htmlFor="">Year </label> <br />
                        <input
                          className="input"
                          type="number"
                          placeholder="Year "
                          name="year"
                          value={element.year || ""}
                          onChange={(e) => handleChangeCurricular(index, e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="des-other">
                        <label htmlFor="">
                          Description{" "}
                          <span className="red-span">
                            (Add ";" for adding bullet point)
                          </span>
                        </label>{" "}
                        <br />
                        <textarea
                          id=""
                          className="input"
                          style={{ height: "200px" }}
                          cols="30"
                          rows="5"
                          placeholder="Description ...."
                          name="description_c"
                          value={element.description_c || ""}
                          onChange={(e) => handleChangeCurricular(index, e)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  {checkValues(3) ? (
                    <button
                      className="Updates"
                      onClick={(e) => {
                        e.preventDefault();
                        updateCurricular(index);
                      }}
                    >
                      Update
                    </button>
                  ) : null}
                  <hr />
                </div>
              );
            })}
            {/* <div className="ml-fp-r">
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Agree our terms and policy"
                            className="check-label"
                        />
                        <button className="btn-label">Back</button>
                    </div> */}
            <div className="button-container">
              <div className="skip-button">
                <button
                  onClick={() => {
                    navigate("/preference");
                  }}
                >
                  Skip
                </button>
              </div>
              <div className="r-btn-next">
                <button
                  onClick={(e) => {
                    if (checkValues(1) || checkValues(2) || checkValues(3)) {
                      console.log("NODATA");
                      navigate("/preference");
                    } else {
                      goToPreference(e);
                    }
                  }}
                >
                  Proceed To Next Step
                </button>
              </div>
            </div>
            {/* <div className="reg1-sign">
                        <p>
                            Already have an account? <span>Sign In Now.</span>
                        </p>
                    </div> */}
          </div>
        </section>
      </section>
    </>
  );
};

export default Others;
