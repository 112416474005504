import axios from "axios";
import React from "react";
import { useState } from "react";
import baseUrlAPI from "../BaseURL";
import { useEffect } from "react";
import "../../css/ProfileSnapshot.css";

const ProfileSnapshot = () => {
  const [profile, setProfile] = useState(null);
  const token = localStorage.getItem("candidateToken");
  const uid = localStorage.getItem("uid");
  console.log({ uid, token });
  const getProfile = async () => {
    const { data } = await axios.post(`${baseUrlAPI}/api/getProfile`, {
      id: uid,
      token,
    });
    setProfile(data[0]);
    console.log({ da: data[0] });
  };
  useEffect(() => {
    getProfile();
  }, []);
  if (!profile) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <div>
        <strong>Name : </strong>
        {profile.first_name}&nbsp;
        {profile.last_name}
      </div>
      <div>
        <strong> Email Id : </strong>
        {profile.email}
      </div>

      <div>
        {" "}
        <strong> Phone : </strong> {profile.mobile}
      </div>
      <div>
        <strong> Location : </strong>
        {profile.current_location}
      </div>

      {/* <div>
        <strong> MBA / Work Domain : </strong>
        {profile.mba_specialization}
      </div> */}
    </div>
  );
};

export default ProfileSnapshot;
