import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import baseUrlAPI from "../BaseURL";
import NavigateTo from "../NavigateTo";

const ProfileSnapCurricular = () => {
  const [curricular, setCurricular] = useState(null);

  const token = localStorage.getItem("candidateToken");
  const uid = localStorage.getItem("uid");
  console.log({ uid, token });
  const getCurricular = async () => {
    const { data } = await axios.post(`${baseUrlAPI}/api/getCurricular`, {
      candidate_id: uid,
      token,
    });
    setCurricular(data[0]);
    console.log({ curr: data[0] });
  };
  useEffect(() => {
    getCurricular();
  }, []);
  if (!curricular) {
    return <NavigateTo url={"my-profile-others"} label={"Curricular"} />;
  }
  return (
    <div>
      <div>
        <strong>Curricular Skills : </strong>
        {curricular.title}
      </div>
    </div>
  );
};

export default ProfileSnapCurricular;
