import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import baseUrlAPI from "../BaseURL";
import NavigateTo from "../NavigateTo";

const ProfileSnapLicences = () => {
  const [License, setLicense] = useState(null);
  const token = localStorage.getItem("candidateToken");
  const uid = localStorage.getItem("uid");
  console.log({ uid, token });
  const getLicense = async () => {
    const { data } = await axios.post(`${baseUrlAPI}/api/getLicence`, {
      candidate_id: uid,
      token,
    });
    setLicense(data[0]);
    console.log({ da: data[0] });
  };
  useEffect(() => {
    getLicense();
  }, []);
  if (!License) {
    return <NavigateTo url={"my-profile-others"} label={"Licences"} />;
  }
  return (
    <div>
      <strong>Category : </strong>
      {License.category}
      <div>
        <strong>Description : </strong>
        {License.description}
      </div>
      <div>
        <strong>Organisation : </strong>:{License.organization}
      </div>
      <div>
        <strong>Title : </strong>
        {License.title}
      </div>
      <div>
        <strong>Year : </strong>
        {License.year}
      </div>
    </div>
  );
};

export default ProfileSnapLicences;
