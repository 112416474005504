import React from "react";
import "../css/Privacy_Policy.css";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Terms = () => {
  return (
    <div className="ba-ba-com">
      <div className="Privacy-Policy">
        <h1 className="pp-heading">Terms and Conditions</h1>
      </div>

      <div className="pp-heading-main">
        <div className="container cokkkk">
          <div className="pp-heading-1">
            <div className="pph-flex">
              <hr className="pph-hr" />
              <p className="pph-flex-para">
                We, at PHC are committed to respecting your online privacy and
                recognize the need for appropriate protection and management of
                any personally identifiable information you share with us.
              </p>
            </div>{" "}
            <br />
            <div className="pph-flex">
              <hr className="pph-hr" />
              <p className="pph-flex-para">
                We will only use your personal data in a fair and reasonable
                manner, and where we have a lawful reason to do so. We may
                process your Personal Information for providing our services and
                products to you including to send you job alerts, calendar
                alerts, relevant search results, recommended jobs and/or
                candidates (as the case maybe), and other social media
                communication facilities.
              </p>
            </div>{" "}
            <br />
            <div className="pph-flex">
              <hr className="pph-hr" />
              <p className="pph-flex-para">
                To use the Platform, you agree that you must be the minimum age,
                18 years or older
              </p>
            </div>{" "}
            <br />
            <div className="pph-flex">
              <hr className="pph-hr" />
              <p className="pph-flex-para">
                Your Personal Information will not be retained by PHC any longer
                than it is necessary for the purposes for which the Personal
                Information is processed and/or in accordance with legal,
                regulatory, contractual or statutory obligations as applicable.
                At the expiry of such periods, your Personal Information will be
                deleted or archived in compliance with applicable laws.
              </p>
            </div>{" "}
            <br />
            <div className="pph-flex">
              <hr className="pph-hr" />
              <p className="pph-flex-para">
                IEIL reserves the right to update, change or modify this Privacy
                Policy at any time. The Privacy Policy shall come to effect from
                the date of publication of such update, change or modification.
              </p>
            </div>{" "}
            <br />
            {/* <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'> 
                Please maintain the professional conduct throughout the recruitment process with the employers. Complaints regarding rude, 
                indecent or careless behaviour towards employers may lead to debarring from our website
            </p>
        </div> <br />
        <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'> 
                We are available in case you face any difficulty or issue during the recruitment process. But please acknowledge that 
                we are a very small team now working with very stringent timelines and we might take some time responding to your queries
            </p>
        </div>  */}
          </div>
          {/* <br /> <br />
      <hr />
      <br /> */}

          {/* <div className="pp-heading-1">
        <h1 className="pph">Lorem Ipsum Dolor</h1>

        <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'> 
                The objective of this website is to make the recruitment process simpler and faster for both the recruiters 
                and the job seekers. To fulfil our mission we need your support!! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit, commodi!
            </p>
        </div> <br />
        <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'> 
                Please apply to an opening only if you are genuinely interested in it. Read the JD, location, expected CTC etc 
                to be sure if role is right for you Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim sunt sequi vero, error sit fugiat!
            </p>
        </div> <br />
        <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'>
                Once an offer has been made by a company, it should not be rejected. The candidates who reject final offers will be 
                placed in least preferred list for next job openings Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eaque tenetur aspernatur nostrum!
            </p>
        </div> <br />
        <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'> 
                The interview schedule & all the process of selection are carried upon by the employers and PHC partners have no 
                influence over the recruitment process. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatem, cum?
            </p>
        </div> <br />
        <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'> 
                Please revert to the mails sent by the recruiter even if you are planning not to attend the interview or further rounds. 
                It will save the recruiter valuable time and provide an opportunity to other participants
            </p>
        </div> <br />
        <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'> 
                Please maintain the professional conduct throughout the recruitment process with the employers. Complaints regarding rude, 
                indecent or careless behaviour towards employers may lead to debarring from our website
            </p>
        </div> <br />
        <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'> 
                We are available in case you face any difficulty or issue during the recruitment process. But please acknowledge that 
                we are a very small team now working with very stringent timelines and we might take some time responding to your queries
            </p>
        </div> 
        
      </div>
       <br /> <br />
      <hr />
      <br />

      <div className="pp-heading-1">
        <h1 className="pph">Lorem Ipsum Dolor</h1>

        <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'> 
                The objective of this website is to make the recruitment process simpler and faster for both the recruiters 
                and the job seekers. To fulfil our mission we need your support!! Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, quas?
            </p>
        </div> <br />
        <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'> 
                Please apply to an opening only if you are genuinely interested in it. Read the JD, location, expected CTC etc 
                to be sure if role is right for you Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati porro quas necessitatibus excepturi quos commodi!
            </p>
        </div> <br />
        <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'>
                Once an offer has been made by a company, it should not be rejected. The candidates who reject final offers will be 
                placed in least preferred list for next job openings Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vel commodi esse officia?
            </p>
        </div> <br />
        <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'> 
                The interview schedule & all the process of selection are carried upon by the employers and PHC partners have no 
                influence over the recruitment process. Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto ad doloribus dicta.
            </p>
        </div> <br />
        <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'> 
                Please revert to the mails sent by the recruiter even if you are planning not to attend the interview or further rounds. 
                It will save the recruiter valuable time and provide an opportunity to other participants
            </p>
        </div> <br />
        <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'> 
                Please maintain the professional conduct throughout the recruitment process with the employers. Complaints regarding rude, 
                indecent or careless behaviour towards employers may lead to debarring from our website
            </p>
        </div> <br />
        <div className="pph-flex">
            <hr className='pph-hr'/>
            <p className='pph-flex-para'> 
                We are available in case you face any difficulty or issue during the recruitment process. But please acknowledge that 
                we are a very small team now working with very stringent timelines and we might take some time responding to your queries
            </p>
        </div> 
        
      </div> */}
        </div>
      </div>

      <div className="day-cards">
        <div className="Get-in-Touch globalPadding">
          <div className="git-flex container">
            <div className="git-flex1">
              <h1>
                {/* Get in <span>Touch</span> */}
                Get in Touch
              </h1>
              <p className="git-flex1-text">
                Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas <br />
                accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices
                gravida.
              </p>
              <div className="name">
                <input
                  type="text"
                  placeholder="Enter Your Name"
                  className="getInTouchFormField"
                />
                {/* <input type="text" placeholder="Category (Optional)" /> */}
                <select className="getInTouchFormField">
                  <option value="General">General</option>
                  <option value="Job Reference">Job Reference</option>
                  <option value="Recruiter">Recruiter</option>
                </select>
              </div>

              <div className="name">
                <input
                  type="text"
                  placeholder="Your Email"
                  className="getInTouchFormField"
                />
                <input
                  type="text"
                  placeholder="Your Number"
                  className="getInTouchFormField"
                />
              </div>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Your Message Description"
                className="getInTouchFormField"
              />
            </div>
            <div className="git-flex2">
              <img
                className="git-flex2-img1"
                src="https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGNvbnRhY3R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                alt=""
              />
              <img
                className="git-flex2-img2"
                src="https://media.istockphoto.com/photos/colleagues-discussing-business-picture-id1319571139?b=1&k=20&m=1319571139&s=170667a&w=0&h=kh-o5VxMCPPeF9bwjQB2qtsZGsmgiXhVX3X9WUEmrCQ="
                alt=""
              />
            </div>
          </div>
          <div className="git-btn-flex container">
            <button>Send Message</button>
            {/* <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="By clicking contact us button, you agree our terms and policy,"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
