import React, { useEffect, useState } from "react";
import "../css/SavedJobs.css";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useNavigate } from "react-router-dom";
import JobCard from "./Job_card";
import baseUrlAPI from "./BaseURL";

import { saveAs } from "file-saver";

const SavedJobs = () => {
  const uid = localStorage.getItem("uid");
  useEffect(() => {
    if (uid) {
      navigate("/saved-jobs");
      window.scrollTo(0, 0);
    } else {
      navigate("/register");
      window.scrollTo(0, 0);
    }
  }, []);
  const navigate = useNavigate();

  const goToMyProfile = () => {
    window.scrollTo(0, 0);
    navigate("/my-profile");
  };

  const goToAppliedJobs = () => {
    window.scrollTo(0, 0);
    navigate("/applied-job");
  };

  const goToGenerateCV = () => {
    window.scrollTo(0, 0);
    navigate("/generate-cv");
  };

  const goToProfileOverview = () => {
    window.scrollTo(0, 0);
    navigate("/dashboard");
  };

  const goToChangePassword = () => {
    window.scrollTo(0, 0);
    navigate("/change-password");
  };

  const tokenid = localStorage.getItem("candidateToken");

  const candidate_id = localStorage.getItem("uid");

  const saveID = { token: tokenid, candidate_id: candidate_id };

  const [getSavejob, setGetSavejob] = useState([]);
  const [numData, setNumData] = useState([]);

  const getSavejobURL = `${baseUrlAPI}/api/getSavejob`;
  console.log(getSavejob, "fhdsabhfg");

  useEffect(() => {
    fetch(getSavejobURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(saveID),
    })
      .then((result) => result.json())

      .then((data) => {
        setNumData(data);
        setGetSavejob(data.data);

        if (data.status === 200) {
          alert("success");
        }
      })

      .catch((e) => {});
  }, [getSavejobURL]);

  if (getSavejob) {
    localStorage.setItem("savedJob", getSavejob.length);
  }

  const goToJobSearch = (val) => {
    window.scrollTo(0, 0);

    navigate("/job-search/" + val?.job_id);
  };

  const DownloadCV = async (e) => {
    e.preventDefault();
    // import { saveAs } from "file-saver";
    const candidate_id = localStorage.getItem("uid");

    const DownloadResume = { uid: candidate_id };

    const DownloadCVURL = `${baseUrlAPI}/api/pdf`;

    fetch(DownloadCVURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(DownloadResume),
    })
      .then((res) => res.blob())
      .then((data) => {
        saveAs(data, "cv.pdf");
      });
  };

  if (!getSavejob.length === 0) {
    return <div>No data Found!</div>;
  }
  return (
    <>
      <div className="dashboard ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 className="dashboard-heading-d"> Saved Jobs</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="Profile-Overview">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
              <div className="flex-p-overview-m-k">
                <p className="bol-pro" onClick={goToProfileOverview}>
                  <ion-icon name="search-circle-outline"></ion-icon> Profile
                  Overview
                </p>
                <p className="bol-pro" onClick={goToAppliedJobs}>
                  <ion-icon name="business-outline"></ion-icon> Applied Jobs
                </p>
                <p className="pro-over-col bol-pro">
                  <ion-icon name="bookmark-outline"></ion-icon> Saved Jobs
                </p>
                <p className="bol-pro" onClick={goToMyProfile}>
                  <ion-icon name="person-circle-outline"></ion-icon> My Profile
                </p>
                {/* <p className='bol-pro' onClick={goToMessages}><ion-icon name="chatbox-ellipses-outline"></ion-icon> Messages</p> */}
                <p className="bol-pro" onClick={DownloadCV}>
                  <ion-icon name="document-outline"></ion-icon> Generate CV
                </p>
                <p className="bol-pro" onClick={goToChangePassword}>
                  <ion-icon name="lock-closed-outline"></ion-icon> Change
                  Password
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Deactivate
                  Account
                </p>
                <p className="bol-pro">
                  <ion-icon name="log-out-outline"></ion-icon> Log Out
                </p>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
              <div className="pad-col-dash-appl">
                <div className="pad-col-flex-appl">
                  <h5>Saved Jobs - {numData.totale} Saved Jobs</h5>
                  {/* <div className="short-div-apll">
                    <span>Sort by:</span>
                    <select name="" id="opt-applied">
                      <option value="">Newest Post</option>
                      <option value="">Resume Selected</option>
                      <option value="">Selected</option>
                      <option value="">Resume Rejected</option>
                      <option value="">In Progress</option>
                    </select>
                  </div> */}
                </div>

                <div className="day-cards">
                  {getSavejob && getSavejob.length !== 0
                    ? getSavejob.map((job, i) => {
                        return <JobCard job={job} index />;
                      })
                    : "Not found"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedJobs;
