import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import List from "./component/List";
// import Home from "./component/Home";
import JobSearch from "./component/Job_Search";
import Employers from "./component/Employers";
import NoticeBoard from "./component/Notice_Board";
// import FAQs from './component/FAQs';
import Error from "./component/Error";
import Footer from "./component/Footer";
import ForgetPassword from "./component/ForgetPassword";
import AboutUs from "./component/About_Us";
import ContactUs from "./component/Contact_Us";
import PrivacyPolicy from "./component/Privacy_Policy";
import Terms from "./component/Terms";
import MemberLogin from "./component/Member_Login";
import ResetPassword from "./component/Reset_Password";
import Verify_Account from "./component/Verify_Account";
import Register from "./component/Register";

import Others from "./component/Others";

import Welcome from "./component/Welcome";
import Job from "./component/Job";

import Dashboard from "./component/DashBorad";
import AppliedJobs from "./component/AppliedJobs";
import SavedJobs from "./component/SavedJobs";

import MyProfile from "./component/MyProfile";

import Messages from "./component/Messages";

import GenerateCV from "./component/GenerateCV";

import Education from "./component/Education";
import Experience from "./component/Experience";
import RegEducation from "./component/RegEducation";
import RegExperience from "./component/RegExperience";
import RegPreference from "./component/RegPreference";

// import LoginWithPassword from "./component/LoginWithPassword";
import MyProfileEducation from "./component/MyProfileEducation";
import MyProfileExperience from "./component/MyProfileExperience";
import MyProfileOthers from "./component/MyProfileOthers";
import MyProfilePreference from "./component/MyProfilePreference";

import ChangePassword from "./component/ChangePassword";
import RecentJobs from "./component/RecentJobs";
import LinkdenSuccess from "./component/LinkdenSuccess";
import NewHome from "./component/NewHome";
import LoginWithPassword from "./component/LoginWithPassword";

const App = () => {
  return (
    <>
      <Router>
        <List />
        <Routes>
          {/* <Route exact path="/login-password" element={<LoginWithPassword />} /> */}
          <Route exact path="/forget-password" element={<ForgetPassword />} />
          <Route exact path="/" element={<NewHome />} />
          <Route exact path="/job-search/:uid" element={<JobSearch />} />
          <Route exact path="/employers" element={<Employers />} />
          <Route exact path="/notice-board" element={<NoticeBoard />} />
          {/* <Route exact path= "/faqs" element= {<FAQs/>}/> */}
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms" element={<Terms />} />

          <Route exact path="/member-log-in" element={<MemberLogin />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/verify-account" element={<Verify_Account />} />

          <Route exact path="/register" element={<Register />} />
          {/* <Route
            exact
            path="/education-qualification"
            element={<RegEducation />}
          /> */}
          {/* <Route exact path="/new-home" element={<NewHome />} /> */}
          <Route exact path="/experience" element={<RegExperience />} />
          <Route exact path="/others" element={<Others />} />
          <Route exact path="/preference" element={<RegPreference />} />

          <Route exact path="/welcome" element={<Welcome />} />

          <Route exact path="/job" element={<Job />} />

          <Route exact path="/dashboard" element={<Dashboard />} />
          {/* <Route exact path="/linkedin" element={<LinkedIn />} /> */}
          <Route exact path="/applied-job" element={<AppliedJobs />} />
          <Route exact path="/saved-jobs" element={<SavedJobs />} />
          <Route exact path="/my-profile" element={<MyProfile />} />
          <Route exact path="/messages" element={<Messages />} />
          <Route exact path="/generate-cv" element={<GenerateCV />} />

          <Route exact path="/education" element={<Education />} />
          <Route exact path="/linkden" element={<LinkdenSuccess />} />
          <Route exact path="/candidate-experience" element={<Experience />} />
          <Route
            exact
            path="/login-with-password"
            element={<LoginWithPassword />}
          />
          <Route
            exact
            path="/my-profile-education"
            element={<MyProfileEducation />}
          />
          <Route
            exact
            path="/my-profile-experience"
            element={<MyProfileExperience />}
          />
          <Route
            exact
            path="/my-profile-others"
            element={<MyProfileOthers />}
          />
          <Route
            exact
            path="/my-profile-preference"
            element={<MyProfilePreference />}
          />
          <Route exact path="/change-password" element={<ChangePassword />} />
          <Route exact path="/recent-jobs" element={<RecentJobs />} />

          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
